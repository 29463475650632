// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { ownerAdapterMap } from '@module/root/models/owner.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const galleryAdapterMap: MappingConfig = {
  editor: {
    type: MappingTypes.Adapter,
    map: ownerAdapterMap,
    value: 'editor'
  },
  childrenCount: 'childrenCount',
  createdAt: 'createdAt',
  description: 'description',
  id: 'id',
  title: 'title',
  updatedAt: 'updatedAt'
}
