// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { ConfirmModal } from '@component/ConfirmModal'
import { ModalConfiguration, ModalSize } from '@component/Modal'
import { SelectModuleModal } from '@component/PageBuilder'

import ForceSiteChangeModal from '@module/root/components/ForceSiteChangeModal.vue'
import LoginModal from '@module/auth/components/LoginModal.vue'
import QuoteFormModal from '@component/QuoteFormModal/QuoteFormModal.vue'
import SiteSelectionModal from '@module/root/components/SiteSelectionModal.vue'

export enum Modals {
  Confirm = 'confirm',
  ForceChangeSite = 'forceChangeSite',
  Login = 'loginModal',
  MarkdownEditorGuide = 'markdownEditorGuide',
  QuoteFormModal = 'quoteFormModal',
  SelectModuleModal = 'selectModuleModal',
  SiteSelection = 'siteSelection'
}

/**
 * Registry of available modals.
 */
export const modals: ModalRegistry<VueConstructor> = {
  [Modals.Confirm]: ConfirmModal,
  [Modals.ForceChangeSite]: ForceSiteChangeModal,
  [Modals.Login]: LoginModal,
  [Modals.QuoteFormModal]: QuoteFormModal,
  [Modals.SelectModuleModal]: SelectModuleModal as VueConstructor,
  [Modals.SiteSelection]: SiteSelectionModal
}

/**
 * Default config for modals.
 */
export const modalConfig: ModalConfiguration = {
  closableWithOutsideClick: false,
  height: ModalSize.auto,
  width: ModalSize.sm
}
