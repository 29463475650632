// Copyright © 2021 Move Closer

import { ModuleDriver } from '@modules'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const layoutModules: string[] = [
  ModuleDriver.Navbar,
  ModuleDriver.Footer
]
