




























// FIXME
import { DescriptionOfSet } from '@/shared/contracts/content'
import { ListGroup, ListGroupItem } from '@component/ListGroup'
import { Loader } from '@component/Loader'

import { AbstractModal } from '@component/Modal'
import { UserModel } from '@module/auth/contracts/models'

import { Domain, SiteModel } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { canViewBlogPostsFeed, canViewJobsFeed } from '@module/sets/config/guards'
import { SetType } from '@module/sets/contracts'
import { Identifier, Inject } from '@modules'
import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Prop } from 'vue-property-decorator'

import {
  ContentRepositoryType,
  IContentRepository,
  PageListsModalPayload,
  RegistryPayload
} from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<PageListsModal>({
  name: 'PageListsModal',
  components: { ListGroup, Loader },
  mounted () {
    this.extractLists()
  }
})
export class PageListsModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  protected payload!: PageListsModalPayload

  @Inject(AuthServiceType)
  private authService!: Authentication<UserModel>

  @Inject(ContentRepositoryType)
  protected readonly contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  private readonly siteResolver!: ISiteResolver

  public isLoading: boolean = true
  protected lists: DescriptionOfSet[] = []

  public get listsToDisplay (): ListGroupItem[] {
    return this.lists.map(l => ({ data: l }))
  }

  protected get sites (): SiteModel[] {
    return this.siteResolver.getDictionary()
  }

  protected get site (): SiteModel | null {
    return this.siteResolver.getSite()
  }

  public closeModal (): void {
    this.$emit('close')
  }

  public checkPermission (set: DescriptionOfSet): boolean {
    if (!this.resolveSite()) {
      return false
    }

    switch (set.type) {
      case SetType.JobOffers:
        return canViewJobsFeed(this.resolveSite() as Domain, this.authService.user)
      case SetType.BlogPosts:
        return canViewBlogPostsFeed(this.resolveSite() as Domain, this.authService.user)
      case SetType.SocialPosts:
        // TODO - finish when API ready
        return true
      default:
        return false
    }
  }

  public resolveSite (): string {
    if (!this.site) {
      return ''
    }
    return this.site.domain
  }

  protected async extractLists (): Promise<void> {
    try {
      const content: RegistryPayload = await this.contentRepository.registry(this.payload.slug)

      const list: DescriptionOfSet[] = []

      list.push(...Object.entries(content.related.jobsFeed ?? {}).map(([k, v]) => {
        if (typeof v !== 'object' || v === null) {
          return { id: k, title: '---', type: SetType.JobOffers } as unknown as DescriptionOfSet
        }

        return { ...v, id: k, type: SetType.JobOffers } as unknown as DescriptionOfSet
      }))

      list.push(...Object.entries(content.related.blogFeed ?? {}).map(([k, v]) => {
        if (typeof v !== 'object' || v === null) {
          return { id: k, title: '---', type: SetType.BlogPosts } as unknown as DescriptionOfSet
        }

        return { ...v, id: k, type: SetType.BlogPosts } as unknown as DescriptionOfSet
      }))

      this.lists = list
      this.isLoading = false
    } catch (e) {
      console.warn(e)
    }
  }
}

export default PageListsModal
