// Copyright © 2021 Move Closer

import {
  IVariantsRepository,
  ModuleValidationResult,
  VariantModel,
  VariantsRepositoryType
} from '@module/content/contracts'
import { clone } from 'lodash'
import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { computed, onMounted, PropType, ref, SetupContext } from '@vue/composition-api'

import { ContainerContent, log, ModuleConstructor } from '@modules'
import { IRelatedService } from '@service/related'

import { ContainerDrawerProps, UseContainerFormProvides } from '../PageBuilder.contracts'
import { useModuleDependencies } from './shared.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const containerDrawerProps: ComponentObjectPropsOptions<ContainerDrawerProps> = {
  relatedService: {
    type: Object as PropType<IRelatedService>,
    required: true
  },
  source: {
    type: Object as PropType<ContainerContent>,
    required: true
  },
  variant: {
    type: Object as PropType<VariantModel>,
    required: false,
    default: () => { return {} }
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useContainerDrawer (props: ContainerDrawerProps, ctx: SetupContext): UseContainerFormProvides {
  const container = ref<ContainerContent | null>(null)

  const { pickRelated, resolveInjection } = useModuleDependencies()

  const variantRepository = resolveInjection<IVariantsRepository>(VariantsRepositoryType)

  const isOpen = computed<boolean>(() => {
    return container.value !== null
  })

  const errors = ref<string[]>([])
  const validating = ref<boolean>(false)

  const selectedForm = computed<ModuleConstructor | string>(() => {
    return ctx.root.$options.configuration?.byKey('builder.containerForm') || 'div'
  })

  function apply (): void {
    ctx.emit('apply', container.value)
    ctx.emit('close')
    container.value = null
  }

  /**
   * Apply all changes that were placed.
   */
  function applyChanges (): void {
    if (!container.value) {
      log('[ContainerDrawer] Module.value is `null`', 'error')
      return
    }

    validating.value = true

    variantRepository.validate(container.value, 'container').then((result: ModuleValidationResult) => {
      if (result.isValid) {
        apply()
      }

      errors.value = result.errors
    }).catch(error => {
      log(error, 'warn')
    }).finally(() => {
      validating.value = false
    })
  }

  onMounted(() => {
    container.value = clone(props.source)
  })

  return { applyChanges, container, errors, isOpen, pickRelated, resolveInjection, validating, selectedForm }
}
