






















































































import { DashmixAlertTheme, DashmixBreadcrumbsProps, DashmixAccordionItem, DashmixIconName } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime, IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@modules'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader'
import { PageBuilder } from '@component/PageBuilder'
import { Query } from '@/shared/contracts/query'

import { AcceptancePreview } from '../components/AcceptancePreview.vue'
import { AcceptanceAddons } from '../components/AcceptanceAddons.vue'
import { AcceptanceModel, AcceptanceRepositoryType, IAcceptanceRepository, IVariantsRepository, VariantsRepositoryType } from '../contracts'
import { ConfirmAcceptanceModalMode } from '../contracts/components'
import { ContentModals } from '../config/modals'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<Acceptance>({
  name: 'Acceptance',
  components: { AcceptanceAddons, Loader, PageBuilder },
  metaInfo (this: Acceptance): MetaInfo {
    return {
      title: `${this.$t('content.acceptance.listTitle')}`
    }
  },

  created () {
    this.loadData(this.$route.params.id as unknown as Identifier)
  }
})
export class Acceptance extends Vue {
  @Prop({ type: Object, required: false })
  public accordionItem!: DashmixAccordionItem

  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected readonly relatedService!: IRelatedService

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  public alertTheme = DashmixAlertTheme.Warning
  public DashmixIconName = DashmixIconName
  public isLoading: boolean = true
  public isPending: boolean = false
  public model: AcceptanceModel = {} as AcceptanceModel
  public pendingName: string = ''
  public rightItemToToggle: number = -1
  public leftItemToToggle: number = -1

  protected activeAddonTab: string = ''
  protected error: string | null = null

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    const base = {
      items: [
        {
          label: `${this.$t('content.' + this.contentType + '.listTitle')}`,
          target: { name: `content.${this.contentType}s.list` }
        }, {
          label: `${this.$t('content.acceptance.listTitle')}`,
          target: { name: 'content.acceptance.list' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      }
    }

    if (this.model) {
      base.items.push({
        label: this.model.title,
        target: { name: 'content.acceptance.list' }
      })
    }

    return base
  }

  public get contentType (): string {
    return this.$route.params.contentType
  }

  public get modulesAsIs () {
    const relatedService: IRelatedService = this.$container!.get(RelatedServiceType)

    return {
      items: [
        {
          id: 'content-as',
          label: `${this.$t('content.acceptance.content')}`,
          component: AcceptancePreview,
          props: {
            content: this.model.content,
            contentType: this.model.contentType,
            model: this.model.asIs,
            isOpen: true,
            relatedService: relatedService
          }
        },
        {
          id: 'addons-as',
          label: `${this.$t('content.acceptance.addons')}`,
          component: AcceptanceAddons,
          props: {
            active: this.activeAddonTab,
            model: this.model.asIs,
            onActiveChange: this.onActiveAddonTabChange,
            relatedService: relatedService
          }
        }
      ]
    }
  }

  public get modulesToBe () {
    const relatedService: IRelatedService = this.$container!.get(RelatedServiceType)

    return {
      items: [
        {
          id: 'content-be',
          label: `${this.$t('content.acceptance.content')}`,
          component: AcceptancePreview,
          props: {
            content: this.model.content,
            contentType: this.model.contentType,
            model: this.model.toBe,
            isOpen: true,
            relatedService: relatedService
          }
        },
        {
          id: 'addons-be',
          label: `${this.$t('content.acceptance.addons')}`,
          component: AcceptanceAddons,
          props: {
            active: this.activeAddonTab,
            model: this.model.toBe,
            onActiveChange: this.onActiveAddonTabChange,
            relatedService: relatedService
          }
        }
      ]
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public acceptAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Accept,
      onConfirm: () => {
        this.modalConnector.close()
        this.accept()
      },
      onClose: () => this.modalConnector.close()
    })
  }

  public editAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Edit,
      onConfirm: () => {
        this.modalConnector.close()
        this.edit()
      },
      onClose: () => this.modalConnector.close()
    })
  }

  public rejectAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Reject,
      onConfirm: (message: string) => {
        this.modalConnector.close()
        this.reject(message)
      },
      onClose: () => this.modalConnector.close(),
      textArea: true
    })
  }

  public returnAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Return,
      onConfirm: (message: string) => {
        this.modalConnector.close()
        this.return(message)
      },
      onClose: () => this.modalConnector.close(),
      textArea: true
    })
  }

  private accept (): void {
    this.setPending('accept')

    this.acceptanceRepository.accept(
      this.model.id
    ).then(() => {
      this.$router.push({ name: 'content.acceptance.list' })
    }).finally(() => {
      this.setPending('')
    })
  }

  private edit (): void {
    this.setPending('edit')

    this.variantsRepository.lock(
      this.model.content,
      this.model.id
    ).then(() => {
      this.$router.push({
        name: `content.${this.model.contentType}s.content`,
        params: { id: `${this.model.toBe.content}` }
      })
    })
  }

  private loadData (id: Identifier): void {
    this.isLoading = true

    this.acceptanceRepository.load(id).then(model => {
      this.model = model
    }).finally(() => {
      this.isLoading = false
    })
  }

  private onActiveAddonTabChange (tab: string): void {
    this.activeAddonTab = tab
  }

  public onLeftAccordionToggle (event: { itemIndex: number }): void {
    this.rightItemToToggle = -1
    this.$nextTick(() => {
      this.rightItemToToggle = event.itemIndex
    })
  }

  public onRightAccordionToggle (event: { itemIndex: number }): void {
    this.leftItemToToggle = -1
    this.$nextTick(() => {
      this.leftItemToToggle = event.itemIndex
    })
  }

  private reject (message: string): void {
    this.setPending('reject')

    this.acceptanceRepository.reject(
      this.model.id,
      message
    ).then(() => {
      this.$router.push({ name: 'content.acceptance.list' })
    }).finally(() => {
      this.setPending('')
    })
  }

  private return (message: string): void {
    this.setPending('return')

    this.acceptanceRepository.return(
      this.model.id,
      message
    ).then(() => {
      this.$router.push({ name: 'content.acceptance.list' })
    }).finally(() => {
      this.setPending('')
    })
  }

  private setPending (name: string): void {
    this.isPending = name !== ''
    this.pendingName = name
  }
}

export default Acceptance
