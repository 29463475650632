// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '@module/auth/contracts/permissions'

import CreateRole from '../views/CreateRole.vue'
import EditRole from '../views/EditRole.vue'
import RolesList from '../views/RolesList.vue'
import UsersList from '../views/UsersList.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/users',
      name: 'list',
      component: UsersList,
      meta: {
        permissions: [
          Permission.ViewUsers
        ]
      }
    },
    {
      path: '/users/{id}',
      name: 'edit',
      params: ['id'],
      component: UsersList,
      meta: {
        permissions: [
          Permission.ViewUsers
        ]
      }
    },
    {
      path: '/roles',
      name: 'roles.list',
      component: RolesList,
      meta: {
        permissions: [
          Permission.ViewRoles
        ]
      }
    },
    {
      path: '/roles/:id/edit',
      name: 'roles.edit',
      component: EditRole,
      meta: {
        permissions: [
          Permission.EditRoles
        ]
      }
    },
    {
      path: '/roles/create',
      name: 'roles.create',
      component: CreateRole,
      meta: {
        permissions: [
          Permission.CreateRoles
        ]
      }
    }
  ]
}
