// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const MissingModule = defineComponent({
  name: 'MissingModule',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  template: `
    <div class="w-100 h-100 bg-danger">
      <p class="text-white">Missing module: {{ data.driver }}</p>
    </div>
  `
})
