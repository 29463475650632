











































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { DateTimeType, IDateTime } from '@movecloser/front-core'

import { Inject } from '@modules'

import { FileModel } from '../contracts'
import { fileSize } from '../helpers'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'FileDataTable'
})
export class FileDataTable extends Vue {
  @Prop({ type: String, required: false })
  public variantUrl?: string | null

  @Prop({ type: Object, required: true })
  public file!: FileModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public icons = DashmixIconName
  public fileSize = fileSize

  public get fileDirectory (): string {
    const fileArr = this.file.fullRoute().split('/')
    fileArr.pop()

    return fileArr.join('/')
  }

  public get fileVariantUrl () {
    return this.variantUrl === '' ? this.file.url : this.variantUrl
  }

  public getDirectoryLink () {
    return { name: 'media.showDirectory', params: { id: this.file.directory.id.toString() } }
  }
}
export default FileDataTable
