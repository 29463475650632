// Copyright © 2021 Move Closer

import { ContentStructure } from '@movecloser/page-builder'

import { LayoutSlot, LayoutSlotData } from '@module/layouts/contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function composeLayoutSlotData (payload: Record<string, ContentStructure>): LayoutSlotData {
  const toBeLayoutData: Record<string, ContentStructure> = {}

  for (const slot of Object.values(LayoutSlot)) {
    toBeLayoutData[slot] = slot in payload && typeof payload[slot] === 'object' && payload[slot] !== null
      ? payload[slot] : {}
  }

  return toBeLayoutData as LayoutSlotData
}
