// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

// TODO: Change csrf to `true` when API is deployed.
export const resources: ResourcesRegistry = {
  content: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'content',
        method: Methods.Post,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      delete: {
        url: 'content/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      get: {
        url: 'content/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'content',
        method: Methods.Get,
        auth: true
      },
      nodes: {
        url: 'nodes',
        method: Methods.Get,
        auth: true
      },
      options: {
        url: 'content/{id}',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      order: {
        url: 'content/{parentId}/order',
        params: ['parentId'],
        method: Methods.Post,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      publish: {
        url: 'content/{id}/publication',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      stats: {
        url: 'content/stats',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'content/{id}',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { csrf: true, toast: true }
      }
    }
  },
  contentAcceptance: {
    prefix: 'api/management',
    methods: {
      accept: {
        url: 'acceptance/{id}',
        params: ['id'],
        method: Methods.Post,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      get: {
        url: 'acceptance/{id}',
        params: ['id'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'acceptance',
        method: Methods.Get,
        auth: true
      }
    }
  },
  contentVariants: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'content/{content}/variants',
        method: Methods.Post,
        params: ['content'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      delete: {
        url: 'variants/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      get: {
        url: 'variants/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      lock: {
        url: 'variants/{id}/lock',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: false }
      },
      preview: {
        url: 'variants/{id}/preview',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      unpublish: {
        url: 'variants/{id}/unpublish',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      update: {
        url: 'variants/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      status: {
        url: 'variants/{id}/status',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      validate: {
        url: 'variants/validate',
        method: Methods.Post,
        auth: true,
        meta: { csrf: true }
      }
    }
  },
  public: {
    prefix: 'api',
    methods: {
      registry: {
        url: 'registry/{slug}',
        method: Methods.Get,
        params: ['slug']
      }
    }
  }
}
