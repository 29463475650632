// Copyright © 2021 Move Closer

import { DashmixIconName, TableSearchBarAction } from '@movecloser/ui-core'
// TODO - fix import
import { TableHead } from '@movecloser/ui-core/lib/abstract/components/Table'

import { AcceptanceModel } from '../contracts/models'

export enum AcceptanceActions {
  Accept = 'accept',
  Preview = 'preview',
  Reject = 'reject',
  Revoke = 'revoke'
}

export const acceptanceRowActions: TableSearchBarAction[] = [
  {
    label: 'Podgląd',
    icon: DashmixIconName.Eye,
    isBulkAction: false,
    action: AcceptanceActions.Preview
  },
  {
    label: 'Akceptuj',
    icon: DashmixIconName.CheckSolid,
    isBulkAction: true,
    guard: (data: unknown): boolean => {
      const model = data as AcceptanceModel
      return model.isAcceptable()
    },
    action: AcceptanceActions.Accept
  }
]

export const acceptanceAcceptedTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'editor',
    label: 'Edytor',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'acceptedBy',
    label: 'Akceptujący',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'acceptedAt',
    label: 'Data akceptacji',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'publishedAt',
    label: 'Data publikacji',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  }
]

export const acceptancePendingTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'editor',
    label: 'Edytor',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'started',
    label: 'Rozpoczęcie edycji',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'updatedAt',
    label: 'Ostatnia zmiana',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  }
]

export const acceptancePendingTableHeadShortened: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: false,
    isSorted: false,
    width: '4fr'
  },
  {
    column: 'contentType',
    label: 'Typ',
    sortable: false,
    isSorted: false,
    width: '1.4fr'
  },
  {
    column: 'editor',
    label: 'Edytor',
    sortable: false,
    isSorted: false,
    width: '1.6fr'
  }
]

export const acceptanceRejectedTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'editor',
    label: 'Edytor',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'acceptedBy',
    label: 'Akceptujący',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'acceptedAt',
    label: 'Data akceptacji',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  }
]
