import { Model } from '@movecloser/front-core'
import { ISetItem, SetItemData } from '@module/sets/contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export abstract class AbstractSetItem extends Model<SetItemData> implements ISetItem {
  protected boot () {
    this.modelProperties = [
      'id',
      'position',
      'data',
      'locked'
    ]
  }

  public abstract displayName (): string

  public identifier () {
    return this._data.id
  }

  public toSetItem (): any {
    return {
      id: this._data.id,
      position: this._data.position,
      title: this.displayName()
    }
  }
}
