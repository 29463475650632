


































import { DashmixBreadcrumbsProps, DashmixTheme, TableHead, TableRowAction, TableRowElement } from '@movecloser/ui-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Inject } from '@modules'

import { DropdownActions } from '@/shared/contracts/content'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { ILayoutsPermissions, LayoutsPermissions } from '../helpers/permissions'
import { ILayoutRepository, LayoutData, LayoutRepositoryType } from '../contracts'
import { LayoutsTableRow } from '../components/LayoutsTableRow.vue'
import { layoutRowActionsFactory, LayoutsActions, layoutsTableHead } from '../maps/layouts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<LayoutsList>({
  name: 'LayoutsList',
  components: { InteractiveTable, ModelListHeader },
  mounted () {
    this.loadList(this.queryParams)
  }
})
export class LayoutsList extends Mixins<ILayoutsPermissions>(LayoutsPermissions) {
  @Inject(LayoutRepositoryType)
  private layoutRepository!: ILayoutRepository

  public isLoading = false
  public itemsTotal: number = 0
  public rowComponent = LayoutsTableRow
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = layoutsTableHead
  public totalPages: number = 0

  public actions: DropdownActions = {
    [LayoutsActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({ name: 'layouts.layouts.edit', params: { id: (data as LayoutData).id.toString() } })
      }
    },
    [LayoutsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: this.$t('settings.layouts.delete.confirmation.header').toString(),
        contentText: this.$t('actions.delete.contentText').toString(),
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public header: HeaderInterface = {
    title: this.$t('settings.layouts.title').toString(),
    buttonLabel: this.canEditLayout ? this.$t('settings.layouts.add').toString() : '',
    linkTarget: this.canEditLayout ? { name: 'layouts.layouts.create' } : undefined
  }

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.layouts.title')}`,
          target: { name: 'layouts.layouts.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  protected get queryParams (): Query {
    return this.$route.query
  }

  public get rowActions (): TableRowAction[] {
    return layoutRowActionsFactory(this.domain, this.user)
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }

  protected loadList (query?: Query): void {
    this.isLoading = true
    this.layoutRepository.list(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          selected: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  protected onDelete (id: Identifier) {
    return this.layoutRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }
}

export default LayoutsList
