// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Container = defineComponent({
  name: 'Container',
  template: '<div><slot/></div>'
})
