// Copyright © 2022 Move Closer

import {
  ConnectorMiddleware,
  CSRFServiceType,
  HttpConnectorType,
  IConfiguration,
  ICSRFService,
  Interfaces,
  ProvidersFactory
} from '@movecloser/front-core'

import { CSRFService } from '@service/csrf'
import {
  IRelatedRepository,
  IRelatedService,
  RelatedRepository,
  RelatedRepositoryType,
  RelatedService,
  RelatedServiceType,
  resolveDrivers
} from '@service/related'
import { IToastService, ToastMiddleware, ToastMiddlewareType, ToastService, ToastServiceType } from '@service/toaster'

/**
 * Global services binding.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const services: ProvidersFactory = (config: IConfiguration) => {
  return (bind: Interfaces.Bind) => {
    bind<ConnectorMiddleware>(ToastMiddlewareType).toDynamicValue((context: Interfaces.Context) => {
      return new ToastMiddleware(
        context.container.get<IToastService<string, ToastrOptions>>(ToastServiceType)
      )
    })

    bind<ICSRFService>(CSRFServiceType).toDynamicValue((context: Interfaces.Context) => {
      return new CSRFService(
        config.byFile('csrf'),
        context.container.get(HttpConnectorType)
      )
    }).inSingletonScope()

    bind<IRelatedRepository>(RelatedRepositoryType).to(RelatedRepository).inSingletonScope()

    bind<IRelatedService>(RelatedServiceType).toDynamicValue((context: Interfaces.Context) => {
      return new RelatedService(
        resolveDrivers(),
        context.container.get(RelatedRepositoryType)
      )
    }).inRequestScope()

    bind<IToastService<string, ToastrOptions>>(ToastServiceType).toDynamicValue(() => {
      return new ToastService(
        config.byFile('toasts')
      )
    })
  }
}
