// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Identifier } from '@/shared/contracts/data'

import { ILayoutRepository, LayoutData, LayoutModel } from '../contracts'
import { Layout } from '../models/layout'
import { Query } from '@/shared/contracts/query'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class LayoutRepository extends Repository<LayoutData, Layout> implements ILayoutRepository {
  public async create (payload: Omit<LayoutData, 'id'>): Promise<any> {
    const response: IResponse = await this.connector.call(
      'layouts',
      'create',
      {},
      { ...payload }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data?.data
  }

  public async delete (layoutId: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'layouts',
      'delete',
      { layoutId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async get (layoutId: Identifier): Promise<LayoutModel> {
    const response: IResponse = await this.connector.call(
      'layouts',
      'get',
      { layoutId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Layout)
  }

  public async list (query?: Query): Promise<ICollection<LayoutModel>> {
    const response: IResponse = await this.connector.call(
      'layouts',
      'list',
      {},
      { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Layout,
      response.data.meta
    )
  }

  public async update (layoutId: Identifier, payload: Omit<LayoutData, 'id'>): Promise<void> {
    const response: IResponse = await this.connector.call(
      'layouts',
      'update',
      { layoutId },
      { ...payload }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
