














import { Component } from 'vue-property-decorator'

import { AbstractSetItem } from './AbstractSetItem'
import { PostItemData } from '../../contracts'

/**
 * @todo - `BlogFeedPost` probably needs its own adapter.
 * @todo - Documentation.
 */
@Component({ name: 'BlogPostSetItem' })
export class BlogPostSetItem extends AbstractSetItem<PostItemData> {
  /**
   * TODO: Documentation.
   */
  public get categories (): string {
    // return this.data.categories.name
    return 'TODO'
  }
}

export default BlogPostSetItem
