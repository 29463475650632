// Copyright © 2021 Move Closer

import { AbstractSetItem } from './set-item'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class OffersItem extends AbstractSetItem {
  public displayName () {
    return this._data.data.title
  }
}
