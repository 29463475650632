















import { Component, Vue } from 'vue-property-decorator'

import { AcceptanceWidget } from '@module/content/shared/AcceptanceWidget.vue'
import { HomePageWidget } from '@module/content/shared/HomePageWidget.vue'
import { LatestPagesWidget } from '@module/content/shared/LatestPagesWidget.vue'
import { SessionsWidget } from '@module/auth/shared/SessionsWidget.vue'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'DashboardView',
  components: {
    AcceptanceWidget,
    LatestPagesWidget,
    HomePageWidget,
    SessionsWidget
  }
})
export class DashboardView extends Vue {}
export default DashboardView
