
























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Fragment } from 'vue-fragment'

import { ContentModel, ContentStatus, ContentType } from '../contracts'
import { ContentPermissions, IContentPermissions } from '../config/permissions.mixin'
import { ContentStatusBadge } from '../contracts/models'
import { EditorsStack } from './EditorsStack.vue'

@Component({
  name: 'PagesTableRowShortened',
  components: { EditorsStack, Fragment }
})
export class PagesTableRowShortened extends Mixins<IContentPermissions>(ContentPermissions) {
  @Prop({ type: Object, required: true })
  public data!: ContentModel

  public ContentStatus = ContentStatus
  public ContentStatusBadge = ContentStatusBadge
  public ContentType = ContentType
}

export default PagesTableRowShortened
