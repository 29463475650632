// Copyright © 2022 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { Picker } from '@modules'

import ConfirmAcceptanceModal from '../components/ComfirmAcceptanceModal.vue'
import ContentTakeOverModal from '../components/ContentTakeOverModal.vue'
import FootnoteModal from '../components/FootnoteModal'
import LinkPickerModal from '../components/LinkPickerModal.vue'
import LockConflictModal from '../components/LockConflictModal.vue'
import MetaAddonModal from '../components/addons/MetaAddonModal.vue'
import PageListsModal from '../components/PageListsModal.vue'
import PreventLoosingDataModal from '../components/PreventLoosingDataModal.vue'
import RemoveConflictModal from '../components/RemoveConflictModal.vue'
import SortContentModal from '../components/SortContentModal.vue'
import VariantPreviewDecisionModal from '../components/VariantPreviewDecisionModal.vue'
import ContentPickerModal from '../components/ContentPickerModal.vue'
import SkipLinksAddonModal from '../components/SkipLinksAddonModal.vue'

export enum ContentModals {
  ConfirmAcceptance = 'confirmAcceptance',
  ContentLists = 'contentLists',
  ContentTakeOver = 'contentTakeOver',
  CropImage = 'cropImage',
  Footnote = 'footnote',
  LockConflict = 'lockConflict',
  MarkdownEditorGuide = 'markdownEditorGuide',
  MetaAddonModal = 'metaAddon',
  PickLink = 'pickLink',
  PreventLoosingData = 'preventLoosingDataModal',
  RemoveConflictModal = 'removeConflictModal',
  SiteSelection = 'siteSelection',
  SortContent = 'sortContent',
  VariantPreviewDecision = 'variantPreviewDecision',
  SkipLinks = 'skipLinks'
}

/**
 * Registry of available modals.
 */
export const contentModals: ModalRegistry<VueConstructor> = {
  [ContentModals.ConfirmAcceptance]: ConfirmAcceptanceModal,
  [ContentModals.ContentLists]: PageListsModal,
  [ContentModals.ContentTakeOver]: ContentTakeOverModal,
  [ContentModals.Footnote]: FootnoteModal,
  [ContentModals.LockConflict]: LockConflictModal,
  [ContentModals.MetaAddonModal]: MetaAddonModal,
  [ContentModals.PickLink]: LinkPickerModal,
  [ContentModals.PreventLoosingData]: PreventLoosingDataModal,
  [ContentModals.RemoveConflictModal]: RemoveConflictModal,
  [ContentModals.SortContent]: SortContentModal,
  [ContentModals.VariantPreviewDecision]: VariantPreviewDecisionModal,
  [ContentModals.SkipLinks]: SkipLinksAddonModal,

  [Picker.Link]: ContentPickerModal
}
