









import { Component, Prop, Vue } from 'vue-property-decorator'
import { TableRowActions } from '@movecloser/ui-core'

import { ContentTableRowElement } from '../contracts'
import { PagesTableRow } from './PagesTableRow.vue'

@Component({
  name: 'PagesParentRow',
  components: { PagesTableRow }
})
export class PagesParentRow extends Vue {
  @Prop({ type: String, required: true })
  public gridColumns!: string

  @Prop({ type: Object, required: true })
  public row!: ContentTableRowElement

  @Prop({ type: Array, required: true })
  public rowActions!: TableRowActions

  public get filteredActions () {
    return this.rowActions.filter(a => {
      return typeof a.guard !== 'function' || a.guard(this.row)
    })
  }

  public doAction (actionItem: any) {
    this.$emit('do', { action: actionItem.action, data: this.row.data })
  }
}

export default PagesParentRow
