// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// TODO: Change csrf to `true` when API is deployed.
export const resources: ResourcesRegistry = {
  user: {
    prefix: 'api/management',
    methods: {
      edit: {
        url: 'users/{id}',
        method: Methods.Put,
        auth: true,
        params: ['id'],
        meta: { csrf: true }
      },
      get: {
        url: 'users/{id}',
        method: Methods.Get,
        auth: true,
        params: ['id']
      },
      list: {
        url: 'users',
        method: Methods.Get,
        auth: true
      },
      reset: {
        url: 'users/{id}',
        method: Methods.Get,
        auth: true,
        params: ['id']
      }
    }
  },
  roles: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'roles',
        method: Methods.Post,
        shorthand: 'createRole',
        auth: true,
        meta: { csrf: true }
      },
      delete: {
        url: 'roles/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      item: {
        url: 'roles/{id}',
        params: ['id'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'roles',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'roles/{id}',
        method: Methods.Put,
        params: ['id'],
        shorthand: 'editRole',
        auth: true,
        meta: { csrf: true }
      }
    }
  }
}
