// Copyright © 2021 Move Closer

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@movecloser/ui-core'

import { loadEditors } from '@/shared/helpers/filters'

import { AcceptanceStatus } from '../contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const acceptanceFiltersConfig: FiltersConfig = {
  groups: {
    title: { label: 'Tytuł', key: 'title', type: FilterType.String },
    editor: { label: 'pages.filters.editor', key: 'editor', type: FilterType.Typeahead, dictionaryLoader: loadEditors },
    acceptedBy: { label: 'Zaakceptowane przez', key: 'acceptedBy', type: FilterType.Typeahead, dictionaryLoader: loadEditors },
    acceptedAt: { label: 'Data akceptacji', key: 'acceptedAt', type: FilterType.Date },
    lockedAt: { label: 'Rozpoczęcie edycji', key: 'lockedAt', type: FilterType.Date },
    updatedAt: { label: 'Ostatnia zmiana', key: 'updatedAt', type: FilterType.Date },
    publishAt: { label: 'Data publikacji', key: 'publishAt', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams, 'status'],
  override: { page: '1' },
  leave: ['status', 'perPage']
}

export const AcceptanceStatusFiltersConfig: Record<AcceptanceStatus, string[]> = {
  [AcceptanceStatus.Pending]: ['title', 'editor', 'lockedAt', 'updatedAt'],
  [AcceptanceStatus.Accepted]: ['title', 'editor', 'acceptedBy', 'publishAt', 'acceptedAt'],
  [AcceptanceStatus.Published]: ['title', 'editor', 'acceptedBy', 'publishAt', 'acceptedAt'],
  [AcceptanceStatus.Rejected]: ['title', 'editor', 'acceptedBy', 'acceptedAt']
}
