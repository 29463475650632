// Copyright © 2021 Move Closer

import { defineComponent, PropType } from '@vue/composition-api'

import { AnyModule } from '@modules'

import { ModuleComponentsRegistry, ModulesRegistryEntry } from '@component/PageBuilder'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ModuleLabel = defineComponent({
  name: 'ModuleLabel',
  inheritAttrs: false,
  props: {
    data: {
      type: Object as PropType<AnyModule>,
      required: true
    }
  },
  computed: {
    definition (): ModulesRegistryEntry {
      const registry: ModuleComponentsRegistry = this.$root.$options.configuration?.byKey('builder.moduleRegistry') || {}
      if (!registry[this.data.driver]) {
        throw new Error(`ModuleLabel: Unknown module given [${this.data.driver}]`)
      }

      return registry[this.data.driver]
    }
  },
  template: `
    <div class="module-label">
      <img :src="definition.image" :title="$t(definition.label)" :alt="$t(definition.label)" />
      <div class="d-inline-block mr-3">
        <span class="module-label__status-dot" :class="{'--visible': data.isVisible, '--invisible': !data.isVisible}"></span>
        <strong :title="data.id">
          {{ (data.title || $t(definition.label)) | trim(30) }}
        </strong>
      </div>
    </div>
  `
})
