


















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { escapeKey, IEscapeEvent } from '@/shared/extensions/events'

import { FormInput, FormText } from '@component/form'
import { Inject } from '@modules'

import { EditFileIntention } from '../intentions/EditFileIntention'
import {
  EmptyFileEditPayload, FileCreatePayload,
  FileEditModalPayload, FileEditPayload,
  FileModel,
  FileRepositoryType,
  FileSource,
  IFileRepository
} from '../contracts'
import { FileDataTable } from '../components/FileDataTable.vue'
import { FileDescriptions } from './FileDescriptions.vue'
import { FileForm } from './FileForm.vue'
import { FilePreview } from './FilePreview.vue'
import { IMediaPermissions, MediaPermissions } from '../config/permissions.mixin'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component<FileEditModal>({
  name: 'FileEditModal',
  components: { FormInput, FormText, FileForm, FileDataTable, FileDescriptions, FilePreview },
  destroyed () {
    window.removeEventListener('keyup', this.listener)
  },
  mounted () {
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'

    window.addEventListener('keyup', this.listener)

    this.initModal()
  }
})
export class FileEditModal extends Mixins<IMediaPermissions>(MediaPermissions) implements IEscapeEvent {
  @Prop({ type: Object, required: true })
  public payload!: FileEditModalPayload

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(FileRepositoryType)
  protected fileRepository!: IFileRepository

  public editMode = false
  public file: FileModel | null = null
  public form: FileEditPayload | FileCreatePayload = { ...EmptyFileEditPayload }
  public formName: string = 'updateFile'
  public icons = DashmixIconName
  public isActing: boolean = false
  public isLoading: boolean = true
  protected listener = escapeKey.bind(this)
  public selected: string = ''
  public variantUrl: string = ''

  public get accordionItems () {
    return {
      items: [
        {
          id: 'file-info',
          label: `${this.$t('media.file.form.accordion.details')}`,
          component: FileDataTable,
          props: {
            file: this.file,
            variantUrl: this.variantUrl
          }
        },
        {
          id: 'file-descriptions',
          label: `${this.$t('media.file.form.accordion.description')}`,
          component: FileDescriptions,
          props: {
            archiveDescriptions: this.file!.archiveDescriptions
          }
        }
      ]
    }
  }

  public get currentDateTime (): string {
    return this.dateTime.nowToFormat('YYYY-MM-DD HH:mm:ss')
  }

  public get sourceSet (): FileSource | null {
    if (!this.file) {
      return null
    }

    return {
      id: this.file.id,
      mime: this.file.mime,
      original: `${this.file.original.file}`,
      variants: this.file.file.variants
    }
  }

  public close (): void {
    this.$emit('close')
  }

  public async deleteFile (): Promise<void> {
    if (!this.file) {
      return
    }

    this.isLoading = true
    try {
      await this.fileRepository.deleteFile(this.file.id)

      if (typeof this.payload.onDelete === 'function') {
        this.payload.onDelete(this.file.id)
      }

      this.$emit('close')
    } catch (e) {
      console.error(e)
    }
  }

  // TODO - finish download file
  // public downloadClick () {
  //   if (!this.isLoading) {
  //     this.downloadFile()
  //   }
  // }

  // public downloadFile () {
  //   this.isLoading = true
  //
  //   if (this.file) {
  //     this.fileRepository.downloadFile(this.file.id)
  //       .then(response => {
  //         console.log(response)
  //       })
  //   }
  // }

  public escapePressed (): void {
    this.close()
  }

  public getFileName (): string {
    return this.file?.name || ''
  }

  public handleUrlChanged (url: string): void {
    this.variantUrl = url
  }

  public async updateFile (): Promise<void> {
    if (!this.file) {
      return
    }

    this.isLoading = true
    const intention = new EditFileIntention({ ...this.form, updatedAt: this.currentDateTime })
    const payload = { ...intention.toRequest() }

    if (this.payload.abandonedMode && 'directory' in this.form) {
      payload.directory = this.form.directory
    }

    try {
      await this.fileRepository.updateFile(this.file.id, payload)

      this.file.applyIntention(intention)
      this.editMode = false

      if (typeof this.payload.onUpdate === 'function') {
        this.payload.onUpdate(this.file)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  public toggleEditMode (): void {
    this.editMode = !this.editMode
  }

  protected async initModal (): Promise<void> {
    try {
      this.file = await this.fileRepository.loadFile(this.payload.file)
      this.form = this.file.toEditPayload()

      if (this.payload.abandonedMode) {
        this.editMode = true
      }

      this.isLoading = false
    } catch (e) {
      console.error(e)
    }
  }
}

export default FileEditModal
