// Copyright © 2021 Move Closer

import { AbstractSetItem } from '@module/sets/models/set-item'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class PostsItem extends AbstractSetItem {
  public displayName () {
    return this._data.data.title
  }
}
