// Copyright © 2021 Move Closer

import {
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { IAuthRepository } from '../contracts/repositories'
import { IToken, TokenData, TokenModel } from '../contracts/models'
import { OAuthPayload } from '../contracts/data'
import { Token } from '../models/token'
import { tokenAdapterMap } from '../models/token.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class AuthRepository extends Repository<TokenData, IToken> implements IAuthRepository {
  protected map: MappingConfig = tokenAdapterMap
  protected useAdapter = true

  public async forgotPassword (email: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'auth',
      'forgotPassword',
      {},
      { email }
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async login (email: string, password: string): Promise<TokenModel> {
    const response: IResponse = await this.connector.call('auth', 'token', {}, { email, password })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    return this.composeModel(response.data.data, Token)
  }

  public async logout (): Promise<void> {
    const response: IResponse = await this.connector.call('auth', 'removeToken', {})

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async authenticate (oauthPayload: OAuthPayload): Promise<TokenModel> {
    const response: IResponse = await this.connector.call('auth', 'authenticate', {}, oauthPayload)

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    return this.composeModel(response.data.data, Token)
  }

  public async resolveRedirectionUrl (): Promise<string> {
    const response: IResponse = await this.connector.call('auth', 'redirect', {}, { grantType: 'adfs' })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    if (!response.data.data.loginUrl) {
      throw new Error('AuthRepository.redirect(): Missing redirect URL!')
    }

    return response.data.data.loginUrl as string
  }

  public async refresh (token: string): Promise<TokenModel> {
    const response: IResponse = await this.connector.call('auth', 'refresh', {}, { token })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    return this.composeModel(response.data.data, Token)
  }

  public async resetPassword (token: string, email: string, password: string, passwordConfirmation: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'auth',
      'resetPassword',
      {},
      { email, token, password, passwordConfirmation }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }
}
