












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Inject, log, Related, RelatedType } from '@modules'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader'

import { PickerPayload } from '@module/content/contracts'

import { INavigationRepository, NavigationModel, NavigationRepositoryType } from '../contracts'
import { NavigationPickerItem } from './NavigationPickerItem.vue'
import { NavigationSidebar } from './NavigationSidebar.vue'

@Component<NavigationPicker>({
  name: 'NavigationPicker',
  components: { Loader, NavigationSidebar, NavigationPickerItem },
  created () {
    this.loadNavigationList()
  }
})
export class NavigationPicker extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<NavigationModel, RelatedType.Navigation, Identifier>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(NavigationRepositoryType)
  private navigationRepository!: INavigationRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public icons = DashmixIconName

  protected activeNavigation: NavigationModel | null = null
  protected navigationList: NavigationModel[] = []
  protected selected: NavigationModel[] = []

  private isLoading: boolean = true
  private isNavigationLoading: boolean = false

  public get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public get isReady (): boolean {
    return !!this.selected && !!this.selected.length
  }

  public apply (): void {
    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selected.map(item => {
        return { type: RelatedType.Navigation, value: item.id } as Related<RelatedType.Navigation, Identifier>
      })

      this.payload.onSelection(this.isMultiple ? result : result[0])
    }

    this.modalConnector.close()
  }

  public changeNavigation (): void {
    this.activeNavigation = null
    this.selected = []

    this.$nextTick(() => {
      this.loadNavigationList()
    })
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public isSelected (id: Identifier): boolean {
    return !!this.selected && this.selected.filter(item => item.id === id).length > 0
  }

  public async loadNavigation (id: Identifier, isSelected: boolean): Promise<void> {
    this.isNavigationLoading = true

    try {
      const navigation = await this.navigationRepository.get(id)

      if (isSelected) {
        this.updateSelected(navigation)
      }
      this.activeNavigation = navigation
    } catch (error) {
      log(error, 'error')
    } finally {
      this.isNavigationLoading = false
    }
  }

  public loadNavigationList (): void {
    this.isLoading = true

    this.navigationRepository.list()
      .then((navigationList: ICollection<NavigationModel>) => {
        this.navigationList = navigationList
      })
      .catch(error => {
        log(error, 'error')
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public toggleNavigation (navigation: NavigationModel): void {
    if (this.isSelected(navigation.id)) {
      this.selected = this.selected.filter(item => item.id !== navigation.id)
    } else {
      this.selected.push(navigation)
    }
  }

  public updateSelected (navigation: NavigationModel): void {
    if (this.isMultiple) {
      this.selected.push(navigation)
    } else {
      this.selected = [navigation]
    }
  }
}

export default NavigationPicker
