
















import { AuthServiceType, ConjunctionOperator } from '@movecloser/front-core'
import { AuthService } from '@movecloser/front-core/lib/services/authorization'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { DashmixBreadcrumbsProps } from '@movecloser/ui-core'

import { EditModeLayout } from '@component/EditModeLayout/EditModeLayout'
import { Inject } from '@modules'
import { Loader } from '@component/Loader'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { CreateSetIntention, CreateSetIntentionPayload } from '../intentions/CreateSetIntention'
import { ISetsRepository, SetsRepositoryType, SetStrategy, SetType } from '../contracts'
import { Set } from '../models/set'
import { SetForm } from '../components/SetForm.vue'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'CreateSet',
  components: { EditModeLayout, SetForm, Loader },
  metaInfo (this: CreateSet): MetaInfo {
    return {
      title: `${this.$t('content.sets.create')}`
    }
  }
})
export class CreateSet extends Vue {
  @Inject(AuthServiceType)
  private authService!: AuthService

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  public formName: string = 'createSet'
  public isLoading: boolean = false
  public payload!: CreateSetIntentionPayload

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t(`sets.listTitle.${this.setType}`)}`,
          target: { name: 'sets.list' }
        },
        {
          label: 'Dodaj listę',
          target: { name: 'sets.create' }
        }
      ],
      root: initBreadcrumbs.root,
      showOnMobile: false
    }
  }

  public get setType (): SetType {
    if (!this.$route.params.type) {
      return SetType.JobOffers
    }
    return this.$route.params.type as unknown as SetType
  }

  created () {
    this.payload = {
      ...new Set(
        {
          author: this.authService.getUserId(),
          type: this.setType,
          title: '',
          childrenCount: 0,
          properties: {
            src: [],
            conjunction: ConjunctionOperator.And,
            items: []
          },
          strategy: SetStrategy.Automatic
        }
      ).toObject(),
      author: Number(this.authService.user?.id)
    }
  }

  public createSet () {
    this.isLoading = true

    this.setsRepository.create(new CreateSetIntention(this.payload)).then(result => {
      this.$router.push({ name: 'sets.edit', params: { id: result.id } })
    }).catch((error) => {
      console.debug(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  protected handleSourcesUpdate (newPayload: CreateSetIntentionPayload) {
    this.payload = newPayload
  }

  protected handleTitleUpdate (newTitle: string) {
    this.payload.title = newTitle
  }
}

export default CreateSet

