// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { modeSwitcherProps, useModeSwitcher } from './ModeSwitcher.hooks'
import { ModeSwitcherProps } from '../PageBuilder.contracts'

export const ModeSwitcher = defineComponent({
  name: 'ModeSwitcher',
  props: modeSwitcherProps,
  setup (props: ModeSwitcherProps, ctx: SetupContext) {
    return useModeSwitcher(props, ctx)
  },

  template: `
    <div class="mode-switcher btn-group mode-switcher__fixed">
    <DsButton v-for="{ icon, label, value } in options" :key="value"
              class="mode-switcher__button" :theme="mode === value ? 'primary' : 'light'" variant="alt"
              :title="label" @click="() => { mode = value }" >
      <DsIcon v-bind="{ icon }" class="mode-switcher__button__icon" />
      <span class="mode-switcher__label">{{ label }}</span>
    </DsButton>
    </div>
  `
})
