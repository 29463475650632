// Copyright © 2021 Move Closer

import { DashmixIconName } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { containerDrawerProps, useContainerDrawer } from './ContainerDrawer.hooks'
import { ContainerDrawerProps } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ContainerDrawer = defineComponent({
  name: 'ContainerDrawer',
  props: containerDrawerProps,
  setup: (props: ContainerDrawerProps, ctx: SetupContext) => ({
    ...useContainerDrawer(props, ctx),
    iconsSet: DashmixIconName
  }),
  template: `
    <div v-if="isOpen" class="page-builder__form-drawer open">
    <div class="page-builder__form-drawer__content">
      <div class="page-builder__form-drawer__header">
        <div>
          <h3 class="mb-0">
            {{ $t('builder.containerInEdition') }}
          </h3>
          <small>ID: {{ container.id }}</small>
        </div>

        <DsButton class="page-builder__form-drawer__content__close"
                  :icon="iconsSet.TimesSolid" variant="icon"
                  @click="$emit('close')" />
      </div>

      <hr />

      <component v-if="isOpen" class="page-builder__form mb-3"
                 :is="selectedForm" v-bind="{ pickRelated, relatedService, resolveInjection }"
                 :content.sync="container" :errors="errors" >
        <div class="d-block bg-danger text-white p-4">{{ $t('builder.missing.containerForm') }}</div>
      </component>

      <DsButton class="mt-3" :loading="validating" :icon="iconsSet.CheckSolid" :label="$t('builder.apply')"
                @click="applyChanges" />
    </div>
    </div>
  `
})
