














import { Component, Prop, Vue } from 'vue-property-decorator'

import { PageBuilder } from '@/shared/components'
import { Inject, log } from '@modules'
import { IRelatedService } from '@service/related'

import {
  ContentData,
  IVariantsRepository,
  VariantData,
  VariantsRepositoryType
} from '../contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AcceptancePreview',
  components: { PageBuilder }
})
export class AcceptancePreview extends Vue {
  @Prop({ type: String, required: true })
  public content!: ContentData['id']

  @Prop({ type: String, required: true })
  public contentType!: string

  @Prop({ type: Object, required: true })
  public model!: VariantData

  @Prop({ type: Object, required: true })
  protected readonly relatedService!: IRelatedService

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  public isGeneratingPreview: boolean = false

  public openPreview (): void {
    this.isGeneratingPreview = true

    this.variantsRepository.preview(
      this.content,
      this.model.id
    ).then(url => {
      window.open(url)
    }).catch((error) => {
      log(error, 'warn')
    }).finally(() => (this.isGeneratingPreview = false))
  }
}

export default AcceptancePreview
