




































import { Component, PropSync, Prop, Vue } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { Domain } from '@module/root/contracts/models'
import { FormSelect } from '@component/form'
import { FormFieldset } from '@modules'
import { IconSelect } from '@/shared/modules/src/components/atoms/IconSelect'

import { DepartmentSetting } from '../contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 * @author Javlon khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component({
  name: 'DepartmentForm',
  components: { IconSelect, FormFieldset, FormSelect }
})
export class DepartmentForm extends Vue {
  /**
   * Department's data (two-way binding).
   */
  @PropSync('model', { type: Object, required: true })
  public _model!: DepartmentSetting

  /**
   * Name of the form (needed for the API validation).
   */
  @Prop({ type: String, required: true })
  public formName!: string

  /**
   * Determines whether the component should be put in the "loading" state.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public loading!: boolean

  /**
   * List of pages (pages tree) available in the system, broken down by the `Domain`.
   */
  @Prop({ type: Object, required: false, default: null })
  public pages!: Record<Domain, DashmixSelectItem[]> | null

  public readonly Domain = Domain
}

export default DepartmentForm
