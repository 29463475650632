// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixNavTabItem } from '@movecloser/ui-core'
import { EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { Subscription } from 'rxjs'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@modules'

import { EditModeTabDestination, EditModeTabGuards, EditModeTabItem } from '../EditModeLayout.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'EditModeTabs',
  template: `
    <DsNavTabs :class=" blink ? 'blink' : '' " :items="navTabs" :active="'tab-'+initTab"
                @update:active="onActiveTabChange" hasDefaultBackground />
  `
})
export class EditModeTabs extends Vue {
  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Prop({ type: Number, required: false, default: 0 })
  public initTab!: number

  @Prop({ type: Array, required: true })
  protected items!: EditModeTabItem[]

  public blink: boolean = false
  public navTabs: DashmixNavTabItem[] = []
  protected navGuards: EditModeTabGuards = {}
  protected subscription!: Subscription

  created () {
    this.items.map((item: EditModeTabItem, index: number) => {
      const id: string = `tab-${index}`
      this.navTabs.push({ id, label: this.$t(item.label).toString() })
      this.navGuards[id] = {
        label: item.label,
        destination: item.route,
        guard: ('guard' in item && typeof item.guard !== 'undefined') ? item.guard : null
      }
    })
  }

  destroy () {
    this.subscription.unsubscribe()
  }

  mounted () {
    this.subscription = this.eventBus.handle('ui:edit-mode.force', (event: EventPayload<Identifier>) => {
      if (typeof event.payload !== 'undefined') {
        this.applyNewDestination(event.payload, true)
      }
    })
  }

  public onActiveTabChange (newActiveTab: Identifier): void {
    this.applyNewDestination(newActiveTab)
  }

  protected applyNewDestination (newActiveTab: Identifier, force: boolean = false): void {
    const item: EditModeTabDestination = this.navGuards[newActiveTab]

    let hasAccess: boolean = true
    if (typeof item.guard === 'function' && !force) {
      hasAccess = item.guard(newActiveTab)
    }

    if (hasAccess || force) {
      try {
        this.$router.push(item.destination)
      } catch (e) {
        throw new Error(`[EditModeTabs]: Invalid item destination. Route for ${item.label} not found.`)
      }
    } else {
      this.blink = true
      setTimeout(() => { this.blink = false }, 500)
    }
  }
}
