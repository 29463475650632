































import { IMediaPermissions, MediaPermissions } from '@module/media/config/permissions.mixin'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import {
  DashmixBreadcrumbsProps,
  DashmixTheme,
  TableHead,
  TableRowAction,
  TableRowElement
} from '@movecloser/ui-core'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { Inject } from '@modules'

import { DropdownActions } from '@/shared/contracts/content'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { ModelListHeaderProps } from '@component/ModelListHeader/ModelListHeader.contracts'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { Query } from '@/shared/contracts/query'

import { createBreadcrumbsFromGallery } from '../helpers'
import { IGalleryRepository, GalleryData, GalleryRepositoryType } from '../contracts'
import { GalleryListActions, galleryRowActionsFactory, galleryTableHead } from '../maps/gallery'
import { galleryFiltersConfig } from '../models/gallery.filters'
import GalleryTableRow from '../components/GalleryTableRow.vue'

@Component({
  name: 'GalleryList',
  components: { InteractiveTable, ModelListHeader }
})
export class GalleryList extends Mixins<IMediaPermissions>(MediaPermissions) {
    @Inject(GalleryRepositoryType)
    protected galleryRepository!: IGalleryRepository

    public isLoading = false
    public filtersConfig = galleryFiltersConfig

    public get header (): HeaderInterface {
      const payload: Partial<HeaderInterface> = {
        title: 'Lista galerii'
      }

      if (this.canUploadMedia) {
        payload.buttonLabel = 'Dodaj galerię'
        payload.linkTarget = { name: 'media.gallery.create' }
      }

      return payload as HeaderInterface
    }

    public get modelListHeader (): ModelListHeaderProps {
      const header: Partial<ModelListHeaderProps> = {
        title: String(this.$t('media.gallery.listTitle'))
      }

      if (this.canUploadMedia) {
        header.addLabel = String(this.$t('media.gallery.listButton'))
        header.addTarget = { name: 'media.gallery.create' }
      }

      return header as ModelListHeaderProps
    }

    public tableHead: TableHead = galleryTableHead
    public rowComponent = GalleryTableRow
    public tableData: TableRowElement[] = []

    public totalPages: number = 0
    public itemsTotal: number = 0

    public get rowActions (): TableRowAction[] {
      return galleryRowActionsFactory(this.domain, this.user)
    }

    public actions: DropdownActions = {
      [GalleryListActions.Edit]: {
        callback: (data: unknown) => {
          this.$router.push({ name: 'media.gallery.edit', params: { id: (data as GalleryData).id.toString() } })
        }
      },
      [GalleryListActions.Delete]: {
        callback: (data: unknown) => {
          return this.onDelete((data as HasIdentifier).id)
        },
        confirmation: {
          header: 'Usuń listę',
          contentText: 'Czy na pewno chcesz usunąć:',
          theme: DashmixTheme.Danger,
          buttonLabel: 'atoms.delete'
        }
      }
    }

    public get breadcrumbs (): DashmixBreadcrumbsProps {
      return {
        items: createBreadcrumbsFromGallery(this.$t('media.gallery.listTitle') as string),
        root: initBreadcrumbs.root
      }
    }

    protected get queryParams (): Query {
      return this.$route.query
    }

    mounted () {
      this.loadList(this.queryParams)
    }

    @Watch('queryParams', { deep: true })
    protected onPageChange (query: Query): void {
      this.loadList(query)
    }

    protected loadList (query: Query): void {
      this.isLoading = true
      this.galleryRepository.loadCollection(query).then(collection => {
        this.isLoading = false

        this.tableData = [...collection].map(model => {
          return {
            id: `${model.id}`,
            selectable: this.canEditMedia || this.canDeleteMedia,
            selected: false,
            data: model
          }
        })

        this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
        this.itemsTotal = collection.meta.total
      }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
    }

    protected onDelete (id: Identifier) {
      return this.galleryRepository.delete(id).then(() => {
        this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
      })
    }
}

export default GalleryList
