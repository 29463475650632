



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { Identifier } from '@/shared/contracts/data'

import { UserData } from '@module/auth/contracts/models'

import { ContentStatus, VariantModel } from '../contracts/models'
import { OnVariantChangeCallback } from '../contracts'

@Component({
  name: 'VariantTab'
})
export class VariantTab extends Vue {
  @Prop({ type: Number, required: true })
  protected active!: number

  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData|null

  @Prop({ type: Function, required: true })
  protected onVariantChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: true })
  protected variant!: VariantModel

  public icons = DashmixIconName
  public isEdit: boolean = false
  public model: string = ''

  public get color (): string {
    switch (this.variant.status) {
      case ContentStatus.Accepted:
      case ContentStatus.InAcceptance:
        return 'bg-warning'
      case ContentStatus.Archived:
      case ContentStatus.Rejected:
        return 'bg-danger'
      case ContentStatus.Published:
        return 'bg-primary'
      case ContentStatus.Draft:
      default:
        return 'bg-muted'
    }
  }

  protected get isActive (): boolean {
    return this.active === this.variant.id
  }

  public get isEditable (): boolean {
    return this.variant.isEditable(this.authUser ? (this.authUser.id as Identifier) : null)
  }

  public get isLive (): boolean {
    return this.variant.isLive()
  }

  mounted () {
    this.model = this.variant.name || ''
  }

  public onBlur () {
    if (!this.model.length) {
      this.isEdit = false
    }
  }

  public turnOffEditMode (): void {
    this.isEdit = false
    this.variant.set('name', this.model)
    this.onVariantChange(this.variant)
  }

  public turnOnEditMode (): void {
    if (this.isActive) {
      this.isEdit = true
    }
  }

  @Watch('active', { deep: true })
  protected onActiveChange (active: number): void {
    if (active !== this.variant.id) {
      this.isEdit = false
      return
    }

    this.isEdit = !this.model.length && this.isEditable
  }
}

export default VariantTab
