// Copyright © 2021 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.abstract'

import {
  canCreateNavigation,
  canDeleteNavigation,
  canEditNavigation,
  canViewNavigation,
  canDeleteSettings,
  canViewSettings,
  canCreateSettings,
  canEditSettings
} from './guards'

export interface ISettingsPermissions extends IUserAware {
  canViewNavigation: boolean
  canCreateNavigation: boolean
  canEditNavigation: boolean
  canDeleteNavigation: boolean
  canViewSettings: boolean
  canCreateSettings: boolean
  canEditSettings: boolean
  canDeleteSettings: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<SettingsPermissions>({})
export class SettingsPermissions extends UserAware implements ISettingsPermissions {
  /**
   * Navigation permissions
   */
  public get canViewNavigation () {
    return canViewNavigation(this.domain, this.authService.user)
  }

  public get canCreateNavigation () {
    return canCreateNavigation(this.domain, this.authService.user)
  }

  public get canEditNavigation () {
    return canEditNavigation(this.domain, this.authService.user)
  }

  public get canDeleteNavigation () {
    return canDeleteNavigation(this.domain, this.authService.user)
  }

  /**
   * Settings permissions
   */
  public get canViewSettings () {
    return canViewSettings(this.domain, this.authService.user)
  }

  public get canCreateSettings () {
    return canCreateSettings(this.domain, this.authService.user)
  }

  public get canEditSettings () {
    return canEditSettings(this.domain, this.authService.user)
  }

  public get canDeleteSettings () {
    return canDeleteSettings(this.domain, this.authService.user)
  }
}
