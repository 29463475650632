// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'

const BreakpointsNames = {
  Xs: '--bs-breakpoint-xs',
  Sm: '--bs-breakpoint-sm',
  Md: '--bs-breakpoint-md',
  Lg: '--bs-breakpoint-lg',
  Xl: '--bs-breakpoint-xl'
}

export interface BreakpointsInterface {
  [key: string]: number
}

function objectKeys<O extends AnyObject, K extends keyof O = keyof O> (obj: O): K[] {
  return Object.keys(obj) as K[]
}

export const getBreakpoints = (): BreakpointsInterface => {
  const breakpoints: BreakpointsInterface = {}

  for (const breakpointName of objectKeys(BreakpointsNames)) {
    const breakpointValue = getComputedStyle(document.documentElement).getPropertyValue(BreakpointsNames[breakpointName])

    if (!breakpointValue) {
      throw new Error('Not supported breakpoint')
    }

    breakpoints[breakpointName] = parseInt(breakpointValue, 10)
  }

  return breakpoints
}
