






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixTheme } from '@movecloser/ui-core'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'
import { Inject } from '@modules'

import { LayoutData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'LayoutsTableRow',
  components: { Fragment }
})
export class LayoutsTableRow extends Vue {
  @Prop({ type: Object, required: true })
  protected data!: LayoutData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public BadgeTheme = DashmixTheme
}

export default LayoutsTableRow
