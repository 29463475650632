// Copyright © 2021 Move Closer

import { VueConstructor } from 'vue'

import { ContentStatus, ContentVariantStatusGroups } from '../contracts'
import DelayedAddon from '../components/addons/DelayedAddon.vue'
import LayoutAddon from '../components/addons/LayoutAddon.vue'
import MetaAddon from '../components/addons/MetaAddon.vue'
import SkipLinksAddon from '../components/addons/SkipLinksAddon.vue'

// !Note: Order of this Enum corresponds to sidebar's order.
export enum Addon {
  Delayed = 'delayed',
  Meta = 'meta',
  Layout = 'layout',
  SkipLink = 'skiplinks'
}

export const addonsDrivers: Record<Addon, VueConstructor> = {
  [Addon.Delayed]: DelayedAddon,
  [Addon.Layout]: LayoutAddon,
  [Addon.Meta]: MetaAddon,
  [Addon.SkipLink]: SkipLinksAddon
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const variantStatusGroups: ContentVariantStatusGroups = {
  [ContentStatus.Draft]: {
    group: ContentStatus.Draft,
    includes: [ContentStatus.Draft]
  },
  [ContentStatus.InAcceptance]: {
    group: ContentStatus.InAcceptance,
    includes: [ContentStatus.InAcceptance]
  },
  [ContentStatus.Accepted]: {
    group: ContentStatus.Accepted,
    includes: [ContentStatus.Accepted]
  },
  [ContentStatus.Published]: {
    group: ContentStatus.Published,
    includes: [ContentStatus.Published]
  },
  [ContentStatus.Archived]: {
    group: ContentStatus.Archived,
    includes: [ContentStatus.Archived, ContentStatus.Rejected]
  }
}

export enum VariantLabel {
  Urgent = 'urgent',
  Live = 'live',
  Interview = 'interview',
  Comment = 'comment',
  Nonstandard = 'nonstandard'
}
