











import { Component } from 'vue-property-decorator'
import { DashmixBreadcrumbsProps } from '@movecloser/ui-core'

import { EditModeLayout } from '@component/EditModeLayout'

import { AbstractEditRole } from './AbstractEditRole.vue'
import { RoleForm } from '../components/RoleForm.vue'
import { RoleFormMode } from '../contracts/components'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'CreateRole',
  components: { EditModeLayout, RoleForm }
})
class CreateRole extends AbstractEditRole {
  public mode = RoleFormMode.Create

  public title = String(this.$t('roles.form.create.title'))

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('roles.list.title')}`,
          target: { name: 'users.roles.list' }
        },
        {
          label: `${this.$t('roles.form.create.title')}`,
          target: { name: 'roles.create' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      }
    }
  }
}
export default CreateRole
