











































import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { AbstractModal } from '@component/Modal'

import { QuoteModalPayload } from './QuoteFormModal.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<QuoteFormModal>({
  name: 'QuoteFormModal',
  mounted () {
    this.author = this.payload.selected?.author || ''
    this.quote = this.payload.selected?.text || ''
  }
})
export class QuoteFormModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: QuoteModalPayload

  public author: string = ''
  public quote: string = ''

  public readonly icons = DashmixIconName

  public apply () {
    this.payload.onSelection({ author: this.author, text: this.quote })
    this.close()
  }
}

export default QuoteFormModal
