







































import { Authentication, AuthServiceType, EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'

import { DashboardLayout, FullScreenLayout, FullScreenLoader } from '@/shared/layouts'
import { Inject } from '@modules'
import { Modal } from '@component/Modal'
import { Navigation, NavigationItem } from '@component/Navigation'

import { SiteLogoVariant, SiteModel } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { ContentPermissions, IContentPermissions } from '@module/content/config/permissions.mixin'
import { Permission } from '@module/auth/contracts/permissions'
import { SiteSelector } from '@module/root/components/SiteSelector.vue'
import { UserMenu } from '@module/root/components/UserMenu.vue'
import { UserModel } from '@module/auth/contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<Layout>({
  name: 'Layout',
  components: {
    DashboardLayout,
    Fragment,
    FullScreenLayout,
    FullScreenLoader,
    Modal,
    Navigation,
    SiteSelector,
    UserMenu
  },
  created () {
    this.changeSite()

    this.eventBus.handle('app:authentication', (event: EventPayload<string>) => {
      if (event.payload === 'booted' || event.payload === 'refreshed') {
        this.navigation = this.buildNavigation()
      }
    })
  }
})
export class Layout extends Mixins<IContentPermissions>(ContentPermissions) {
  @Prop({ type: Object, required: true })
  protected site!: SiteModel

  @Inject(AuthServiceType)
  private authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  private defaultLayout: string = 'DashboardLayout'
  public icon: DashmixIconName = DashmixIconName.PlusSolid
  public logoBig: string | null = null
  public logoSmall: string = require('@/assets/images/icons/icon.svg')
  public navigation: NavigationItem[] = []

  public get isDashboard (): boolean {
    return this.resolveLayoutName() === 'DashboardLayout'
  }

  public get isFullscreen (): boolean {
    return this.resolveLayoutName() === 'FullScreenLayout'
  }

  public buildNavigation (): NavigationItem[] {
    const user = this.authService.user

    if (!this.domain || !user) return []

    const navigationConfig: NavigationItem[] = this.$root.$options.configuration?.byFile('navigation') || []

    let newConfig = JSON.parse(JSON.stringify(navigationConfig))

    for (const navItem of newConfig) {
      if (navItem.children) {
        navItem.children = navItem.children.filter((child: NavigationItem) => {
          return child.permissions?.every(permission => user?.canPerform(this.site.domain!, permission))
        })
      }
    }

    newConfig = newConfig.filter((item: NavigationItem) => {
      if ((!item.children || !item.children.length) && !item.route) {
        return false
      }

      return !item.permissions ||
          !item.route ||
          item.permissions.every((permission: Permission) => user.canPerform(this.site.domain!, permission))
    })

    return newConfig
  }

  @Watch('site')
  private changeSite () {
    this.logoBig = this.site.logo[SiteLogoVariant.Dark] || null
    this.navigation = this.buildNavigation()
  }

  private resolveLayoutName (): string {
    return this.$route.meta?.layout || this.defaultLayout
  }
}

export default Layout
