




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem, Hint } from '@movecloser/ui-core'

import { Inject } from '@modules'
import { Identifier } from '@/shared/contracts/data'

import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'
import { LayoutAddonData } from '@module/layouts/contracts'
import { mockedDirtyCallback, mockedVariantChangeCallback } from '../../helpers/components'

import { ContentType, OnVariantChangeCallback, VariantModel } from '../../contracts'
import { Addon } from '../../maps/variant'

type LayoutHintsMap = { [type in ContentType]?: Hint[] }

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'LayoutAddon',
  components: {}
})
export class LayoutAddon extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public disabled!: boolean

  @Prop({ type: Function, required: false, default: mockedDirtyCallback })
  protected onChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: false, default: mockedVariantChangeCallback })
  protected variant!: VariantModel

  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public ContentType = ContentType

  public layoutsDictionary: DashmixSelectItem[] | [] = []
  public loading: boolean = false
  public searchParam: string = ''
  public value: LayoutHintsMap = {}

  mounted () {
    this.getVariantProperty()

    this.getLayoutsDictionary()
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete (type: ContentType) {
    delete this.value[type]
    this.value = { ...this.value }
    this.onLayoutSelected(type, this.value)
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (type: ContentType, selectedHint: Hint) {
    this.value = { ...this.value, [type]: [selectedHint] }
    this.onLayoutSelected(type, this.value)
  }

  protected getLayoutsDictionary (searchParams?: string): void {
    this.loading = true

    this.dictRepository.loadLayoutsDictionary({ q: searchParams || '' }).then(dict => {
      this.layoutsDictionary = [...dict].map(model => {
        return {
          value: model.id,
          label: model.fullName
        }
      })
    }).catch(error => {
      console.warn(error)
    }).finally(() => {
      this.loading = false
    })
  }

  protected getVariantProperty (): void {
    const layoutFromModel = this.variant.getProperty<LayoutAddonData>(Addon.Layout)

    if (!layoutFromModel) {
      this.value = {}
      return
    }

    let t: ContentType
    for (t in layoutFromModel) {
      if (layoutFromModel.hasOwnProperty(t)) {
        const layoutForType: { id: Identifier; name: string } | undefined = layoutFromModel[t]

        if (layoutForType) {
          this.value[t] = [{
            value: layoutForType.id,
            label: layoutForType.name
          }]
        }
      }
    }
  }

  protected onLayoutSelected (type: ContentType, value: LayoutHintsMap): void {
    const addonPayload: LayoutAddonData = {} as LayoutAddonData

    let t: ContentType
    for (t in value) {
      if (value.hasOwnProperty(t)) {
        const hintForType: Hint[] | undefined = value[t]

        if (hintForType && hintForType[0]) {
          addonPayload[t] = {
            id: hintForType[0].value as unknown as Identifier,
            name: hintForType[0].label
          }
        }
      }
    }

    this.variant.setProperty<LayoutAddonData>(Addon.Layout, addonPayload)

    this.onChange(this.variant)
  }

  @Watch('searchParam', { deep: false })
  protected onSearchParamsChange (searchParams: string): void {
    this.getLayoutsDictionary(searchParams)
  }

  @Watch('variant', { deep: true })
  protected onVariantChange (newVariant: VariantModel, oldVariant: VariantModel) {
    if (JSON.stringify(newVariant.toObject()) !== JSON.stringify(oldVariant.toObject())) {
      this.getVariantProperty()
    }
  }
}

export default LayoutAddon
