// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import { resolve } from '@modules'

import { ClockProps } from './Clock.contracts'

import { defaultDateFormat } from '@module/content/helpers/formatting'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const clockProps: ComponentObjectPropsOptions<ClockProps> = {
  /**
   * Defaults to DD.MM.YYYY (HH:mm)
   */
  dateFormat: {
    type: String,
    required: false
  },
  /**
   * Clock interval
   */
  interval: {
    type: Number,
    required: false,
    default: 60000
  }
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const useClock = (props: ClockProps) => {
  const { dateFormat = defaultDateFormat, interval = 60000 } = props

  const dateTime = resolve<IDateTime>(DateTimeType)

  const date = ref(dateTime.nowToFormat(dateFormat))

  let intervalId: ReturnType<typeof setInterval>

  const killClock = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
  }

  const startClock = () => {
    intervalId = setInterval(() => {
      date.value = dateTime.nowToFormat(dateFormat)
    }, interval)
  }

  onMounted(() => startClock())

  onUnmounted(() => killClock())

  return { date, killClock, startClock }
}
