// Copyright © 2021 Move Closer

import { AnyObject, IModel, MagicModel, ModelPayload } from '@movecloser/front-core'

import { HasIdentifier, Identifier } from '@/shared/contracts/data'

export interface DictData {
  name: string
  slug: string
  [key: string]: string
}

export type DictModel = MagicModel<DictData>

export interface IOwner extends IModel<OwnerData> {
  fullName (): string
  shortName (): string
}

export interface IProcess extends IModel<ProcessData> {
  isFailed (): boolean
  isPending (): boolean
  isSuccessful (): boolean
}

export interface ISite extends IModel<SiteData> {
  getProperty <Type = any>(name: string, defaultValue?: Type): Type | undefined
  hasParent (): boolean
}

export interface OwnerData extends HasIdentifier {
  avatar: string
  nickname: string
  firstName?: string
  lastName?: string
}

export type OwnerModel = MagicModel<OwnerData, IOwner>

export interface ProcessData {
  id: string
  code: number
  errors: string[]
  feedback: string[]
  result: ModelPayload
  status: ProcessStatus
  type: string
}

export type ProcessModel = MagicModel<ProcessData, IProcess>

export enum ProcessStatus {
  Canceled = 'canceled',
  Failed = 'failed',
  Finished = 'finished',
  New = 'new',
  Pending = 'pending',
  Succeed = 'succeed'
}

/**
 * Available domains.
 */
export enum Domain {
  PL = 'bnp.pl',
  EN = 'bnp.com',
}

export enum RoutePrefix {
  PL = 'pl',
  EN = 'en'
}

export interface SiteData extends HasIdentifier {
  name: string
  address: string
  domain: Domain
  locale: string
  logo: Partial<Record<SiteLogoVariant, string>>
  owner: OwnerData
  parent: SiteData|null
  properties: AnyObject
  rootContent: Identifier
  routePrefix: RoutePrefix
}

export enum SiteLogoVariant {
  Dark = 'dark',
  Light = 'light'
}

export type SiteModel = MagicModel<SiteData, ISite>
