// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'
import { UserModel } from '@module/auth/contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canCreateLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateLayout)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canDeleteLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteLayout)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canEditLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditLayout)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canViewLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewLayout)
}
