// Copyright © 2021 Move Closer

import {
  loadEditors,
  loadStatusDict
} from '@/shared/helpers/filters'

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@movecloser/ui-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const pageFiltersConfig: FiltersConfig = {
  groups: {
    title: { label: 'pages.filters.title', key: 'title', type: FilterType.String },
    editor: { label: 'pages.filters.editor', key: 'editor', type: FilterType.Typeahead, dictionaryLoader: loadEditors },
    status: { label: 'pages.filters.status', key: 'status', type: FilterType.Dictionary, dictionary: loadStatusDict() },
    cStatus: { label: 'pages.filters.cStatus', key: 'cStatus', type: FilterType.Dictionary, dictionary: loadStatusDict() },
    cTitle: { label: 'pages.filters.cTitle', key: 'cTitle', type: FilterType.String },
    createdAt: { label: 'pages.filters.createdAt', key: 'createdAt', type: FilterType.Date },
    updatedAt: { label: 'pages.filters.updatedAt', key: 'updatedAt', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams, 'parent'],
  override: { page: '1' },
  leave: ['parent', 'perPage']
}
