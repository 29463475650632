


import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { Inject, RelatedServiceType } from '@modules'
import { IRelatedService } from '@service/related'

@Component({ name: 'ConfigForm' })
export class ConfigForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public isSaving!: boolean

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public readonly icons = DashmixIconName

  /**
   * Default payload value for settings
   */
  static defaultPayload (): AnyObject {
    return {}
  }
}

export default ConfigForm
