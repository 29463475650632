















































import { VueConstructor } from 'vue'
import { debounce } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SizeMap, SortDirectionMap, TableHead, TableRowAction, TableRowActionEvent, TableRowElement, FiltersConfig } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { ConfirmModalContent } from '@component/ConfirmModal'
import { DropdownActions } from '@/shared/contracts/content'
import { Identifiable } from '@/shared/contracts/data'
import { Inject } from '@modules'
import { Modals } from '@/config/modals'
import { Query } from '@/shared/contracts/query'

import { CurrentSorting, SortToString, StringToSort } from '../../contracts/sort'
import { EmptyTable } from './partials/EmptyTable'
import { HeaderInterface, InteractiveTableMode } from './InteractiveTable.contracts'

@Component({
  name: 'InteractiveTable',
  components: { EmptyTable }
})
export class InteractiveTable extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public actionColumn!: boolean

  @Prop({ type: Object, required: false, default: () => { return {} } })
  public actions!: DropdownActions

  @Prop({ type: String, required: false, default: '' })
  public className!: string

  @Prop({ type: Object, required: false, default: () => ({}) })
  public header!: HeaderInterface

  @Prop({ type: Boolean, required: false, default: false })
  public isLoading!: boolean

  @Prop({ type: Object, required: false })
  public filtersConfig!: FiltersConfig

  @Prop({ type: Number, required: true })
  public itemsTotal!: number

  @Prop({ type: Number, required: false })
  public numberOfRows!: number

  @Prop({ type: String, required: false, default: InteractiveTableMode.Set })
  public mode!: InteractiveTableMode

  @Prop({ type: String, required: true })
  public routeName!: string

  @Prop({ type: Array, required: false, default: () => { return [] } })
  public rowActions!: TableRowAction[]

  @Prop({ type: Function, required: true })
  public rowComponent!: VueConstructor

  @Prop({ type: Boolean, required: false, default: false })
  public searchable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public selectColumn!: boolean

  @Prop({ type: Array, required: true })
  public tableData!: TableRowElement[]

  @Prop({ type: Array, required: true })
  public tableHead!: TableHead

  @Prop({ type: Number, required: false })
  public totalPages!: number

  @Prop({ type: Object, required: false, default: () => { return {} } })
  public query!: Query

  @Inject(ModalType)
  protected modalConnector!: IModal

  public isProcessing = false
  public selectedRows: string[] = []

  public get currentPage (): number {
    if (this.mode === InteractiveTableMode.Set &&
        Object.prototype.hasOwnProperty.call(this.$route.query, 'page') && this.$route.query.page != null) {
      return parseInt(this.$route.query.page as string, 10)
    }

    if (this.mode === InteractiveTableMode.Emit &&
        Object.prototype.hasOwnProperty.call(this.query, 'page') && this.query.page !== null) {
      return parseInt(this.query.page as string, 10)
    }

    return 1
  }

  public set currentPage (page: number) {
    this.setQuery({
      ...this.queryParams,
      page: page.toString()
    })
  }

  public get perPage (): number {
    if (this.mode === InteractiveTableMode.Set &&
        Object.prototype.hasOwnProperty.call(this.$route.query, 'perPage') && this.$route.query.perPage != null) {
      return Number(this.$route.query.perPage)
    }

    if (this.mode === InteractiveTableMode.Emit &&
        Object.prototype.hasOwnProperty.call(this.query, 'perPage') && this.query.perPage != null) {
      return Number(this.query.perPage)
    }

    return this.numberOfRows ? this.numberOfRows : 25
  }

  public set perPage (perPage: number) {
    this.setQuery({
      ...this.queryParams,
      perPage: perPage.toString(),
      page: '1'
    })
  }

  public get q (): string {
    if (this.mode === InteractiveTableMode.Set &&
        Object.prototype.hasOwnProperty.call(this.$route.query, 'q') && this.$route.query.q != null) {
      return this.$route.query.q as string
    }

    if (this.mode === InteractiveTableMode.Emit &&
        Object.prototype.hasOwnProperty.call(this.query, 'q') && this.query.q != null) {
      return this.query.q as string
    }

    return ''
  }

  public set q (q: string) {
    this.setQuery({
      ...this.queryParams,
      q: q.toString(),
      page: '1',
      sort: ''
    })
  }

  public get sortedBy (): CurrentSorting {
    if (this.mode === InteractiveTableMode.Set &&
        Object.prototype.hasOwnProperty.call(this.$route.query, 'sort') && this.$route.query.sort != null) {
      return StringToSort(this.$route.query.sort as string)
    }

    if (this.mode === InteractiveTableMode.Emit &&
        Object.prototype.hasOwnProperty.call(this.query, 'sort') && this.query.sort != null) {
      return StringToSort(this.query.sort as string)
    }

    return {} as CurrentSorting
  }

  public set sortedBy (sorting: CurrentSorting) {
    this.setQuery({
      ...this.queryParams,
      sort: SortToString(sorting)
    })
  }

  protected get queryParams (): Query {
    if (this.mode === InteractiveTableMode.Emit) {
      return this.query
    }
    return this.$route.query
  }

  public applyFilters (newQuery: Query) {
    this.setQuery({ ...this.queryParams, ...newQuery })
  }

  public doAction<Model extends Identifiable = Identifiable> (event: TableRowActionEvent<Model>): void {
    const { action, data } = event

    if (this.actions[action].confirmation) {
      this.modalConnector.open(Modals.Confirm, {
        content: {
          ...this.actions[action].confirmation,
          contentTitle: this.getContentTitle(data, this.actions[action].confirmation)
        },
        onConfirm: () => {
          this.actions[action].callback(data)
          this.modalConnector.close()
        }
      }, { size: SizeMap.Medium })
    } else {
      this.actions[action].callback(data)
    }
  }

  public doBulkActions (actionKey: string, selected: string[]) {
    this.isProcessing = true

    const bulkCallback = () => {
      const promises: Promise<void>[] = []

      for (const id of selected) {
        const result = this.actions[actionKey].callback(
          this.tableData.find(
            model => model.id === id
          )
        )

        if (result instanceof Promise) {
          promises.push(result)
        }
      }

      if (promises.length) {
        Promise.all(promises).finally(() => {
          this.isProcessing = false
        })
      } else {
        this.isProcessing = false
      }
    }

    if (this.actions[actionKey].confirmation) {
      this.modalConnector.open(Modals.Confirm, {
        content: {
          ...this.actions[actionKey].confirmation,
          contentTitle: this.$t('atoms.elements', { count: selected.length })
        },
        onConfirm: () => {
          bulkCallback()
          selected.length = 0
          this.modalConnector.close()
        }
      })
    } else {
      bulkCallback()
      selected.length = 0
      this.modalConnector.close()
    }
  }

  public onSelection (selected: any): void {
    this.selectedRows = selected.selected
  }

  public search (payload: string) {
    this.searchDebounce(payload)
  }

  public setQuery (newQuery: Query): void {
    if (this.mode === InteractiveTableMode.Set && JSON.stringify(this.$route.query) === JSON.stringify(newQuery)) {
      return
    }

    if (this.queryParams !== newQuery) {
      if (this.mode === InteractiveTableMode.Emit) {
        this.$emit('query:set', newQuery)
        return
      }
      this.$router.push({
        name: this.routeName,
        query: newQuery
      })
    }
  }

  public sortBy (fieldColumn: string) {
    if (this.sortedBy !== null) {
      if (this.sortedBy.field === fieldColumn) {
        this.sortedBy = {
          field: this.sortedBy.field,
          direction: this.sortedBy.direction === SortDirectionMap.Asc ? SortDirectionMap.Desc : SortDirectionMap.Asc
        }
      } else {
        this.sortedBy = {
          field: fieldColumn,
          direction: SortDirectionMap.Asc
        }
      }
    }
  }

  public updateCurrentPage (payload: number) {
    this.currentPage = payload
  }

  public updatePerPage (payload: number) {
    this.perPage = payload
  }

  protected getContentTitle<Model extends Identifiable = Identifiable> (data: Model, confirmation: ConfirmModalContent | undefined): string {
    if (Object.hasOwnProperty.call(data, 'displayName') && typeof data.displayName === 'function') {
      return data.displayName()
    } else if (confirmation && confirmation.contentTitle) {
      return confirmation.contentTitle
    }
    return ''
  }

  @Watch('sortedBy', { deep: true })
  protected onSortedByChange (sortedBy: CurrentSorting) {
    for (const headElement of this.tableHead) {
      if (!sortedBy) {
        return
      }
      headElement.isSorted = headElement.column === sortedBy.field
    }
  }

  protected searchDebounce = debounce((payload: string) => {
    this.q = payload
  }, 500)
}

export default InteractiveTable
