
























































import {
  AnyObject,
  Collection,
  DateTimeType,
  ICollection,
  IDateTime,
  IModal,
  ModalType,
  QueryParams
} from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme, SizeMap, TableRowActionEvent } from '@movecloser/ui-core'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { throttle } from 'lodash'

import { Picker, Related, RelatedType, Inject } from '@modules'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { DropdownActions } from '@/shared/contracts/content'
import { FormText, FormInput } from '@component/form'
import { Identifiable, Identifier } from '@/shared/contracts/data'
import { Loader } from '@component/Loader'
import { ScrollHandleConfig } from '@module/content/contracts'

import { AddGalleryItemIntention } from '../intentions/AddGalleryItemIntention'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import { File } from '../models/file'
import { FileModel, FileRepositoryType, GalleryModel, GalleryRepositoryType, IFileRepository, IGalleryRepository, mediaTypes } from '../contracts'
import { GalleryDraggableList } from '../components/GalleryDraggableList.vue'
import { GalleryItemListActions } from '../maps/gallery'
// import { MediaModal } from '../config/modals'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component<GalleryItems>({
  name: 'GalleryItems',
  components: { FormInput, FormText, GalleryDraggableList, Loader },
  created () {
    this.isLoading = true
    this.showLoader = true
    document.addEventListener('scroll', throttle(this.onScroll, 200), true)
    this.fileRepository.loadGalleryItems(this.gallery.id, this.query)
      .then((files: ICollection<FileModel>) => {
        this.items = GalleryItems.withPosition(files)
        this.numOfPages = Math.ceil(files.meta.total / this.perPage)
        this.isLoading = false
        this.showLoader = false
      })
  },
  destroyed () {
    document.removeEventListener('scroll', this.onScroll, true)
  }
})
export class GalleryItems extends Vue {
  @Prop({ type: Object, required: true })
  public gallery!: GalleryModel

  @Prop({ type: Boolean, required: false, default: true })
  public isEditable?: boolean

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(FileRepositoryType)
  private fileRepository!: IFileRepository

  @Inject(GalleryRepositoryType)
  private galleryRepository!: IGalleryRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public actions: DropdownActions = {
    [GalleryItemListActions.Edit]: {
      callback: (data: unknown) => {
        const model = data as FileModel
        return this.openEditFileModal(model.id)
      }
    },
    [GalleryItemListActions.Remove]: {
      callback: (data: unknown) => {
        const model = data as FileModel
        return this.removeItem(model)
      }
    }
  }

  public buttonTheme = DashmixTheme
  public icons = DashmixIconName
  public isLoading: boolean = false
  public showLoader: boolean = false
  public items: FileModel[] | null = null
  private count: number = this.gallery.childrenCount
  private numOfPages: number = 0
  private page: number = 1
  private perPage: number = 30
  public scrollConfig: ScrollHandleConfig = {
    throttleInterval: 500,
    threshold: 50
  }

  public get currentDateTime (): string {
    return this.dateTime.nowToFormat('YYYY-MM-DD HH:mm:ss')
  }

  public get query (): QueryParams {
    return { page: this.page, perPage: this.perPage }
  }

  public doAction<Model extends Identifiable = Identifiable> (event: TableRowActionEvent<Model>): void {
    const { action, data } = event
    this.actions[action].callback(data)
  }

  public onScroll () {
    const target = (this.$refs['gallery-draggable-list-container'] as HTMLElement)?.getBoundingClientRect()
    const offset = window.innerHeight / 4
    if (target && (target.bottom - offset < window.innerHeight)) {
      this.tryTurnPage()
    }
  }

  public openEditFileModal (id: Identifier) {
    this.modalConnector.open(
      'fileEdit',
      {
        file: id,
        onUpdate: (file: FileModel) => {
          if (!this.items) {
            return null
          }

          const pos = this.items.findIndex(item => item.id === id)
          this.items.splice(pos, 1, File.hydrate({ ...file }) as FileModel)
        }
      },
      { size: SizeMap.XLarge }
    )
  }

  public openFilePicker () {
    this.modalConnector.open(Picker.File, {
      multiple: true,
      onSelection: this.addItems,
      onClose: () => this.modalConnector.close(),
      config: {
        allowedMediaType: mediaTypes.Image
      }
    }, { size: SizeMap.XLarge })
  }

  protected addItem (files: FileModel[]) {
    this.isLoading = true
    const itemIntensions: AddGalleryItemIntention[] = []

    for (let i = 0; i < files.length; i++) {
      files[i].set('position', 1)
      itemIntensions.push(new AddGalleryItemIntention(files[i]))
    }

    this.galleryRepository.addItems(this.gallery.id, itemIntensions).then(() => {
      if (!this.items) {
        return null
      }

      this.count += itemIntensions.length
      this.items = [...files, ...GalleryItems.incrementPosition(this.items)]
      this.modalConnector.close()
      this.fileRepository.loadGalleryItems(this.gallery.id, this.query)
        .then((files: ICollection<FileModel>) => {
          this.items = GalleryItems.withPosition(files)
          this.numOfPages = Math.ceil(files.meta.total / this.perPage)
        })
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  protected async addItems (data: Related<RelatedType.File, Identifier>[]) {
    const hydratedFiles: FileModel[] = []
    for (let i = 0; i < data.length; i++) {
      const file = await this.relatedService.describe({ type: RelatedType.File, value: data[i].value }) as AnyObject
      hydratedFiles.push(File.hydrate(file))
    }
    this.addItem(hydratedFiles)
  }

  protected async handleReorder ({ newIndex, oldIndex }: { newIndex: number; oldIndex: number }) {
    if (newIndex === oldIndex || !this.items) {
      return
    }

    this.isLoading = true

    const intention = new ChangeItemsOrderIntention({
      id: this.items[oldIndex].id,
      position: newIndex + 1
    })

    this.galleryRepository.changeOrder(this.gallery.id, intention)
      .catch((error: Error) => {
        console.log(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  protected static incrementPosition (collection: FileModel[]) {
    return new Collection<FileModel>(
      [...collection].map(
        (model: FileModel) => {
          return File.hydrate(
            {
              ...model.toObject(),
              position: model.position + 1
            }
          )
        }
      )
    )
  }

  @Watch('page')
  protected async loadMore () {
    this.isLoading = true
    this.showLoader = true
    this.fileRepository.loadGalleryItems(this.gallery.id, this.query).then((files: ICollection<FileModel>) => {
      if (!this.items) {
        return
      }

      if (files.length) {
        this.items = new Collection<FileModel>([...this.items, ...GalleryItems.withPosition(files)])
      }
    }).catch((error: Error) => {
      console.log(error)
    }).finally(() => {
      this.isLoading = false
      this.showLoader = false
    })
  }

  protected tryTurnPage () {
    if (!this.isLoading && this.page < this.numOfPages) {
      this.page++
    }
  }

  protected removeItem (file: FileModel) {
    this.isLoading = true
    this.galleryRepository.removeItem(this.gallery.id, file.id).then(() => {
      if (!this.items) {
        return
      }

      this.count--
      this.items = this.items.filter(item => item.id !== file.id).map(item => {
        return item.position > file.position
          ? item
          : File.hydrate(
            {
              ...item.toObject(),
              position: item.position - 1
            }
          )
      })
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  protected static filterChangedPosition (newItem: FileModel, index: number) {
    return newItem.position !== index
  }

  protected static withPosition (collection: FileModel[]) {
    return new Collection<FileModel>(
      [...collection].map(
        (model: FileModel, index: number) => {
          return File.hydrate(
            {
              ...model.toObject(),
              position: index
            }
          )
        }
      )
    )
  }

  protected static withTravelDistance (collection: FileModel[]) {
    return new Collection<FileModel>(
      [...collection].map(
        (model: FileModel, index: number) => {
          return File.hydrate(
            {
              ...model.toObject(),
              travelDistance: Math.abs(model.position - index)
            }
          )
        }
      )
    )
  }
}

export default GalleryItems
