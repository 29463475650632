// Copyright © 2022 Move Closer

import { Builder, Viewer } from '@movecloser/page-builder'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { FormSelect } from '@component/form'

import { ContainerDrawer, FormDrawer, ModeSwitcher, Module } from './partials'
import { PageBuilderProps } from './PageBuilder.contracts'
import { pageBuilderProps, usePageBuilder } from './PageBuilder.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const PageBuilder = defineComponent({
  name: 'PageBuilder',
  components: { Builder, ContainerDrawer, FormDrawer, FormSelect, ModeSwitcher, Viewer },
  props: pageBuilderProps,

  setup (props: PageBuilderProps, ctx: SetupContext) {
    return {
      ...usePageBuilder(props, ctx),
      module: Module
    }
  },

  template: `
    <!-- Editing mode -->
    <div v-if="isBuilderActive">
      <div class="d-flex justify-content-between flex-row-reverse page-builder__controls"
           :class="{'shadow-sm': isSticky}">
        <ModeSwitcher v-if="!noPreview" :controls.sync="showControls" />
        <FormSelect class="col-3 pl-0" :model.sync="breakpoint" :options="breakpointOptions"
                    name="breakpoint" form="pageBuilder"
                    @update:model="changeBreakpoint" />
      </div>

      <section :id="showControls ? null : 'preview'" :class="{'page-builder--xs': isMobile}">
        <DsAlert v-if="!showControls" theme="warning">
          <small>{{ $t('builder.preview-alert') }}</small>
        </DsAlert>

        <Builder class="mt-3" :breakpoint="breakpoint" :config="config"
                 :containerComponent="containerComponent" :moduleComponent="module"
                 :containers.sync="_containers" :modules.sync="_modules" :structure.sync="_structure"
                 :replicateContainer="replicateContainer" :replicateModules="replicateModules"
                 :elementProps="elementProps" v-on="listeners" />
      </section>

      <ContainerDrawer v-if="containerToEdit" :source="_containers[containerToEdit]" :relatedService="relatedService"
                       @apply="editContainer" :variant="fromParent.variant" @close="() => { containerToEdit = null }" />

      <FormDrawer v-if="moduleToEdit" :source="_modules[moduleToEdit]"
                  :relatedService="relatedService" :variant="fromParent.variant"
                  @apply="editModule" @close="() => { moduleToEdit = null }" />
    </div>
    <!-- END | Editing mode -->

    <!-- Preview mode -->
    <section v-else id="preview">
      <DsAlert v-if="!showControls" theme="warning">
        <small>{{ $t('builder.preview-alert') }}</small>
      </DsAlert>
      <Viewer v-bind="{ breakpoint, config, containerComponent, elementProps }"
              :containers="_containers" :moduleComponent="module"
              :modules="_modules" :structure="_structure" />
    </section>
    <!-- END | Preview mode -->
  `
})
