// Copyright © 2021 Move Closer

import { FilePondOptions } from 'filepond'

/**
 * This config allows to setup fileUploader server settings
 *
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const uploader: FilePondOptions = {
  server: {
    url: process.env.VUE_APP_HTTP_BNP_API_BASEURL?.replace(/\/\s*$/, ''),
    process: {
      url: '/api/management/storage/files/',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Language': process.env.VUE_APP_HTTP_BNP_API_LOCALE || 'en-GB',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'X-Client': process.env.VUE_APP_HTTP_BNP_API_CLIENT
      }
    },
    fetch: null,
    revert: null
  },
  maxFileSize: process.env.VUE_APP_UPLOAD_MAX_FILESIZE || '50MB'
}
