// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { computed, PropType, ref, SetupContext } from '@vue/composition-api'

import {
  ModuleComponentsRegistry,
  ResolvedModuleEntry,
  SelectModuleModalPayload,
  SelectModuleModalProps
} from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const selectModuleModalProps: ComponentObjectPropsOptions<SelectModuleModalProps> = {
  payload: {
    type: Object as PropType<SelectModuleModalPayload>,
    required: true
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useSelectionModuleModal (props: SelectModuleModalProps, ctx: SetupContext) {
  const chosenModule = ref<string | null>(null)
  const minQueryLength: number = 3
  const query = ref<string>('')

  const _registry = computed<ModuleComponentsRegistry>(() => {
    return ctx.root.$options.configuration?.byKey('builder.moduleRegistry') || {}
  })

  const isSelected = computed<boolean>(() => !!chosenModule.value)
  const list = computed<ResolvedModuleEntry[]>(() => {
    const list: ResolvedModuleEntry[] = []

    for (const m of props.payload.modulesRegistry) {
      if (!_registry.value[m]) {
        continue
      }

      list.push({
        image: _registry.value[m].image,
        label: ctx.root.$i18n.t(_registry.value[m].label).toString(),
        value: m
      })
    }

    return list
  })

  const modules = computed<ResolvedModuleEntry[]>(() => {
    return list.value.filter(m => {
      return query.value.length < minQueryLength ||
        m.label.indexOf(query.value) !== -1 ||
        m.label.toLowerCase().indexOf(query.value.toLowerCase()) !== -1
    }).sort((a, b) => {
      return query.value.length < minQueryLength ? 0 : a.label.localeCompare(b.label)
    })
  })

  function addSelected () {
    props.payload.onSelection(chosenModule.value)
    ctx.emit('close')
  }

  return { addSelected, chosenModule, isSelected, modules, query }
}
