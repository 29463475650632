

























import { Component } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'

import { DateTimePickerType } from '@movecloser/ui-core'

import { Addon } from '../../maps/variant'
import { DelayedDates } from '../../contracts'
import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'DelayedAddon',
  components: { AddonErrors }
})
export class DelayedAddon extends AbstractAddon {
  public dateTimePickerType = DateTimePickerType
  public delayedDates: DelayedDates = { from: '', to: '' }

  public get delayFrom (): string {
    return this.delayedDates.from ?? ''
  }

  public set delayFrom (from: string) {
    this.delayedDates.from = from
    this.setNewDates(this.delayedDates)
  }

  public get delayTo (): string {
    return this.delayedDates.to ?? ''
  }

  public set delayTo (to: string) {
    this.delayedDates.to = to
    this.setNewDates(this.delayedDates)
  }

  public disabledAfterToday (date: Date) {
    const today = new Date()

    return date > today
  }

  public disabledBeforeToday (date: Date) {
    const today = new Date()
    today.setDate(today.getDate() - 1)

    return date < today
  }

  public publicationDate (date: Date) {
    if (this.delayFrom.length === 0) {
      return this.disabledBeforeToday(date)
    }

    const publicationDate = new Date(this.delayFrom)

    return date < publicationDate
  }

  public unpublicationDate (date: Date) {
    if (this.delayTo.length === 0) {
      return this.disabledBeforeToday(date)
    }

    const unpublishedDate = new Date(this.delayTo)

    return date > unpublishedDate || this.disabledBeforeToday(date)
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const datesFromModel = this.variant.getProperty<DelayedDates>(Addon.Delayed)

      if (!datesFromModel) {
        this.delayedDates = { from: '', to: '' }
        return
      }

      this.delayedDates = { ...this.delayedDates, ...datesFromModel }
    }
  }

  protected setNewDates: DebouncedFunc<(dates: DelayedDates) => void> =
    debounce((dates: DelayedDates) => {
      this.setNewDates.cancel()

      this.variant.setProperty<DelayedDates>(Addon.Delayed, dates)

      this.onChange(this.variant)
    }, 1000)
}

export default DelayedAddon
