



































import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator'
import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'
import { AnyObject } from '@movecloser/front-core'

import { ALL_CONTAINERS_MOUNTED_INJECTION_KEY } from '@modules'
import { Identifier } from '@/shared/contracts/data'
import { IRelatedService } from '@service/related'
import { PageBuilder } from '@component/PageBuilder'
import { Property } from '@component/Property'

import { UserData } from '@module/auth/contracts/models'

import { ContentType, OnDirtyCallback, OnVariantChangeCallback } from '../contracts'
import { VariantModel } from '../contracts/models'
import { pageModules } from '../maps/modules'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<PageVariant>({
  name: 'PageVariant',
  components: { PageBuilder, Property },
  created () {
    this.containers = this.variant.containers
    this.modules = this.variant.modules
    this.structure = this.variant.structure
  },

  mounted () {
    this.isMounted = true
  }
})
export class PageVariant extends Vue {
  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData|null

  @Prop({ type: String, required: true })
  public contentType!: ContentType

  @Prop({ type: Object, required: false, default: null })
  public errors!: AnyObject

  @Prop({ type: Function, required: true })
  protected onDirty!: OnDirtyCallback

  @Prop({ type: Function, required: true })
  protected onVariantChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: true })
  public variant!: VariantModel

  /**
   * Determines whether the component has been mounted.
   */
  @ProvideReactive(ALL_CONTAINERS_MOUNTED_INJECTION_KEY)
  public isMounted: boolean = false

  public containers: ContainersRegistry = {}
  public modules: ModulesRegistry = {}
  public modulesRegistry: string[] = pageModules
  public structure: ContentStructure = {}
  public variantErrors: AnyObject = {}

  public get title (): string {
    return this.variant.title
  }

  public set title (value: string) {
    this.variant.set('title', value)
    this.onVariantChange(this.variant)
  }

  public get titleError (): string | null {
    return this.variantErrors && this.variantErrors.title && this.variantErrors.title.length > 0
      ? this.variantErrors.title[0] : null
  }

  public isEditable (): boolean {
    return this.variant.isEditable(this.authUser ? (this.authUser.id as Identifier) : null)
  }

  @Watch('errors', { deep: true })
  public onErrorsChange (err: AnyObject): void {
    if (err && err.variant && err.variant[this.variant.id]) {
      this.variantErrors = err.variant[this.variant.id]
    } else {
      this.variantErrors = {}
    }
  }

  public onContainersChange (changed: ContainersRegistry): void {
    this.containers = changed

    this.variant.set('containers', this.containers)
    this.onVariantChange(this.variant)
  }

  public onModulesChange (changed: ModulesRegistry): void {
    this.modules = changed

    this.variant.set('modules', this.modules)
    this.onVariantChange(this.variant)
  }

  public onStructureChange (changed: ContentStructure): void {
    this.structure = changed

    this.variant.set('structure', this.structure)
    this.onVariantChange(this.variant)
  }
}

export default PageVariant
