// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Identifiable, Identifier } from '@/shared/contracts/data'

import { IUser, UserModel, UsersInfoData } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class User extends Model<UserModel> implements IUser, Identifiable {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'avatar',
      'deletedAt',
      'email',
      'firstName',
      'isActive',
      'isBlocked',
      'isEditor',
      'lastLogin',
      'lastName',
      'roles',
      'nickname',
      'registeredAt',
      'hasActiveSubscription',
      'subscriptionId',
      'isSubscriber'
    ]
  }

  public fullName (): string {
    if (this._data.lastName && this._data.firstName) {
      return `${this._data.firstName} ${this._data.lastName}`
    }

    return this._data.nickname
  }

  public displayName (): string {
    return this._data.nickname
  }

  public identifier (): Identifier {
    return this._data.id
  }

  public switchBlocked (blocked: boolean): void {
    this._data.isBlocked = blocked
  }
}

export class UsersInfo extends Model<UsersInfoData> {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = ['userCount', 'userSubscriptionCount', 'latestUserCount']
  }
}
