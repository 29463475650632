






























































import {
  Authentication, AuthServiceType, DateTimeType,
  ICollection, IDateTime, IModal, IResponse,
  IValidation, ModalType, ResourceActionFailed, ValidationType
} from '@movecloser/front-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  DashmixBreadcrumbsProps,
  DashmixIconName,
  DashmixTheme,
  SizeMap,
  TableHead,
  TableRowAction
} from '@movecloser/ui-core'
import { FilePondFile } from 'filepond'

import { DropdownActions } from '@/shared/contracts/content'
import { EmptyTable } from '@component/InteractiveTable/partials/EmptyTable'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@modules'
import { UserModel } from '@module/auth/contracts/models'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import {
  DirectoryCreatePayload, DirectoryData, DirectoryEditPayload,
  DirectoryModel, DirectoryRepositoryType, FileCreatePayload,
  FileModel, FileRepositoryType,
  IDirectoryRepository, IFileRepository, MediaMovePayload
} from '../contracts'
import { createBreadcrumbsFromDirectory } from '../helpers'
import { CreateDirectoryIntention } from '../intentions/CreateDirectoryIntention'
import { CreateFileIntention } from '../intentions/CreateFileIntention'
import { Directory } from '../models/directory'
import { EditDirectoryIntention } from '../intentions/EditDirectoryIntention'
import { File } from '../models/file'
import { IMediaPermissions, MediaPermissions } from '../config/permissions.mixin'
import { MediaActions, mediaRowActionsFactory, mediaTableHead } from '../maps/media'
import { MediaModal } from '../config/modals'
import { MediaTableRow } from '../components/MediaTableRow.vue'
import { MediaView } from '../components/MediaView.vue'
import { TilesGrid } from '../components/TilesGrid.vue'
import { ConnectorErrors } from '@/shared/exceptions/connector-errors'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'MediaList',
  components: { EmptyTable, MediaTableRow, TilesGrid, MediaView }
})
export class MediaList extends Mixins<IMediaPermissions>(MediaPermissions) {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(DirectoryRepositoryType)
  protected directoryRepository!: IDirectoryRepository

  @Inject(FileRepositoryType)
  protected fileRepository!: IFileRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ValidationType)
  protected validation!: IValidation

  public actions: DropdownActions = {
    [MediaActions.OpenDirectory]: {
      callback: (data: unknown) => {
        const model = data as DirectoryModel
        return this.openDirectory(model.id)
      }
    },
    [MediaActions.EditFile]: {
      callback: (data: unknown) => {
        const model = data as FileModel
        return this.openEditFileModal(model.id)
      }
    },
    [MediaActions.EditDirectory]: {
      callback: (data: unknown) => {
        const model = data as DirectoryModel
        return this.openEditDirectoryModal(model.id)
      }
    },
    [MediaActions.DeleteDirectory]: {
      callback: (data: unknown) => {
        const model = data as DirectoryModel
        return this.deleteDirectory(model.id)
      },
      confirmation: {
        header: 'actions.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },
    [MediaActions.DeleteFile]: {
      callback: (data: unknown) => {
        const model = data as FileModel
        return this.deleteFile(model.id)
      },
      confirmation: {
        header: 'actions.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },
    [MediaActions.MoveFiles]: {
      callback: (data: unknown) => {
        if ((data as FileModel).id) {
          const model = data as FileModel
          return this.openMoveFilesModal([model.id])
        }
        if ((data as string[])[0]) {
          const ids = data as string[]
          return this.openMoveFilesModal(ids.map(id => parseInt(id)))
        }
      }
    },
    [MediaActions.MoveDirectory]: {
      callback: (data: unknown) => {
        const model = data as DirectoryModel
        return this.openMoveDirectoryModal(model.id)
      }
    }
  }

  public breadcrumbs: DashmixBreadcrumbsProps = initBreadcrumbs
  public currentDirectory: DirectoryData | null = null
  public icons = DashmixIconName
  public isLoading = false
  public get rowActions (): TableRowAction[] {
    return mediaRowActionsFactory(this.domain, this.user)
  }

  public tableHead: TableHead = mediaTableHead
  public tileMode = true

  public get currentDateTime (): string {
    return this.dateTime.nowToFormat('YYYY-MM-DD HH:mm:ss')
  }

  public get searchMode (): boolean {
    return !!this.$route?.query?.q
  }

  mounted () {
    if (this.$route?.query?.editId) {
      this.openEditFileModal(parseInt(this.$route.query.editId as string))
    }
  }

  public async createDirectory (payload: DirectoryCreatePayload): Promise<void> {
    const intention = new CreateDirectoryIntention(payload)

    this.directoryRepository.createDirectory(intention.toRequest()).then(id => {
      const directory: DirectoryModel = Directory.hydrate({
        name: intention.toModel().name,
        parent: this.currentDirectory,
        id,
        createdAt: this.currentDateTime,
        updatedAt: this.currentDateTime
      })

      this.addDirectoryToCurrentDirectory(directory)
      this.modalConnector.close()
    }).catch(error => {
      console.error(error)
    })
  }

  public async createFile (id: Identifier, payload: FileCreatePayload, file: FilePondFile, force: boolean = false):
    Promise<boolean> {
    const intention = new CreateFileIntention(payload)

    return this.fileRepository.createFile(id, intention.toRequest(), force).then((response: IResponse) => {
      const fileModel: FileModel = File.hydrate({
        ...intention.toModel(),
        id,
        addedBy: this.user,
        file: {
          file: response?.data?.data?.file || URL.createObjectURL(file.file) || '',
          thumbnail: response?.data?.data?.thumbnail || URL.createObjectURL(file.file) || ''
        },
        directory: this.currentDirectory,
        size: file.fileSize,
        mime: file.fileExtension,
        createdAt: this.currentDateTime,
        updatedAt: this.currentDateTime
        // pending: true - set when async turned on
      })

      this.addFileToCurrentDirectory(fileModel)
      return true
    })
      .catch((errors: ResourceActionFailed[]) => {
        for (const error of errors) {
          if (error.status === ConnectorErrors.Unknown) {
            console.error(error)
          } else {
            this.validation.pushErrors(
              'createFile_' + file.id,
              { [error?.payload.name ?? 'lost_error_name']: [error.message] }
            )
          }
        }
        return false
      })
  }

  public async deleteDirectory (id: Identifier): Promise<void> {
    return this.directoryRepository.deleteDirectory(id).then(() => {
      this.removeDirectoryFromCurrentDirectory(id)
    }).catch(error => {
      console.error(error)
    })
  }

  public async deleteFile (id: Identifier): Promise<void> {
    return this.fileRepository.deleteFile(id).then(() => {
      this.removeFileFromCurrentDirectory(id)
    }).catch(error => {
      console.error(error)
    })
  }

  public async updateDirectory (id: Identifier, payload: DirectoryEditPayload): Promise<void> {
    const intention = new EditDirectoryIntention({ ...payload, updatedAt: this.currentDateTime })

    this.directoryRepository.updateDirectory(id, intention.toRequest()).then(() => {
      if (!this.currentDirectory) {
        return
      }

      const dir: DirectoryModel | undefined = this.currentDirectory.directories.find(dir => dir.id === id)
      if (!dir) {
        return
      }

      dir.applyIntention(intention)
      this.updateDirectoryInCurrentDirectory(id, dir)
      this.modalConnector.close()
    }).catch(error => {
      console.error(error)
    })
  }

  public async moveDirectory (id: Identifier, payload: MediaMovePayload): Promise<void> {
    this.directoryRepository.moveDirectory(id, payload).then(() => {
      if (payload.directory !== this.currentDirectory?.id) {
        this.removeDirectoryFromCurrentDirectory(id)
      }
      this.modalConnector.close()
    }).catch(error => {
      console.error(error)
    })
  }

  public async moveFile (id: Identifier, payload: MediaMovePayload): Promise<void> {
    this.fileRepository.moveFile(id, payload).then(() => {
      if (payload.directory !== this.currentDirectory?.id) {
        this.removeFileFromCurrentDirectory(id)
      }
      this.modalConnector.close()
    }).catch(error => {
      console.error(error)
    })
  }

  public openCreateDirectoryModal () {
    if (this.searchMode) {
      this.openDirectory(this.currentDirectory?.id ?? 1)
    }

    this.modalConnector.open(
      MediaModal.DirectoryCreate,
      {
        parentDirectory: this.currentDirectory,
        createDirectory: this.createDirectory
      }, { size: SizeMap.Medium })
  }

  public openCreateFileModal () {
    if (this.searchMode) {
      this.openDirectory(this.currentDirectory?.id ?? 1)
    }

    this.modalConnector.open(
      MediaModal.FileCreate,
      {
        parentDirectory: this.currentDirectory,
        createFile: this.createFile
      }, { size: SizeMap.XLarge })
  }

  public openEditDirectoryModal (id: Identifier) {
    this.modalConnector.open(
      MediaModal.DirectoryEdit,
      {
        directory: this.currentDirectory?.directories.find(d => d.id === id),
        updateDirectory: this.updateDirectory
      }, { size: SizeMap.Medium })
  }

  public openEditFileModal (id: Identifier) {
    this.modalConnector.open(
      MediaModal.FileEdit,
      {
        file: id,
        onDelete: () => this.removeFileFromCurrentDirectory(id),
        onUpdate: (file: FileModel) => this.updateFileInCurrentDirectory(id, file)
      },
      { size: SizeMap.XLarge }
    )
  }

  public openMoveDirectoryModal (id: Identifier) {
    this.modalConnector.open(
      MediaModal.DirectoryMove,
      {
        directory: this.currentDirectory?.directories.find(d => d.id === id),
        moveDirectory: this.moveDirectory
      }, { size: SizeMap.Large })
  }

  public openMoveFilesModal (ids: Identifier[]) {
    this.modalConnector.open(
      MediaModal.FilesMove,
      {
        files: this.currentDirectory?.files.filter(d => ids.includes(d.id)),
        moveFile: this.moveFile
      }, { size: SizeMap.Large })
  }

  protected addDirectoryToCurrentDirectory (newDirectory: DirectoryModel): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory,
        directories: [newDirectory].concat(this.currentDirectory.directories) as ICollection<DirectoryModel>
      }
    }
  }

  protected addFileToCurrentDirectory (newFile: FileModel): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory,
        files: [newFile].concat(this.currentDirectory.files) as ICollection<FileModel>
      }
    }
  }

  protected updateDirectoryInCurrentDirectory (id: Identifier, directory: DirectoryModel): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory
      }
    }
  }

  protected updateFileInCurrentDirectory (id: Identifier, file: FileModel): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory
      }
    }
  }

  @Watch('currentDirectory', { deep: true })
  protected onCurrentDirectoryChange (model: DirectoryModel): void {
    this.breadcrumbs.items = createBreadcrumbsFromDirectory(model, this.searchMode)
  }

  protected openDirectory (id: Identifier) {
    this.isLoading = true
    this.$router.push({
      name: 'media.showDirectory',
      params: { id: id.toString() }
    })
  }

  protected removeDirectoryFromCurrentDirectory (id: Identifier): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory,
        directories: this.currentDirectory.directories.filter(rowData =>
          rowData.id !== id) as ICollection<DirectoryModel>
      }
    }
  }

  protected removeFileFromCurrentDirectory (id: Identifier): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory,
        files: this.currentDirectory.files.filter(rowData =>
          rowData.id !== id) as ICollection<FileModel>
      }
    }
  }
}

export default MediaList
