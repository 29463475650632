// Copyright © 2021 Move Closer

import { AuthConfig, TokenDriver } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const auth: AuthConfig = {
  refreshThreshold: 60,
  validThreshold: 5,
  tokenName: 'bnp_cms_app_token',
  tokenDriver: TokenDriver.Single
}
