















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICollection } from '@movecloser/front-core'

import { Inject } from '@modules'
import { Item, Node, TreeViewer } from '@component/TreeViewer'
import { Loader } from '@component/Loader'

import { FormMode } from '../contracts/components'
import { ContentStatus, NodeModel } from '../contracts/models'
import { INodesRepository, NodesRepositoryType } from '../contracts/repositories'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'NodeSelect',
  components: { Loader, TreeViewer }
})
export class NodeSelect extends Vue {
  @Inject(NodesRepositoryType)
  protected nodesRepository!: INodesRepository

  @Prop({ type: Array, required: true })
  public selectedNodesList!: Item[]

  @Prop({ type: Boolean, required: false, default: true })
  public showHidden!: boolean

  @Prop({ type: String, required: true })
  public formMode!: FormMode

  @Prop({ type: Boolean, required: false, default: false })
  public onlyPublished?: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public skippedRoot?: boolean

  public isLoading = false
  public nodesList: Node[] = []

  mounted () {
    this.loadNodes()
  }

  public loadNodes (): void {
    this.isLoading = true

    this.nodesRepository.loadTree(this.onlyPublished ? { status: ContentStatus.Published } : {})
      .then(node => {
        const nodeList = this.mapNodesToTree(node)

        if (!this.showHidden) {
          // TODO implement filtering when API will return 'properties'
          this.nodesList = nodeList[0].children
        } else {
          this.nodesList = nodeList[0].children
        }
        if (this.selectedNodesList.length === 0) {
          this.$emit('selectedNodes', nodeList)
        }
      })
      .catch(error => {
        // TODO - handling errors
        console.warn(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public selectNode (selectedItemsList: Item[]) {
    this.$emit('selectedNodes', selectedItemsList)
  }

  protected mapNodesToTree (dataTree: ICollection<NodeModel>): Node[] {
    if (!dataTree) {
      return []
    }

    return [...dataTree].map(node => {
      return {
        label: node.name,
        value: node.id,
        slug: node.slug,
        children: this.mapNodesToTree(node.nodes)
      }
    })
  }
}

export default NodeSelect
