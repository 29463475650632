

































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '@modules'

import { IMediaPermissions, MediaPermissions } from '../config/permissions.mixin'

import { GalleryData } from '../contracts'

@Component({
  name: 'GalleryTableRow',
  components: { Fragment }
})
export class GalleryTableRow extends Mixins<IMediaPermissions>(MediaPermissions) {
  @Prop({ type: Object, required: true })
  protected data!: GalleryData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public Icons = DashmixIconName
}

export default GalleryTableRow
