// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { computed, ComputedRef, SetupContext } from '@vue/composition-api'

import { FileUploaderProps } from './FileUploader.contracts'
import { resolve } from '@modules'
import { AnyObject, Authentication, AuthServiceType } from '@movecloser/front-core'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { composeUploaderConfig } from '@component/FileUploader/FileUploader.helpers'
import { allowedTypes, mediaTypes } from '@module/media/contracts'

export const fileUploaderProps: ComponentObjectPropsOptions<FileUploaderProps> = {

  /**
   * Allows upload only of specified mimes
   */
  allowedMediaType: {
    type: String,
    required: false,
    default: mediaTypes.File
  },

  /**
   * Show filepond's built-in button for upload and retry upload
   */
  allowProcess: {
    type: Boolean,
    required: false,
    default: false
  },

  /**
   * Contains processed files
   */
  files: {
    type: Array,
    required: false
  },

  /**
   * Allow multiple file upload
   */
  multiple: {
    type: Boolean,
    required: false,
    default: false
  },

  /**
   * Show thumbnail if available
   */
  showPreview: {
    type: Boolean,
    required: false,
    default: true
  },

  /**
   * File will be processed (send to server) after selecting it
   */
  instantUpload: {
    type: Boolean,
    required: false,
    default: false
  }
}

export const useFileUploader = (props: FileUploaderProps, ctx: SetupContext) => {
  const authService: Authentication<any> = resolve(AuthServiceType)
  const siteResolver: ISiteResolver = resolve(SiteResolverType)

  const model = computed<AnyObject>({
    get: () => {
      return props.files
    },
    set: (value: AnyObject) => {
      if (value) {
        ctx.emit('update:files', props.files)
      }
    }
  })

  const settings: ComputedRef<AnyObject> = computed(() => {
    return composeUploaderConfig(
      { ...ctx.root!.$options!.configuration!.byFile('uploader') },
      authService.getAuthorizationHeader(),
      siteResolver.getSiteHeader()
    )
  })

  return { model: model, settings: settings }
}
