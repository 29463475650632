// Copyright © 2021 Move Closer

import { mapCollection, MappingConfig, MappingTypes } from '@movecloser/front-core'

import { ownerAdapterMap } from '@module/root/models/owner.adapter'
import { siteAdapterMap } from '@module/root/models/site.adapter'

export const contentAdapterMap: MappingConfig = {
  childrenCount: 'childrenCount',
  children: {
    type: MappingTypes.Function,
    value: item => item.children || []
  },
  createdAt: 'createdAt',
  editors: {
    type: MappingTypes.Function,
    value: item => {
      if (item.editors && Array.isArray(item.editors)) {
        return mapCollection(item.editors, ownerAdapterMap)
      }
      return null
    }
  },
  id: 'id',
  parent: 'parent',
  properties: 'properties',
  site: {
    type: MappingTypes.Adapter,
    map: siteAdapterMap,
    value: 'site'
  },
  slug: 'slug',
  status: 'status',
  title: 'title',
  type: 'type',
  variantsCount: 'variantsCount',
  variants: {
    type: MappingTypes.Function,
    value: item => item.variants || []
  },
  updatedAt: 'updatedAt',
  url: {
    type: MappingTypes.Function,
    value: item => item.url || null
  }
}
