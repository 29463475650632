


















import { Component } from 'vue-property-decorator'

import { AbstractSetItem } from './AbstractSetItem'
import { OffersItemData } from '../../contracts'

@Component
export class OffersSetItem extends AbstractSetItem<OffersItemData> {
  public get city (): string {
    if (!this.data.city) {
      return '--'
    }
    return this.data.city
  }

  public get departments (): string {
    if (!this.data.departments || this.data.departments.length === 0) {
      return '--'
    }
    return this.data.departments.map(i => i.name).join(', ')
  }
}

export default OffersSetItem
