// Copyright © 2022 Move Closer

import { DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'
import { debounce } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Inject, log, Related } from '@modules'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { Loader } from '@component/Loader'
import { PickerPayload } from '@module/content/contracts'
import { IRelatedService, RelatedServiceType } from '@service/related'

import SetPickerItem from '../SetPickerItem.vue'
import SetSidebar from '../SetSidebar.vue'
import { ISetsRepository, SetModel, SetsRepositoryType, SetType } from '../../contracts'

@Component<AbstractSetPickerModal<SetType>>({
  name: 'AbstractSetPickerModal',
  components: { Loader, SetPickerItem, SetSidebar },

  created () {
    const promises: Promise<void>[] = []

    this.site = this.siteResolver.getSite()?.id
    this.siteOptions = [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          label: site.domain,
          value: site.id
        }
      })

    if (this.payload.selected) {
      let selectedIds: Identifier[] = []

      if (this.payload.multiple) {
        selectedIds = (this.payload.selected as Related<SetType, Identifier>[]).map(s => s.value)
      } else {
        selectedIds = [(this.payload.selected as Related<SetType, Identifier>).value]
      }

      selectedIds.forEach(id => promises.push(this.loadSet(id, true)))
    }

    Promise.all(promises).then(() => {
      this.loadSetList()
    })
  },
  template: `
    <DsModalInner @close="close" class="set-picker">
      <template v-slot:header>
        <h5 class="modal-title">{{ $t('sets.picker.title') }}</h5>
      </template>

    <DsSelect class="mb-3" :model.sync="site" :options="siteOptions" @update:model="changeSets()" />

    <DsInput
      name="query"
      :model.sync="query"
      :placeholder="$t('sets.picker.searchPlaceholder')"
      @input="search"
    />

    <div class="list-picker-content">
      <div class="list-container flex-column d-flex justify-content-between">
        <div class="list">
          <SetPickerItem
            v-for="item in setList"
            :key="item.id"
            :item="item"
            :isActive="activeSet ? (activeSet.id === item.id) : false"
            :isSelected="isSelected(item.id)"
            :isMultiple="isMultiple"
            @select="toggleSet(item)"
            @click="loadSet(item.id, !isMultiple)"
          />
        </div>
      </div>
      <div class="content">
        <template v-if="isSetLoading">
          <Loader/>
        </template>
        <template v-else>
          <SetSidebar
            v-if="activeSet"
            :set="activeSet"
            :setType="setType"
            :siteId="site"
            :isEditable="false"
          />
        </template>
      </div>
    </div>

    <template v-slot:footer>
      <DsButton
        :label="$t('sets.picker.select')"
        :icon="icons.Save"
        :disabled="!isReady"
        theme="info"
        @click="apply" />
    </template>
    </DsModalInner>
  `
})
export class AbstractSetPickerModal<Type> extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<SetModel, Type, Identifier>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public site: Identifier | undefined
  public siteOptions: DashmixSelectItem[] = []
  public setType!: SetType

  protected query: string = ''
  protected setList: SetModel[] = []
  protected selected: SetModel[] = []
  protected activeSet: SetModel | null = null

  private icons = DashmixIconName
  private isLoading: boolean = true
  private isSetLoading: boolean = false

  get isReady (): boolean {
    return !!this.selected && !!this.selected.length
  }

  get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public apply (): void {
    if (!this.setType) {
      throw new Error('[AbstractSetPickerModal]: Piker type not define!')
    }
    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selected.map(item => {
        return { type: this.setType, value: item.id } as unknown as Related<Type, Identifier>
      })

      this.payload.onSelection(this.isMultiple ? result : result[0])
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public isSelected (id: Identifier): boolean {
    return !!this.selected && this.selected.filter(item => item.id === id).length > 0
  }

  public loadSet (id: Identifier, isSelected: boolean): Promise<void> {
    this.isSetLoading = true

    return new Promise((resolve, reject) => {
      this.setsRepository.load(id, this.site)
        .then((set: SetModel) => {
          if (isSelected) {
            this.updateSelected(set)
          }
          this.activeSet = set

          resolve()
        })
        .catch(error => {
          log(error, 'error')

          reject(error)
        })
        .finally(() => {
          this.isSetLoading = false
        })
    })
  }

  public loadSetList () {
    this.isLoading = true

    if (!this.setType) {
      throw new Error('[AbstractSetPickerModal] Piker type not define!')
    }

    this.setsRepository.loadCollection(this.setType, { q: this.query }, this.site)
      .then((setList: ICollection<SetModel>) => {
        this.setList = setList
      })
      .catch(error => {
        log(error, 'error')
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public changeSets () {
    this.activeSet = null
    this.selected = []

    this.$nextTick(() => {
      this.loadSetList()
    })
  }

  public toggleSet (set: SetModel) {
    if (this.isSelected(set.id)) {
      this.selected = this.selected.filter(item => item.id !== set.id)
    } else {
      this.selected.push(set)
    }
  }

  public search () {
    this.searchDebounce()
  }

  public searchDebounce = debounce(this.loadSetList.bind(this), 500)

  public updateSelected (set: SetModel) {
    if (this.isMultiple) {
      this.selected.push(set)
    } else {
      this.selected = [set]
    }
  }
}
