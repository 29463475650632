// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { Identifier } from '@/shared/contracts/data'
import { PickerPayload } from '@module/content/contracts'
import { RelatedType } from '@/shared/modules'

import { AbstractSetPickerModal } from './AbstractSetPickerModal'
import { SetModel, SetType } from '../../contracts'

@Component({
  name: 'BlogPostPickerModal',
  extends: AbstractSetPickerModal
})
export class BlogPostPickerModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<SetModel, RelatedType.BlogPostsFeed, Identifier>

  public setType = SetType.BlogPosts
}

export default BlogPostPickerModal
