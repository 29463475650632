// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { NavigationItem, NavigationItemProps } from '@component/Navigation'
import { computed, PropType, SetupContext } from '@vue/composition-api'
import { RawLocation } from 'vue-router'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const navigationItemProps: ComponentObjectPropsOptions<NavigationItemProps> = {
  item: {
    type: Object as PropType<NavigationItem>,
    required: true
  },
  isOpened: {
    type: Boolean,
    required: false,
    default: false
  },
  allOpen: {
    type: Boolean,
    required: false,
    default: false
  }
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const useNavigationItem = (props: NavigationItemProps, ctx: SetupContext) => {
  const isActive = (itemRoute?: RawLocation): boolean => {
    let resolvedPath: string

    if (!itemRoute) {
      return false
    }

    if (typeof itemRoute === 'string') {
      resolvedPath = itemRoute
    } else {
      resolvedPath = ctx.root.$router.resolve(itemRoute).href
    }

    return (resolvedPath === ctx.root.$route.path)
  }

  const isSelected = computed(() => {
    if (isActive(props.item.route)) {
      return true
    }

    if (props.item.children) {
      for (const child of props.item.children) {
        if ((isActive(child.route) && child.route !== '/')) {
          return true
        }
      }
    }

    return false
  })

  return { isSelected }
}
