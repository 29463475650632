













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { RelatedType } from '@/shared/modules'

import { Identifier } from '@/shared/contracts/data'

import { Item } from '@component/TreeViewer'

import { FormMode } from '../contracts'
import { NodeSelect } from './NodeSelect.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'ContentPickerPage',
  components: {
    NodeSelect
  }
})
export class ContentPickerPage extends Vue {
  @Prop({ type: Number, required: false })
  public selectedId?: Identifier

  @Prop({ type: Function, required: true })
  public onSelect!: (selected: Identifier, related: RelatedType | null) => void

  public formMode: FormMode = FormMode.Picker
  public nodes: Item[] = []

  public async selectNodes (selectedItemsList: Item[]): Promise<void> {
    this.nodes = selectedItemsList

    const node = selectedItemsList.slice(-1).pop()

    if (node) {
      this.onSelect(Number(node.value), RelatedType.Link)
    }
  }

  @Watch('selectedId')
  private updateSelected (selectedId: Identifier) {
    if (selectedId) {
      this.nodes = [{
        value: selectedId,
        label: ''
      }]
    }
  }
}

export default ContentPickerPage
