// Copyright © 2021 Move Closer

import { CanDetectStatus } from '@module/content/services/variantStatus'
import { AnyModule, ContainerContent } from '@modules'
import { ICollection, IResponse } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import {
  AcceptanceData,
  AcceptanceModel,
  ContentData,
  ContentInfoData,
  ContentModel,
  ContentType,
  NodeModel,
  RegistryPayload,
  SimpleContentModel,
  VariantData,
  VariantModel
} from './models'

import {
  ContentCreatePayload,
  IContentOptionsPayload,
  IPublishContentPayload, ValidationType,
  VariantUpdatePayload
} from '../contracts/data'
import { IEditContentIntention } from './intentions'
import { SaveVariantMode } from './components'

export const AcceptanceRepositoryType = Symbol.for('IAcceptanceRepository')

export const ContentRepositoryType = Symbol.for('IContentRepository')

export interface IAcceptanceRepository {
  accept (id: AcceptanceData['id']): Promise<void>
  load (id: AcceptanceData['id']): Promise<AcceptanceModel>
  loadCollection (query: Query): Promise<ICollection<AcceptanceModel>>
  reject (id: AcceptanceData['id'], message: string): Promise<void>
  return (id: AcceptanceData['id'], message: string): Promise<void>
}

export interface IContentRepository {
  create (payload: ContentCreatePayload): Promise<Identifier>
  changeOrder (parent: ContentData['id'], children: Identifier[]): Promise<ContentModel>
  delete (id: ContentData['id']): Promise<void>
  load (id: ContentData['id']): Promise<ContentModel>
  loadCollection (types: ContentType|ContentType[], query: Query): Promise<ICollection<ContentModel>>
  loadContentInfo (): Promise<ContentInfoData>
  loadNode (types: ContentType|ContentType[], id: ContentData['id'], query: Query): Promise<ContentModel>
  loadTree (query: Query): Promise<ICollection<SimpleContentModel>>
  options (id: ContentData['id'], payload: IContentOptionsPayload): Promise<void>
  publish (id: ContentData['id'], payload: IPublishContentPayload): Promise<void>
  registry (slug: string): Promise<RegistryPayload>
  update (id: ContentData['id'], payload: IEditContentIntention): Promise<IResponse>
}

export interface INodesRepository {
  loadTree (query: Query): Promise<ICollection<NodeModel>>
}

export interface IVariantsRepository extends CanDetectStatus {
  create (content: ContentData['id']): Promise<VariantModel>
  clone (content: ContentData['id'], from: VariantData['id']): Promise<VariantModel>
  delete (content: ContentData['id'], id: VariantData['id']): Promise<void>
  load (content: ContentData['id'], id: VariantData['id']): Promise<VariantModel>
  lock (content: ContentData['id'], id: VariantData['id']): Promise<void>
  preview (content: ContentData['id'], id: VariantData['id']): Promise<string>
  unpublish (content: ContentData['id'], id: VariantData['id']): Promise<void>
  update (
    content: ContentData['id'],
    id: VariantData['id'],
    payload: VariantUpdatePayload,
    postSave?: SaveVariantMode
  ): Promise<void>
  updateAndPreview (content: ContentData['id'], id: VariantData['id'], payload: VariantUpdatePayload): Promise<string>
  validate (payload: AnyModule | ContainerContent, type: ValidationType): Promise<any>
}

export const NodesRepositoryType = Symbol.for('INodesRepository')
export const VariantsRepositoryType = Symbol.for('IVariantsRepository')
