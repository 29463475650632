// Copyright © 2022 Move Closer

import { AuthMiddlewareType, CSRFMiddlewareType, ValidationMiddlewareType } from '@movecloser/front-core'

import { LoginMiddlewareType } from '@module/auth/services/login.middleware'
import { SiteMiddlewareType } from '@module/root/services/site-resolver/middleware'
import { ToastMiddlewareType } from '@service/toaster'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const middlewares: symbol[] = [
  // Here you can register global middleware that will be use
  // each API request.
  SiteMiddlewareType,
  AuthMiddlewareType,
  CSRFMiddlewareType,
  LoginMiddlewareType,
  ToastMiddlewareType,
  ValidationMiddlewareType
]
