// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'
import { UserModel } from '@module/auth/contracts/models'

export const canEditUsers = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditUsers)
}

export const canViewUsers = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewUsers)
}

/**
 * Roles
 */
export const canCreateRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateRoles)
}

export const canEditRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditRoles)
}

export const canDeleteRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteRoles)
}

export const canViewRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewRoles)
}
