// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'
import { UserModel } from '@module/auth/contracts/models'

export const canViewNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewNavigation)
}

export const canCreateNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateNavigation)
}

export const canEditNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditNavigation)
}

export const canDeleteNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteNavigation)
}

export const canViewLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewLayout)
}

export const canCreateLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateLayout)
}

export const canEditLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditLayout)
}

export const canDeleteLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteLayout)
}

export const canDeleteSettings = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteSettings)
}

export const canViewSettings = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewSettings)
}

export const canCreateSettings = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateSettings)
}

export const canEditSettings = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditSettings)
}
