// Copyright © 2021 Move Closer

import { AbstractIntention, ConjunctionOperator } from '@movecloser/front-core'
import { GalleryType } from '@module/media/contracts'
import { SetStrategy } from '@module/sets/contracts'

export interface CreateGalleryIntentionPayload {
  title: string
  description: string
}

export class CreateGalleryIntention extends AbstractIntention<CreateGalleryIntentionPayload> {
  protected map = {
    title: 'title',
    description: 'description',
    type: 'type',
    strategy: 'strategy',
    properties: 'properties'
  }
}

export const EmptyGalleryCreatePayload: CreateGalleryIntentionPayload = {
  title: '',
  description: ''
}

export const GalleryConstantPayload = {
  properties: {
    src: [],
    conjunction: ConjunctionOperator.And
  },
  strategy: SetStrategy.Manual,
  type: GalleryType.Gallery
}
