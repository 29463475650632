// Copyright © 2021 Move Closer

import { Roles } from '@module/users/contracts/models'
import { Identifier } from '@modules'
import { Permission } from '@module/auth/contracts/permissions'

export interface PermissionsListItemProps {
  checked: boolean
  label: string
  description: string
  value: Permission
}

export enum RoleFormMode {
  Create = 'create',
  Edit = 'edit'
}

export interface EditUsersRolesPayload {
  userId: Identifier
  onSave: (newRoles: Roles) => void
}
