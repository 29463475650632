// Copyright © 2021 Move Closer

import { ICollection } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import { NavigationData, NavigationModel, SettingData, SettingOptions } from './models'

export interface INavigationRepository {
  create (payload: Omit<NavigationData, 'id'>): Promise<any>
  delete (navigationId: Identifier): Promise<void>
  get (navigationId: Identifier): Promise<NavigationModel>
  list (): Promise<ICollection<NavigationModel>>
  update (navigationId: Identifier, payload: Omit<NavigationData, 'id'>): Promise<void>
}

export interface ISettingsRepository {
  loadCollection (query?: Query): Promise<ICollection<SettingData>>
  getSetting<T> (type: string): Promise<T>
  updateSetting<T> (type: string, payload: T, additionalOptions?: SettingOptions): Promise<void>
}

export const NavigationRepositoryType = Symbol.for('INavigationRepository')

export const SettingRepositoryType = Symbol.for('ISettingsRepository')
