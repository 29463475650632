// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

export const resources: ResourcesRegistry = {
  dict: {
    connection: 'bnp',
    prefix: 'api/management',
    methods: {
      layouts: {
        url: 'layouts',
        method: Methods.Get,
        auth: true
      },
      departments: {
        url: 'departments',
        method: Methods.Get,
        auth: true
      },
      postCategory: {
        url: 'post-category',
        method: Methods.Get,
        auth: true
      },
      users: {
        url: 'users',
        method: Methods.Get,
        auth: true
      },
      user: {
        url: 'users/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      roles: {
        url: 'roles',
        method: Methods.Get,
        auth: true
      }
    }
  },
  sites: {
    connection: 'bnp',
    prefix: 'api/management',
    methods: {
      list: {
        url: 'sites',
        method: Methods.Get,
        auth: true
      }
    }
  }
}
