// Copyright © 2021 Move Closer

import { DashmixIconName } from '@movecloser/ui-core'

import { PageBuilderOperationMode, PageBuilderOperationModeIconRegistry } from './PageBuilder.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const pageBuilderOperationModeIconRegistry: PageBuilderOperationModeIconRegistry = {
  [PageBuilderOperationMode.Builder]: DashmixIconName.Edit,
  [PageBuilderOperationMode.Viewer]: DashmixIconName.Eye
}
