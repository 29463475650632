


import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '@modules'

import { CreateRoleIntention, CreateRolePayload } from '../intentions/createRole'
import { IRolesRepository, RolesRepositoryType } from '../contracts/repositories'
import { Permission, splitPermission } from '../../auth/contracts/permissions'
import { PermissionsListItemProps, RoleFormMode } from '../contracts/components'
import { UpdateRoleIntention, UpdateRolePayload } from '../intentions/updateRole'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component
export class AbstractEditRole extends Vue {
  @Inject(RolesRepositoryType)
  private rolesRepository!: IRolesRepository

  protected initPermissions = Object.values(Permission).map(this.toListItem([]))
  protected intention: CreateRolePayload | UpdateRolePayload = {
    name: '',
    permissions: this.initPermissions
  }

  protected isLoading: boolean = false
  protected isSending: boolean = false
  protected mode: RoleFormMode = RoleFormMode.Create
  protected name: string = ''
  protected title: string = ''

  protected async loadRole () {
    this.isLoading = true
    const { name, permissions } = await this.rolesRepository.load(parseInt(this.$route.params.id, 10))
    this.intention.name = name
    this.name = name
    this.intention.permissions = Object.values(Permission).map(this.toListItem(permissions))
    this.title = String(this.$t('roles.form.edit.title', { name: this.name }))
    this.isLoading = false
  }

  protected toListItem (checked: Permission[]) {
    return (permission: Permission): PermissionsListItemProps => {
      const { resource, action } = splitPermission(permission)

      return {
        checked: checked.includes(permission),
        label: String(this.$t(`permissions.action.label.${action}`) + ' ' + this.$t(`permissions.resource.label.${resource}`)),
        description: String(this.$t('permissions.template', {
          actionDescription: this.$t(`permissions.action.description.${action}`),
          resourceDescription: this.$t(`permissions.resource.description.${resource}`)
        })),
        value: permission
      }
    }
  }

  protected async submit () {
    this.isSending = true

    try {
      if (this.mode === RoleFormMode.Create) {
        const roleId = await this.rolesRepository.create(new CreateRoleIntention(this.intention))
        this.$router.push({ name: 'users.roles.list' })
      } else {
        await this.rolesRepository.update(
          parseInt(this.$route.params.id, 10),
          new UpdateRoleIntention(this.intention)
        )
        this.$router.push({ name: 'users.roles.list' })
      }
    } catch {} finally {
      this.isSending = false
    }
  }
}
export default AbstractEditRole
