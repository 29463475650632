





























import { ConjunctionOperator } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { SetSource, SetType } from '../contracts'
import SetSourceForm from './SetSourceForm.vue'

@Component({
  name: 'SourcesForm',
  components: { SetSourceForm }
})
export class SourcesForm extends Vue {
  @PropSync('model', { type: Array, required: true })
  public sources!: SetSource[]

  @PropSync('conj', { type: String, required: true })
  public conjunction!: ConjunctionOperator

  @Prop({ type: String, required: true })
  public form!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public editable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isLoading!: boolean

  @Prop({ type: String, required: true })
  public setType!: SetType

  public buttonTheme = DashmixTheme
  public Icons = DashmixIconName

  public conjunctionOptions = [
    {
      label: this.$t('sets.create.form.conjunction.type.and'),
      value: ConjunctionOperator.And
    },
    {
      label: this.$t('sets.create.form.conjunction.type.or'),
      value: ConjunctionOperator.Or
    }
  ]

  public get initSource () {
    return {
      type: '',
      value: ''
    }
  }

  public addSource () {
    this.sources.push({ ...this.initSource })
  }

  public onSourceChange (source: SetSource, index: number) {
    const newSources = this.sources
    newSources[index] = source
    this.sources = newSources
  }

  public onSourceDeleted (index: number) {
    const newSources = this.sources
    newSources.splice(index, 1)
    this.sources = newSources
  }
}

export default SourcesForm
