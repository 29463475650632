// Copyright © 2021 Move Closer

import uiCore from '@movecloser/ui-core/lib/libraries/dashmix/locales/pl.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { merge } from 'lodash'

import atomsPL from '@/assets/langs/pl/atoms.json'
import commonPl from '@/assets/langs/pl/common.json'
import moduleForms from '@/shared/modules/src/locales/forms/pl.json'
import modules from '@/shared/modules/src/locales/modules/pl.json'
import modulesUi from '@/shared/modules/src/locales/messages/pl.json'

import contentPl from '@/modules/content/config/content.json'
import mediaPL from '@/modules/media/config/media.json'
import settingsPL from '@/modules/settings/config/settings.json'
import setsPL from '@/modules/sets/config/sets.json'
import usersPl from '@/modules/users/config/users.json'

Vue.use(VueI18n)

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  silentTranslationWarn: true,
  messages: {
    pl: {
      ...merge(
        uiCore,
        modulesUi, modules, moduleForms,
        atomsPL, commonPl,
        mediaPL, setsPL, usersPl, contentPl, settingsPL
      )
    }
  }
})
