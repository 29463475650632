// Copyright © 2021 Move Closer

import { Component, Vue, Prop } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '@modules'

import { ConfirmModalContent, ConfirmModalPayload } from './ConfirmModal.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'ConfirmModal',
  template: `
    <DsModalInner :noControls="payload.noControls" @close="onClose">
      <template v-slot:header>
        <h5 class="modal-title">
          {{ $t(content.header) }}
        </h5>
      </template>

      <p class="mb-1">
        {{ $t(content.contentText) }}
        <strong v-if="content.contentTitle" class="ml-1">{{ content.contentTitle }}</strong>?
      </p>

      <template v-slot:footer>
        <DsButton v-if="!payload.noControls" :label="$t('atoms.cancel')" @click="onClose" :theme="themes.Light" />
        <DsButton :theme="content.theme ? content.theme : 'info'" :label="$t(content.buttonLabel)"
                   @click="payload.onConfirm" />
      </template>
    </DsModalInner>
  `
})
export class ConfirmModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: ConfirmModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public content: ConfirmModalContent = this.payload.content
  public readonly icons = DashmixIconName
  public readonly themes = DashmixTheme

  public onClose (): void {
    if (typeof this.payload.onClose === 'function') {
      this.payload.onClose()
      return
    }
    this.modalConnector.close()
  }
}
