// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '@module/auth/contracts/permissions'

import CreateGallery from '../views/CreateGallery.vue'
import EditGallery from '../views/EditGallery.vue'
import GalleryList from '../views/GalleryList.vue'
import MediaList from '../views/MediaList.vue'
import MediaWaitingList from '../views/MediaWaitingList.vue'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/media/list/:id',
      name: 'showDirectory',
      component: MediaList,
      meta: {
        permissions: [
          Permission.ViewMedia
        ]
      }
    },
    {
      path: '/media/list',
      name: 'list',
      component: MediaList,
      meta: {
        permissions: [
          Permission.ViewMedia
        ]
      }
    },
    {
      path: '/media/waiting-list',
      name: 'waiting-list',
      component: MediaWaitingList,
      meta: {
        permissions: [
          Permission.ViewMedia
        ]
      }
    },
    {
      path: '/media/gallery/:id/edit',
      name: 'gallery.edit',
      component: EditGallery,
      meta: {
        permissions: [
          Permission.EditMedia
        ]
      }
    },
    {
      path: '/media/gallery/create',
      name: 'gallery.create',
      component: CreateGallery,
      meta: {
        permissions: [
          Permission.CreateGallery
        ]
      }
    },
    {
      path: '/media/gallery',
      name: 'gallery.list',
      component: GalleryList,
      meta: {
        permissions: [
          Permission.ViewGallery
        ]
      }
    }
  ]
}
