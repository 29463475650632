// Copyright © 2022 Move Closer

import { AppConfig, RouterDriver, StoreDriver } from '@movecloser/front-core'

import { auth } from './auth'
import { builder } from './builder'
import { csrf } from './csrf'
import { http } from './http'
import { middlewares } from './middlewares'
import { modalConfig, modals } from './modals'
import { modules } from './modules'
import { defaultRoutesConfig, navigation } from './navigation'
import { resources } from './resources'
import { services } from './services'
import { toasts } from './toasts'
import { uploader } from './uploader'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const config: AppConfig = {
  // This is global config index. Use it to register any properties
  // that should be available inside application.
  // To access any of them use: Configuration
  auth,
  builder,
  csrf,
  defaultRoutesConfig,
  http,
  middlewares,
  modalConfig,
  modals,
  modules,
  navigation,
  resources,
  router: RouterDriver.VueRouter,
  services,
  store: StoreDriver.None,
  toasts,
  uploader
}
