













































































































import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, SizeMap } from '@movecloser/ui-core'

import { FormInput, FormSelect } from '@component/form'

// FIXME: `iconsRegistry` being imported in the `<IconSelect>` causes circular dependency.
import { IconSelect } from '@/shared/modules/src/components/atoms/IconSelect'

import { Inject, Picker, Related, RelatedType } from '@modules'
import { Identifier } from '@/shared/contracts/data'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { ContentRepositoryType, ContentType, IContentRepository } from '@module/content/contracts'

import { NavigationItem, OpenStrategy, Target } from '../contracts'

@Component({
  name: 'NavigationItemEdit',
  components: { FormInput, IconSelect, FormSelect }
})
export class NavigationItemEdit extends Vue {
  @Prop({ type: Object, required: true })
  public item!: NavigationItem

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public Icons = DashmixIconName
  public Target = Target

  public form = 'editNavigationItem'
  public isLoading: boolean = false
  public label: string = ''
  public icon: string = ''
  public link: string | null = null
  public linkName: string | null = null
  public strategy: OpenStrategy = OpenStrategy.Self
  public type: Target = Target.Internal
  public withIcon: boolean = false

  public iconOptions = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'youtube', label: 'YouTube' },
    { value: 'instagram', label: 'Instagram' }
  ]

  public get isBlank () {
    return this.strategy === OpenStrategy.Blank
  }

  public set isBlank (value: boolean) {
    this.strategy = value ? OpenStrategy.Blank : OpenStrategy.Self
  }

  public get isInternal () {
    return this.type === Target.Internal
  }

  public set isInternal (value: boolean) {
    this.type = value ? Target.Internal : Target.External
  }

  public get isValid () {
    return !!this.link && !!this.label
  }

  created () {
    this.init(this.item)
  }

  public cancel () {
    this.$emit('cancel')
  }

  public async selectContent () {
    this.modalConnector.open(Picker.Link, {
      config: { },
      onClose: () => this.modalConnector.close(),
      onSelection: async (data: Related<RelatedType.Link, Identifier>) => {
        this.link = `${data.value}`

        const page: AnyObject = await this.relatedService.describe({ type: RelatedType.Link, value: data.value })

        this.linkName = page.title
      },
      selected: this.link ? { type: RelatedType.Link, value: this.link } : null
    }, { size: SizeMap.Large })
  }

  public update () {
    if (this.link) {
      const item: NavigationItem = {
        strategy: this.strategy,
        target: {
          type: this.type,
          link: this.link
        },
        label: this.label,
        icon: this.withIcon ? this.icon : '',
        children: this.item.children
      }

      this.$emit('update', item)
    }
  }

  @Watch('item')
  private async init (editedItem: NavigationItem) {
    this.label = editedItem?.label ?? ''
    this.strategy = editedItem?.strategy ?? OpenStrategy.Self
    this.type = editedItem?.target?.type ?? Target.Internal
    this.icon = editedItem?.icon ?? ''
    this.withIcon = !!this.icon
    this.link = editedItem?.target?.link ?? null
    this.linkName = editedItem?.target?.type === Target.Internal && editedItem?.target?.link
      ? await this.loadPageTitle(Number(editedItem?.target?.link))
      : null
  }

  private loadPageTitle (id: Identifier): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.contentRepository.loadNode(ContentType.Page, id, {})
        .then(model => resolve(model.title))
        .catch(e => reject(e))
    })
  }
}

export default NavigationItemEdit
