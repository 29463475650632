












import { Component, Prop, Vue } from 'vue-property-decorator'

import { Inject } from '@modules'
import { IRelatedService } from '@service/related'

import { AcceptanceRepositoryType, IAcceptanceRepository, VariantData } from '../contracts'
import { Addon, addonsDrivers } from '../maps/variant'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'AcceptanceAddons'
})
export class AcceptanceAddons extends Vue {
  @Prop({ type: String, required: true })
  public active!: string

  @Prop({ type: Object, required: true })
  public model!: VariantData

  @Prop({ type: Function, required: true })
  protected onActiveChange!: (tab: string) => void

  @Prop({ type: Object, required: true })
  protected readonly relatedService!: IRelatedService

  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  public addonsType = Addon

  public get items () {
    return Object.values(this.addonsType)
      .filter(i => this.model.addons.includes(i))
      .map(i => {
        return {
          label: this.$t(`content.addonsLabels.${i}`),
          id: `${i}`,
          component: addonsDrivers[i],
          props: { disabled: true, variant: this.model, relatedService: this.relatedService }
        }
      })
  }

  mounted () {
    if (this.items.length) {
      this.onActiveChange(this.items[0].id)
    }
  }
}

export default AcceptanceAddons
