




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { FormInput } from '@component/form'
import { Inject } from '@modules'

import { DirectoryEditModalPayload } from '../contracts/components'
import { DirectoryForm } from './DirectoryForm.vue'
import { EmptyDirectoryEditPayload, DirectoryEditPayload, DirectoryParentData } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'DirectoryEditModal',
  components: { FormInput, DirectoryForm }
})
export class DirectoryEditModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: DirectoryEditModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public icons = DashmixIconName
  public isLoading: boolean = false
  public form: DirectoryEditPayload = { ...EmptyDirectoryEditPayload }
  public formName: string = 'updateDirectory'
  public parentDirectory: DirectoryParentData | null = null

  mounted () {
    this.form.name = this.payload.directory.name
    this.parentDirectory = this.payload.directory.parent
  }

  public close (): void {
    this.$emit('close')
  }

  public sendForm (): void {
    this.payload.updateDirectory(
      this.payload.directory.id,
      this.form
    )
  }
}

export default DirectoryEditModal
