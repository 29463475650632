// Copyright © 2021 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.abstract'

import { canCreateLayout, canDeleteLayout, canEditLayout, canViewLayout } from '../guards'

export interface ILayoutsPermissions extends IUserAware {
  canEditLayout: boolean
  canViewLayout: boolean
  canCreateLayout: boolean
  canDeleteLayout: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<LayoutsPermissions>({})
export class LayoutsPermissions extends UserAware implements ILayoutsPermissions {
  /**
   * Layout permissions
   */
  public get canViewLayout () {
    return canViewLayout(this.domain, this.authService.user)
  }

  public get canCreateLayout () {
    return canCreateLayout(this.domain, this.authService.user)
  }

  public get canEditLayout () {
    return canEditLayout(this.domain, this.authService.user)
  }

  public get canDeleteLayout () {
    return canDeleteLayout(this.domain, this.authService.user)
  }
}
