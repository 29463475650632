















import { Component } from 'vue-property-decorator'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import {
  DashmixButtonVariantMap,
  DashmixIconName,
  DashmixTheme
} from '@movecloser/ui-core'

import { ModalSize } from '@component/Modal'
import { Inject } from '@modules'

import { ContentModals } from '../../../content/config/modals'
import { Addon } from '../../maps/variant'
import { AddonErrors } from '../addons/AddonErrors.vue'
import { AbstractAddon } from './AbstractAddon'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<SkipLinksAddon>({ name: 'SkipLinksAddon', components: { AddonErrors } })
export class SkipLinksAddon extends AbstractAddon {
  @Inject(ModalType)
  protected modalConnector!: IModal

  public readonly ButtonTheme = DashmixTheme
  public readonly ButtonVariant = DashmixButtonVariantMap
  public readonly Icons = DashmixIconName

  public get content (): AnyObject {
    return this.variant.getProperty(Addon.SkipLink) ?? {}
  }

  public set content (content: AnyObject) {
    this.variant.setProperty(Addon.SkipLink, content)
    this.onChange(this.variant)
  }

  /**
   * Opens modal
   */
  public openModal (): void {
    this.modalConnector.open(ContentModals.SkipLinks, {
      content: this.content,
      onChange: (content: AnyObject) => { this.content = content },
      onClose: () => { this.modalConnector.close() }
    }, { size: ModalSize.xl })
  }
}
export default SkipLinksAddon
