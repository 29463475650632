// Copyright © 2021 Move Closer

import { Authentication, AuthServiceType, EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '@modules'

import { Domain } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { UserModel } from '../contracts/models'

export interface IUserAware {
  domain: Domain
  user: UserModel | null
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<UserAware>({
  created () {
    this.domain = this.siteResolver.getSite()?.domain || Domain.PL
    this.user = this.authService.user

    this.eventBus.handle('ui:context.changed', () => {
      this.domain = this.siteResolver.getSite()?.domain || Domain.PL
    })

    this.eventBus.handle('app:authentication', (event: EventPayload<string>) => {
      this.onAuthentication(event)
      this.resolveUser()
    })
  }
})
export class UserAware extends Vue implements IUserAware {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public domain: Domain = Domain.PL
  public user: UserModel | null = null

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onAuthentication (event: EventPayload<string>): void {}

  protected resolveUser (): void {
    this.user = this.authService.user
  }
}
