// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '@module/auth/contracts/permissions'

import EditSettings from '../views/EditSettings.vue'
import Navigation from '../views/Navigation.vue'
import NavigationEdit from '../views/NavigationEdit.vue'
import SettingList from '../views/SettingList.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/settings/navigation/create',
      name: 'navigation.create',
      component: NavigationEdit,
      meta: {
        permissions: [
          Permission.CreateNavigation
        ]
      }
    },
    {
      path: '/settings/navigation/:id/edit',
      name: 'navigation.edit',
      component: NavigationEdit,
      meta: {
        permissions: [
          Permission.EditNavigation
        ]
      }
    },
    {
      path: '/settings/navigation',
      name: 'navigation',
      component: Navigation,
      meta: {
        permissions: [
          Permission.ViewNavigation
        ]
      }
    },
    {
      path: '/settings/:type',
      name: 'config.edit',
      component: EditSettings,
      meta: {
        permissions: [
          Permission.EditSettings
        ]
      }
    },
    {
      path: '/settings',
      name: 'config.list',
      component: SettingList,
      meta: {
        permissions: [
          Permission.ViewSettings
        ]
      }
    }
  ]
}
