













import { AnyObject } from '@movecloser/front-core'
import { DashmixIconName, DashmixBreadcrumbsProps } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'

import { EditModeLayout } from '@component/EditModeLayout'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { Inject } from '@modules'

import { DepartmentsSettingsForm } from '../components/DepartmentsSettingsForm.vue'
import { GeneralSettingsForm } from '../components/GeneralSettingsForm.vue'
import { ISettingsRepository, SettingOptions, SettingRepositoryType } from '../contracts'

@Component({
  name: 'EditSetting',
  components: {
    EditModeLayout
  }
})
export class EditSetting extends Vue {
  @Inject(SettingRepositoryType)
  protected settingsRepository!: ISettingsRepository

  public readonly icons = DashmixIconName

  public loading = true
  public isSaving = false
  public payload: AnyObject | AnyObject[] = {}

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.all.title')}`,
          target: { name: 'settings.config.all' }
        },
        {
          label: `${this.$t(`settings.${this.type}`)}`,
          target: { name: `settings.config.${this.type}` }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get formComponent () {
    switch (this.type) {
      case 'departments':
        return DepartmentsSettingsForm
      case 'general':
        return GeneralSettingsForm
      default:
        return null
    }
  }

  public get type () {
    if (this.$route.params.type) {
      return this.$route.params.type
    }
    if (this.$route.meta && this.$route.meta.type) {
      return this.$route.meta.type
    }
    return null
  }

  mounted () {
    this.loading = true

    // if (this.formComponent !== null) {
    //   this.payload = this.formComponent.defaultPayload()
    // }

    this.settingsRepository.getSetting<AnyObject>(this.type).then(data => {
      if (Array.isArray(this.payload)) {
        this.payload = [...this.payload, ...(Array.isArray(data) ? data : Object.values(data))]
      } else {
        this.payload = {
          ...this.payload,
          ...data
        }
      }
    }).catch(e => console.warn(e))
      .finally(() => {
        this.loading = false
      })
  }

  public onSubmit (additionalSettings?: SettingOptions) {
    this.isSaving = true

    this.settingsRepository.updateSetting<AnyObject>(this.type, this.payload, additionalSettings)
      .catch(e => console.warn(e))
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default EditSetting
