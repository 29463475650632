// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '@module/auth/contracts/permissions'

import Acceptance from '../views/Acceptance.vue'
import AcceptanceList from '../views/AcceptanceList.vue'
import CreatePage from '../views/CreatePage.vue'
import EditPageContent from '../views/EditPageContent.vue'
import EditPageBasics from '../views/EditPageBasics.vue'
import PagesList from '../views/PagesList.vue'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/content/acceptance/:contentType/:id',
      name: 'acceptance.show',
      component: Acceptance,
      meta: {
        permissions: [
          Permission.AcceptContents
        ]
      }
    },
    {
      path: '/content/acceptance/:contentType',
      name: 'acceptance.list',
      component: AcceptanceList,
      meta: {
        permissions: [
          Permission.AcceptContents
        ]
      }
    },
    {
      path: '/content/pages/:id/basics',
      name: 'pages.basics',
      component: EditPageBasics,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/pages/:id/content',
      name: 'pages.content',
      component: EditPageContent,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/pages/create',
      name: 'pages.create',
      component: CreatePage,
      meta: {
        permissions: [
          Permission.CreateContents
        ]
      }
    },
    {
      path: '/content/pages',
      name: 'pages.list',
      component: PagesList,
      meta: {
        permissions: [
          Permission.ViewContents
        ]
      }
    },
    {
      path: '/content',
      redirect: '/content/articles'
    }
  ]
}
