import { render, staticRenderFns } from "./EditUsersRolesModal.vue?vue&type=template&id=67aad079&scoped=true&"
import script from "./EditUsersRolesModal.vue?vue&type=script&lang=ts&"
export * from "./EditUsersRolesModal.vue?vue&type=script&lang=ts&"
import style0 from "./EditUsersRolesModal.vue?vue&type=style&index=0&id=67aad079&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67aad079",
  null
  
)

export default component.exports