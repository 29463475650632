// Copyright © 2021 Move Closer

import { DashmixIconName } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { CheckTile } from '@component/CheckTile'

import { selectModuleModalProps, useSelectionModuleModal } from './SelectModuleModal.hooks'
import { SelectModuleModalProps } from '../PageBuilder.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const SelectModuleModal = defineComponent({
  name: 'SelectModuleModal',
  components: { CheckTile },
  props: selectModuleModalProps,

  setup (props: SelectModuleModalProps, ctx: SetupContext) {
    return { ...useSelectionModuleModal(props, ctx), iconsSet: DashmixIconName }
  },

  template: `
    <DsModalInner class="SelectModuleModal" @close="$emit('close')">
      <template v-slot:header>
        <h5 class="modal-title" v-t="'builder.addModuleTitle'" />
      </template>

      <DsInput name="search" :model.sync="query" :placeholder="$t('builder.findModule')" />

      <div class="row no-gutters mt-3">
        <div v-for="(m, i) in modules" :key="'m-' + m.value" class="col-3 d-flex">
          <CheckTile class="m-1 flex-fill" :id="'module-to-pick-' + i" name="module"
                     :selected.sync="chosenModule" :value="m.value">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="m.image" :title="m.label" :alt="m.label" />
              <span>{{ m.label }}</span>
            </div>
          </CheckTile>
        </div>
        <div v-if="!modules.length" class="col-12 py-5">
          <h5 class="text-center text-black-50" v-t="'builder.noModulesToDisplay'" />
        </div>
      </div>

      <template v-slot:footer>
        <DsButton :icon="iconsSet.PlusSolid" :label="$t('builder.addModule')"
                   :disabled="!isSelected" @click="addSelected" />
      </template>
    </DsModalInner>
  `
})
