// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'

export const canUploadMedia = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.UploadMedia)
}

export const canDeleteMedia = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteMedia)
}

export const canEditMedia = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditMedia)
}

export const canViewMedia = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewMedia)
}

export const canEditGallery = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditGallery)
}

export const canCreatGallery = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateGallery)
}

export const canDeleteGallery = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteGallery)
}

export const canViewGallery = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewGallery)
}
