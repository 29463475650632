// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const jobOfferAdapterMap: MappingConfig = {
  cities: 'cities',
  departments: 'departments',
  title: 'title',
  externalNumber: 'externalNumber',
  url: 'url'
}
