























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '@modules'
import { DashmixIconName } from '@movecloser/ui-core'

import { Identifier } from '@/shared/contracts/data'
import { Item } from '@component/TreeViewer'

import { ContentRepositoryType, ContentStatus, ContentType, FormMode, IContentRepository, INodesRepository, NodeModel, NodesRepositoryType } from '@module/content/contracts'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { NodeSelect } from '@module/content/components/NodeSelect.vue'
import { mapParentToNode } from '@module/content/helpers'

import { NavigationItem, OpenStrategy, Target } from '../contracts'
import { NavigationList } from './NavigationList.vue'

@Component({
  name: 'NavigationAutoForm',
  components: {
    NavigationList,
    NodeSelect
  }
})
export class NavigationAutoForm extends Vue {
  @Prop({ type: Array, required: true })
  public links!: NavigationItem[]

  @Prop({ type: Number, required: false, default: null })
  public from!: Identifier | null

  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(NodesRepositoryType)
  protected nodesRepository!: INodesRepository

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  public Icons = DashmixIconName

  public formMode: FormMode = FormMode.Picker
  public isEdit: boolean = false
  public isLoading: boolean = false
  public linkName: string | null = null
  public linksList: NavigationItem[] = this.links
  public nodes: Item[] = []
  private parentId: Identifier | null = this.from
  private parentName: string | null = null

  mounted () {
    this.nodes = mapParentToNode(null)
    this.init()
  }

  public cancel () {
    this.isEdit = false
  }

  public edit () {
    this.isEdit = true
  }

  public isValid () {
    return !!this.parentId
  }

  public async selectNodes (selectedItemsList: Item[]): Promise<void> {
    this.nodes = selectedItemsList

    const node = selectedItemsList.slice(-1).pop()

    if (node) {
      this.parentId = Number(node.value)
      this.parentName = await this.loadPageTitle(Number(node.value))
    }
  }

  public update () {
    if (this.parentId) {
      this.loadList(this.parentId as Identifier)

      this.isEdit = false
    }
  }

  private async init () {
    this.linkName = this.from ? await this.loadPageTitle(this.from) : null
    this.parentName = this.linkName
  }

  private loadPageTitle (id: Identifier): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.contentRepository.loadNode(ContentType.Page, id, {})
        .then(model => resolve(model.title))
        .catch(e => reject(e))
    })
  }

  private nodeToNavigation (node: NodeModel) {
    const item: NavigationItem = {
      hideInNav: node.hideInNav,
      label: node.name,
      strategy: OpenStrategy.Self,
      target: {
        link: `${node.id}`,
        type: Target.Internal
      },
      children: node.nodes ? node.nodes.map(child => this.nodeToNavigation(child)) : []
    }

    return item
  }

  private loadList (parentId: Identifier): void {
    this.isLoading = true

    if (!parentId) {
      const siteModel = this.siteResolver.getSite()

      if (siteModel) {
        parentId = siteModel.rootContent
      }
    }

    this.nodesRepository.loadTree({ parent: `${parentId}`, status: ContentStatus.Published })
      .then(nodes => {
        if (!nodes.length) {
          return
        }

        this.linksList = this.nodeToNavigation(nodes[0]).children.filter(i => !i.hideInNav)
        this.linkName = this.parentName

        this.$emit('update', parentId, this.linksList)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}

export default NavigationAutoForm
