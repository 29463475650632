// Copyright © 2021 Move Closer

import { DashmixIconName, TableSearchBarAction, TableHead } from '@movecloser/ui-core'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canDeleteLayout, canEditLayout } from '../guards'

export const layoutsTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'editor',
    label: 'Utworzony przez',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'createdDate',
    label: 'Data utworzenia',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'modificationDate',
    label: 'Data modyfikacji',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum LayoutsActions {
  Delete = 'delete',
  Edit = 'edit'
}

export const layoutRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditLayout(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: LayoutsActions.Edit
      }
    )
  }

  if (canDeleteLayout(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: LayoutsActions.Delete
      }
    )
  }

  return actions
}
