


















import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { Inject, log } from '@modules'

import { AuthRepositoryType, IAuthRepository } from '../contracts/repositories'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<LoginPage>({
  name: 'LoginPage',
  metaInfo (this: LoginPage): MetaInfo {
    return {
      title: `${this.$t('auth.login')}`
    }
  }
})
export class LoginPage extends Vue {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  public redirecting: boolean = false

  public get isProduction (): boolean {
    return process.env.VUE_APP_ENV === 'production'
  }

  public authenticate (): void {
    this.redirecting = true

    this.authRepository.resolveRedirectionUrl().then(redirectAddress => {
      window.location.href = redirectAddress
    }).catch((e) => {
      log(e)
    }).finally(() => {
      this.redirecting = false
    })
  }
}

export default LoginPage

