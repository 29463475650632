































import { Component } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { AbstractModuleForm } from '../../_abstract'
import { Iterator } from '../../../molecules'

import { SkiplinksModule, SkiplinksModuleContent } from '../Skiplinks.contracts'
import { skiplinksModuleContentFactory } from '../Skiplinks.factory'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component({
  name: 'SkiplinksModuleForm',
  components: { Iterator }
})
export class SkiplinksModuleForm extends AbstractModuleForm<SkiplinksModule> {
  /**
   * @inheritDoc
   */
  public initialContent: SkiplinksModuleContent = skiplinksModuleContentFactory()

  /**
   * New link (anchor) payload.
   */
  public addLink (): AnyObject {
    return {
      containerId: '',
      label: ''
    }
  }
}

export default SkiplinksModuleForm
