// Copyright © 2021 Move Closer

import { Roles, SimpleRole } from '@module/users/contracts/models'
import { Identifier } from '@/shared/contracts/data'

export const mapRolesToIntention = (roles: Roles) => {
  return Object.keys(roles).reduce((result: Record<string, Identifier[]>, k: string) => {
    result[k] = roles[k].map((simpleRole: SimpleRole) => simpleRole.id)

    return result
  }, {} as Record<string, Identifier[]>)
}
