






















import {
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { Domain } from '@module/root/contracts/models'
import { Inject } from '@modules'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { Loader } from '@component/Loader'
import { Query } from '@/shared/contracts/query'

import { AuthRepositoryType, IAuthRepository } from '../contracts/repositories'
import { OAuthPayload } from '../contracts/data'
import { TokenModel, UserModel } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<OauthView>({
  name: 'OauthView',
  components: { Loader },
  metaInfo (this: OauthView): MetaInfo {
    return {
      title: `${this.$t('_.loading')}`
    }
  },
  mounted () {
    this.loginUser()
  }
})
export class OauthView extends Vue {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  public loginFailed: boolean = false
  public errors: string = ''

  public get query (): Query {
    return this.$route.query
  }

  public enterPressed (): void {
    this.loginUser()
  }

  public loginUser (): void {
    this.authRepository.authenticate(this.query as unknown as OAuthPayload).then((model: TokenModel) => {
      this.authService.setUser(model.getUser())
      this.authService.setToken(model.toAuthToken())

      const userPermissions = model.getUser().sitesWithAccess()
      const activeSite = this.siteResolver.getSite()

      if (userPermissions.length === 0) {
        this.$router.push({ name: 'auth.no-access' })
        return
      }

      if (!activeSite || !activeSite.domain || !userPermissions.includes(activeSite.domain)) {
        const newSite = this.siteResolver.findByDomain(userPermissions[0] as Domain)

        if (!newSite) {
          this.authService.deleteToken()
          this.$router.push({ name: 'auth.login' })
          return
        }

        this.siteResolver.setSite(newSite)
        this.eventBus.emit('ui:context.changed')
      }

      this.$router.push({ name: 'root.dashboard' })
    }).catch(e => {
      this.loginFailed = true
      this.errors = e.message
    })
  }

  public onAlertClose () {
    this.$router.push({ name: 'auth.login' })
  }
}

export default OauthView

