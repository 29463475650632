








import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@modules'

import { ISiteResolver, SiteResolverType } from '../services/site-resolver'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'InitView'
})
export class InitView extends Vue {
  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  mounted () {
    const siteId = this.siteResolver.getSiteId()

    if (siteId) {
      this.$router.push({
        name: 'root.dashboard',
        params: { siteId }
      })
    }
  }
}

export default InitView
