



































import { Component } from 'vue-property-decorator'
import { DateTimeType, IDateTime, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName } from '@movecloser/ui-core'

import { Identifier, Inject } from '@modules'
import { Loader } from '@component/Loader'

import { ContentModel, ContentRepositoryType, ContentType, IContentRepository } from '../contracts'
import { ContentModals } from '../config/modals'
import AbstractWidget from '@module/root/shared/AbstractWidget'
import { canEditContents, canViewContents } from '@module/content/config/guards'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<HomePageWidget>({
  name: 'HomePageWidget',
  components: { Loader },

  created (): void {
    this.loadList()
  }
})
export class HomePageWidget extends AbstractWidget {
  @Inject(ContentRepositoryType)
  protected readonly contentRepository!: IContentRepository

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  public data: ContentModel | null = null
  public icon = DashmixIconName
  public isLoading: boolean = false

  public routeToEditPage (): void {
    const siteModel = this.siteResolver.getSite()

    if (!siteModel) {
      return
    }
    const parentId = siteModel.rootContent

    this.$router.push({ name: 'content.pages.content', params: { id: parentId.toString() } })
  }

  public showSets (): void {
    this.modalConnector.open(ContentModals.ContentLists, {
      siteId: this.siteResolver.getSite()?.id,
      slug: '/',
      title: this.$t('dashboard.homePageWidget.label')
    }, {
      closableWithOutsideClick: true
    })
  }

  public canEditContents (): boolean {
    return canEditContents(this.domain, this.user)
  }

  private loadList (): void {
    this.isLoading = true

    let parentId: Identifier = 0
    const siteModel = this.siteResolver.getSite()
    if (!siteModel) {
      return
    }
    parentId = siteModel.rootContent

    this.contentRepository.loadNode([ContentType.Page], parentId, {}).then((collection) => {
      this.isLoading = false
      this.data = collection
    })
  }

  protected checkPermission (domain: Domain, user: UserModel | null): boolean {
    return canViewContents(domain, user)
  }
}

export default HomePageWidget
