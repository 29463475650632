// Copyright © 2021 Move Closer

export enum Permission {
  AcceptContents = 'contents.accept',
  CreateContents = 'contents.create',
  DeleteContents = 'contents.delete',
  EditContents = 'contents.edit',
  PublishContents = 'contents.publish',
  ViewContents = 'contents.view',

  UploadMedia = 'media.upload',
  DeleteMedia = 'media.delete',
  EditMedia = 'media.edit',
  ViewMedia = 'media.view',

  EditUsers = 'users.edit',
  ViewUsers = 'users.view',

  CreateRoles = 'roles.create',
  EditRoles = 'roles.edit',
  DeleteRoles = 'roles.delete',
  ViewRoles = 'roles.view',

  CreateNavigation = 'navigation.create',
  EditNavigation = 'navigation.edit',
  DeleteNavigation = 'navigation.delete',
  ViewNavigation = 'navigation.view',

  CreateLayout = 'layout.create',
  EditLayout = 'layout.edit',
  DeleteLayout = 'layout.delete',
  ViewLayout = 'layout.view',

  EditJobsFeed = 'offers.edit',
  DeleteJobsFeed = 'offers.delete',
  CreateJobsFeed = 'offers.create',
  ViewJobsFeed = 'offers.view',

  EditBlogPostsFeed = 'posts.edit',
  DeleteBlogPostsFeed = 'posts.delete',
  CreateBlogPostsFeed = 'posts.create',
  ViewBlogPostsFeed ='posts.view',

  ViewSocialPostsFeed ='social.view',

  EditSettings = 'setting.edit',
  DeleteSettings = 'setting.delete',
  CreateSettings = 'setting.create',
  ViewSettings ='setting.view',

  EditGallery = 'gallery.edit',
  DeleteGallery = 'gallery.delete',
  CreateGallery= 'gallery.create',
  ViewGallery ='gallery.view'
}

export interface SplitPermission {
  resource: string
  action: string
}

export const splitPermission = (permission: Permission): SplitPermission => {
  const [resource, action] = permission.split('.')
  return { resource, action }
}
