// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { computed, ComputedRef, PropType, SetupContext } from '@vue/composition-api'
import { DashmixBadgeVariant, DashmixBadgeVariantMap, DashmixTheme, Size, Hint } from '@movecloser/ui-core'

import { FormFieldAbstractSetup } from '../form/Abstract'
import { FormTypeaheadProps } from './FormTypeahead.contracts'

export const formTypeaheadProps = {
  badgeMaxLength: {
    type: Number,
    required: false
  },
  badgeTheme: {
    type: String as PropType<DashmixTheme>,
    required: false,
    default: DashmixTheme.Light
  },
  badgeVariant: {
    type: String as PropType<DashmixBadgeVariant>,
    required: false,
    default: DashmixBadgeVariantMap.Default
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  },
  multiple: {
    type: Boolean,
    required: false,
    default: false
  },
  hints: {
    type: Array as PropType<Hint[]>,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  searchable: {
    type: Boolean,
    required: false,
    default: true
  },
  clearable: {
    type: Boolean,
    default: false
  },
  noResults: {
    type: String,
    required: false
  },
  newResult: {
    type: String,
    required: false
  },
  taggable: {
    type: Boolean,
    required: false,
    default: true
  },
  size: {
    type: String as PropType<Size>,
    required: false
  },
  showSelection: {
    type: Boolean,
    required: false,
    default: true
  },
  useDebounce: {
    type: Boolean,
    required: false
  }
}

export const useFormTypeahead = (props: FormTypeaheadProps, ctx: SetupContext) => {
  const { emit } = ctx

  const {
    errors,
    displayLabel,
    hasErrors,
    value
  } = FormFieldAbstractSetup(props, emit)

  const classObject: ComputedRef<AnyObject> = computed(() => {
    return {
      'is-invalid': hasErrors.value
    }
  })

  return {
    classObject,
    errors,
    displayLabel,
    hasErrors,
    value
  }
}
