// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { ComputedRef, Ref } from '@vue/composition-api'
import { DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'
import {
  BuilderConfig,
  Container,
  ContainersRegistry,
  ContentStructure,
  ElementIdentifier,
  Module,
  ModulesRegistry
} from '@movecloser/page-builder'

import { IRelatedService } from '@service/related'
import {
  AnyModule,
  ContainerContent,
  ContainerData,
  ContentType,
  InjectionCallback,
  LayoutBreakpoint,
  ModuleConstructor,
  ModuleFactory,
  ModuleVersion,
  ModuleVersions,
  PickerCallback
} from '@modules'
import { VariantModel } from '@module/content/contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerDrawerProps {
  relatedService: IRelatedService
  source: ContainerContent
  variant: VariantModel
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface FormDrawerProps {
  relatedService: IRelatedService
  source: AnyModule
  variant: VariantModel
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModeSwitcherItem {
  icon: string | symbol
  label: string
  value: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModeSwitcherProps {
  controls: boolean
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModuleComponentsRegistry = Record<string, ModulesRegistryEntry>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModuleFactoriesRegistry = Record<string, ModuleFactory>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModulesRenderRegistry = Record<string, ModuleConstructor>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModulesRegistryEntry {
  image: string
  label: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface PageBuilderConfig {
  componentFactories: ModuleFactoriesRegistry
  containerComponent: ModuleConstructor
  containerForm: ModuleConstructor | null
  formComponents: ModulesRenderRegistry
  moduleRegistry: ModuleComponentsRegistry
  uiComponents: ModulesRenderRegistry
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line
export type PageBuilderEventCallback = (event: any) => void

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum PageBuilderOperationMode {
  Builder = 'builder',
  Viewer = 'viewer'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type PageBuilderOperationModeIconRegistry = Record<PageBuilderOperationMode, DashmixIconName>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface PageBuilderProps {
  columns: number
  containers: ContainersRegistry
  contentType: ContentType
  fromParent: AnyObject
  mode: PageBuilderOperationMode
  modules: ModulesRegistry
  modulesRegistry: string[] | null
  noPreview: boolean
  relatedService: IRelatedService
  structure: ContentStructure
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ResolvedModuleEntry extends ModulesRegistryEntry {
  value: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SelectModuleModalPayload {
  modulesRegistry: string[]
  onSelection: (driver: string | null) => void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SelectModuleModalProps {
  payload: SelectModuleModalPayload
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UseContainerFormProvides extends UseModuleDependenciesProvides {
  applyChanges: () => void
  container: Ref<ContainerContent | null>
  errors: Ref<string[]>
  isOpen: ComputedRef<boolean>
  selectedForm: Ref<ModuleConstructor | string>
  validating: Ref<boolean>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UseFormDrawerProvides extends UseModuleDependenciesProvides {
  applyChanges: () => void
  content: ComputedRef<AnyObject>
  errors: Ref<string[]>
  isOpen: ComputedRef<boolean>
  module: Ref<AnyModule | null>
  selectedForm: Ref<ModuleConstructor | string | null>
  version: ComputedRef<ModuleVersion | undefined>
  validating: Ref<boolean>
  versionsRegistry: ModuleVersions<string>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UseModuleDependenciesProvides {
  pickRelated: PickerCallback
  resolveInjection: InjectionCallback
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UsePageBuilderProvides {
  _containers: ComputedRef<ContainersRegistry>
  _modules: ComputedRef<ModulesRegistry>
  _structure: ComputedRef<ContentStructure>
  breakpoint: Ref<LayoutBreakpoint>
  breakpointOptions: DashmixSelectItem[]
  changeBreakpoint: () => void
  config: ComputedRef<Partial<BuilderConfig>>
  containerComponent: ComputedRef<ModuleConstructor | null>
  containerToEdit: Ref<ElementIdentifier | null>
  editContainer: (container: ContainerData) => void
  editModule: (module: AnyModule) => void
  elementProps: ComputedRef<unknown>
  isBuilderActive: ComputedRef<boolean>
  isMobile: Ref<boolean>
  isSticky: Ref<boolean>
  listeners: Record<string, PageBuilderEventCallback>
  moduleToEdit: Ref<ElementIdentifier | null>
  replicateContainer: (toReplicate: Container) => Container
  replicateModules: (toReplicate: Module[]) => Module[]
  showControls: Ref<boolean>
}
