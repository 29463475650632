// Copyright © 2021 Move Closer

import {
  BuilderConfig,
  Container,
  ContainerAddEvent,
  ContainerDefinition,
  ContainerEvent,
  ContainersRegistry,
  ContentStructure,
  ElementIdentifier,
  Module,
  ModuleDefinition,
  ModuleEvent,
  ModulesRegistry
} from '@movecloser/page-builder'
import { ComponentObjectPropsOptions, DashmixTheme, SizeMap } from '@movecloser/ui-core'
import {
  computed,
  ComputedGetter,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  SetupContext,
  watch
} from '@vue/composition-api'
import { IModal, ModalType } from '@movecloser/front-core'
import { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'

import {
  AnyModule,
  ContainerData,
  ContentType,
  LayoutBreakpoint,
  ModuleConstructor,
  ModuleFactory,
  resolve
} from '@modules'
import { IRelatedService } from '@service/related'
import { Modals } from '@/config/modals'

import { Container as ContainerComponent } from './partials'
import {
  ModuleFactoriesRegistry,
  PageBuilderOperationMode,
  PageBuilderProps,
  UsePageBuilderProvides
} from './PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const pageBuilderProps: ComponentObjectPropsOptions<PageBuilderProps> = {
  columns: {
    type: Number,
    required: false,
    default: 12
  },
  containers: {
    type: Object as PropType<ContainersRegistry>,
    required: true
  },
  contentType: {
    type: String as PropType<ContentType>,
    required: true
  },
  fromParent: {
    type: Object,
    required: false,
    default: () => ({})
  },
  mode: {
    type: String as PropType<PageBuilderOperationMode>,
    required: false,
    default: () => PageBuilderOperationMode.Builder
  },
  modules: {
    type: Object as PropType<ModulesRegistry>,
    required: true
  },
  modulesRegistry: {
    type: Array as PropType<string[]>,
    required: false,
    default: null
  },
  noPreview: {
    type: Boolean,
    required: false,
    default: false
  },
  relatedService: {
    type: Object as PropType<IRelatedService>,
    required: true
  },
  structure: {
    type: Object as PropType<ContentStructure>,
    required: true
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function usePageBuilder (props: PageBuilderProps, ctx: SetupContext): UsePageBuilderProvides {
  const breakpoint = ref<LayoutBreakpoint>(LayoutBreakpoint.LG)
  const builderWrapperOffset = ref<number>(0)
  const containerToEdit = ref<ElementIdentifier | null>(null)
  const isSticky = ref<boolean>(false)
  const modalConnector: IModal = resolve(ModalType)
  const moduleToEdit = ref<ElementIdentifier | null>(null)
  const showControls = ref<boolean>(true)

  const _containers = computed<ContainersRegistry>({
    get () {
      return { ...props.containers }
    },
    set (elements: ContainersRegistry) {
      ctx.emit('update:containers', elements)
    }
  })

  const _factories = computed<ModuleFactoriesRegistry>(() => {
    return ctx.root.$options.configuration?.byKey('builder.componentFactories') || {}
  })

  const _modules = computed<ModulesRegistry>({
    get () {
      return { ...props.modules }
    },
    set (elements: ModulesRegistry) {
      ctx.emit('update:modules', elements)
    }
  })

  const _structure = computed<ContentStructure>({
    get () {
      return { ...props.structure }
    },
    set (elements: ContentStructure) {
      ctx.emit('update:structure', elements)
    }
  })

  const breakpointOptions = Object.entries(LayoutBreakpoint)
    .filter(([key, _]) => isNaN(key as any))
    .map(([_, value]) => ({ label: ctx.root.$i18n.t(`builder.breakpoint.options.${value}`).toString(), value }))

  const config = computed<Partial<BuilderConfig>>(() => ({
    columns: props.columns,
    showControls: showControls.value
  }))

  const elementProps = computed(() => {
    return {
      contentType: props.contentType,
      mode: props.mode,
      relatedService: props.relatedService,
      resolveInjection: <Interface> (type: string | symbol): Interface => {
        if (!ctx.root.$container) {
          throw new Error('Builder: Missing required Container instance!')
        }

        return ctx.root.$container!.get<Interface>(type as symbol)
      },
      ...props.fromParent
    }
  })

  const isBuilderActive = computed<boolean>(() => props.mode === PageBuilderOperationMode.Builder)
  const isMobile = computed<boolean>(() => breakpoint.value === LayoutBreakpoint.XS)

  const containerComponent = computed<ModuleConstructor | null>(() => {
    if (isBuilderActive.value && showControls.value) {
      return ContainerComponent as ModuleConstructor
    }

    return ctx.root.$options.configuration?.byKey('builder.containerComponent') ?? null
  })

  /*
   * Find breakpoint that already has a defined structure
   */
  function _findNonEmptyStructure () {
    const availableBreakpoints = Object.keys(_structure.value)

    if (availableBreakpoints.length === 0) {
      return undefined
    }

    return { key: availableBreakpoints[0], structure: _structure.value[availableBreakpoints[0]] }
  }

  function _handleScroll () {
    isSticky.value = window.pageYOffset > builderWrapperOffset.value
  }

  function _cloneStructure (source: ContainerDefinition[]): ContainerDefinition[] {
    const clonedStructure: ContainerDefinition[] = []
    const containers: ContainersRegistry = {}
    const modules: ModulesRegistry = {}

    for (const definition of source) {
      const container = _containers.value[definition.container]

      // clone container
      const c = replicateContainer(container, false)
      // Because data assigning is asnyc in Vue but this function itself is sync.
      //  That's why we need to add containers after iterate all of them.
      const replicatedContainer = c.id
      containers[replicatedContainer] = c

      // clone each module
      const replicatedModules: ModuleDefinition[] = definition.modules.map(moduleDefinition => {
        //  Because data assigning is asnyc in Vue but map loop is sync
        //  That's why we need to add modules after iterate all of them.
        const m = replicateModules([_modules.value[moduleDefinition.module]], false)
        const replicatedModule = m[0].id
        modules[replicatedModule] = m[0]

        return { ...cloneDeep(moduleDefinition), module: replicatedModule }
      })

      // append structure definition
      clonedStructure.push({ container: replicatedContainer, modules: replicatedModules })
    }

    // update containers and modules
    _containers.value = { ..._containers.value, ...containers }
    _modules.value = { ..._modules.value, ...modules }

    return clonedStructure
  }

  /**
   * Create new container add place it into the structure.
   */
  function addContainer (event: ContainerAddEvent): void {
    const containers = { ..._containers.value }
    const id: ElementIdentifier = uuid()
    containers[id] = { id }

    _containers.value = containers

    const definitions: ContainerDefinition[] = [...(_structure.value[breakpoint.value] || [])]
    switch (event.destination) {
      case 'append':
        definitions.push({ container: id, modules: [] })
        break
      case 'prepend':
        definitions.unshift({ container: id, modules: [] })
        break
    }

    const structure = { ..._structure.value }
    structure[breakpoint.value] = definitions

    _structure.value = structure
    // Let's open a container's form after it's successful addition.
    containerToEdit.value = id
  }

  /**
   * Create new module inside a given container.
   */
  function addModule (event: ContainerEvent): void {
    modalConnector.open(Modals.SelectModuleModal, {
      modulesRegistry: props.modulesRegistry,
      onSelection: (driver: string) => {
        const factory: ModuleFactory | undefined = _factories.value[driver]
        if (!factory) {
          ctx.emit('error', `PageBuilder: Missing factory of given module [${driver}]`)
          return
        }

        const module: AnyModule = { ...factory.createModule(), id: uuid(), title: '', isVisible: false }
        const modules = { ..._modules.value }
        modules[module.id] = module
        _modules.value = modules

        for (let i = 0; i < _structure.value[breakpoint.value].length; i++) {
          if (_structure.value[breakpoint.value][i].container !== event.container) {
            continue
          }

          const definition = { ..._structure.value[breakpoint.value][i] }
          definition.modules.push({
            module: module.id,
            size: factory.createInitialSize()
          })

          const structure = { ..._structure.value }
          structure[breakpoint.value][i] = definition
          _structure.value = structure
        }

        // Let's open a module's form after it's successful addition.
        moduleToEdit.value = module.id
      }
    }, {
      closableWithOutsideClick: true,
      size: SizeMap.Large
    })
  }

  /*
   * Callback for changing current breakpoint
   */
  function changeBreakpoint () {
    if (_structure.value[breakpoint.value] && _structure.value[breakpoint.value].length !== 0) {
      return
    }

    const nonEmpty = _findNonEmptyStructure()

    if (!nonEmpty) {
      return
    }

    const fromLabel = ctx.root.$t(`builder.breakpoint.name.${nonEmpty.key}`)
    const toLabel = ctx.root.$t(`builder.breakpoint.name.${breakpoint.value}`)

    modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: ctx.root.$t('builder.breakpoint.clone.yes'),
        contentText: ctx.root.$t('builder.breakpoint.clone.text', { from: fromLabel, to: toLabel }),
        header: ctx.root.$t('builder.breakpoint.clone.header', { name: toLabel })
      },
      onConfirm: () => {
        _structure.value = { ..._structure.value, [breakpoint.value]: _cloneStructure(nonEmpty.structure) }
        modalConnector.close()
      }
    })
  }

  /**
   * Set container's id that will be edit.
   */
  function containerDrawer (event: ContainerEvent): void {
    containerToEdit.value = event.container
  }

  /**
   * Apply changes to container.
   */
  function editContainer (container: ContainerData): void {
    const containers = _containers.value
    containers[container.id] = container

    _containers.value = containers
  }

  /**
   * Apply changes to module.
   */
  function editModule (module: AnyModule): void {
    const modules = _modules.value
    modules[module.id] = module

    _modules.value = modules
  }

  /**
   * Set module's id that will be edit.
   */
  function moduleDrawer (event: ModuleEvent): void {
    moduleToEdit.value = event.module
  }

  function onToEditChange (toEdit: ElementIdentifier | null): void {
    const documentBody = document.body

    if (toEdit === null) {
      documentBody.style.overflowY = 'auto'
    } else {
      documentBody.style.overflowY = 'hidden'
    }
  }

  /**
   * Remove container with all it's modules.
   */
  function removeContainer (event: ContainerEvent): void {
    modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: ctx.root.$t('atoms.delete'),
        contentText: ctx.root.$t('builder.deleteContainerAction.text'),
        header: ctx.root.$t('builder.deleteContainerAction.header'),
        theme: DashmixTheme.Danger
      },
      onConfirm: () => {
        const desktop = _structure.value[breakpoint.value]
        const modules = desktop[event.index].modules.map(m => m.module)
        desktop.splice(event.index, 1)

        _structure.value[breakpoint.value] = desktop
        delete _containers.value[event.container]

        for (const m of modules) {
          delete _modules.value[m]
        }

        modalConnector.close()
      }
    })
  }

  /**
   * Remove module form container.
   */
  function removeModule (event: ModuleEvent): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const module: AnyModule = _modules.value[event.module]

    modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: ctx.root.$t('atoms.delete'),
        contentText: ctx.root.$t('builder.deleteModuleAction.text'),
        contentTitle: module.title || ctx.root.$t(`moduleDrivers.${module.driver}`),
        header: ctx.root.$t('builder.deleteModuleAction.header'),
        theme: DashmixTheme.Danger
      },
      onConfirm: () => {
        const structure = { ..._structure.value }
        const desktop = structure[breakpoint.value]
        if (!desktop.length) {
          ctx.emit('error', `There's no structure of breakpoint [${breakpoint.value}]`)
          return
        }

        desktop[event.containerIndex].modules.splice(event.index, 1)

        structure[breakpoint.value] = desktop
        _structure.value = structure

        const modules = { ..._modules.value }
        delete modules[event.module]
        _modules.value = modules

        modalConnector.close()
      }
    })
  }

  function replicateContainer (toReplicate: Container, updateContainers: boolean = true): Container {
    const newOne: Container = cloneDeep(toReplicate)
    newOne.id = uuid()

    const containers = { ..._containers.value }
    containers[newOne.id] = newOne

    if (updateContainers) {
      _containers.value = containers
    }

    return newOne
  }

  function replicateModules (toReplicate: Module[], updateModules: boolean = true): Module[] {
    const newModules: Module[] = []
    const modules = { ..._modules.value }

    for (const m of toReplicate) {
      const newOne: Module = cloneDeep(m)
      newOne.id = uuid()

      modules[newOne.id] = newOne
      newModules.push(newOne)
    }

    if (updateModules) {
      _modules.value = modules
    }

    return newModules
  }

  onMounted(() => {
    const element = document.querySelector('.page-builder__wrapper') as HTMLElement
    const controls = document.querySelector('.page-builder__controls') as HTMLElement

    if (element && controls) {
      const initOffset: number = 75
      const offset: number = element.offsetTop - controls.getBoundingClientRect().height - initOffset
      builderWrapperOffset.value = offset > initOffset ? offset : initOffset

      document.addEventListener('scroll', _handleScroll)
    }
  })

  onUnmounted(() => {
    document.removeEventListener('scroll', _handleScroll)
  })

  watch(moduleToEdit, onToEditChange)
  watch(containerToEdit, onToEditChange)

  const listeners = {
    addContainer,
    addModule,
    editContainer: containerDrawer,
    editModule: moduleDrawer,
    removeContainer,
    removeModule
  }

  return {
    _containers,
    _modules,
    _structure,
    breakpoint,
    breakpointOptions,
    changeBreakpoint,
    config,
    containerComponent,
    containerToEdit,
    editContainer,
    editModule,
    elementProps,
    isBuilderActive,
    isMobile,
    isSticky,
    listeners,
    moduleToEdit,
    replicateContainer,
    replicateModules,
    showControls
  }
}
