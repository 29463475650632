

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { IRelatedService, RelatedServiceType } from '@service/related'

import {
  CanBeAlignedInGrid,
  HorizontalAlignment,
  Inject, ModuleDriver,
  VerticalAlignment
} from '../../../shared/modules'
import { SkiplinksModuleForm } from '../../../shared/modules/src/components/modules/Skiplinks/form/Skiplinks.form.vue'
import { SkiplinksModuleContent } from '../../../shared/modules/src/components/modules/Skiplinks'

@Component<SkipLinksAddonModal>({
  name: 'SkipLinksAddonModal',
  components: { SkiplinksModuleForm },
  mounted (): void {
    if (typeof this.payload.content.content === 'undefined') {
      return
    }

    this.content.containers = [...this.payload.content.content.containers]
  }
})
export class SkipLinksAddonModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: AnyObject

  @Inject(RelatedServiceType)
  protected readonly relatedService!: IRelatedService

  /**
   * Initial content of addon.
   */
  public content: SkiplinksModuleContent = {
    containers: []
  }

  /**
   * Determines default alignment for addon.
   */
  public get defaultAlignment (): CanBeAlignedInGrid['alignment'] {
    return {
      x: {
        mobile: HorizontalAlignment.Justify,
        desktop: HorizontalAlignment.Justify
      },
      y: {
        mobile: VerticalAlignment.Top,
        desktop: VerticalAlignment.Top
      }
    }
  }

  /**
   * Applies changes to the variant and close modal.
   */
  public applyChanges (): void {
    this.payload.onChange({
      alignment: this.defaultAlignment,
      content: {
        containers: [...this.content.containers]
      },
      driver: ModuleDriver.Skiplinks
    })

    this.closeModal()
  }

  /**
   * Closes the modal.
   */
  public closeModal () {
    this.payload.onClose()
  }

  /**
   * Mock function to fulfill `resolveInjection` @Prop
   *
   * (It is workaround, I don't know whether it is OK , to pass empty function as Mock)
   */
  public resolveInjectionMockCallback (): void {
    //
  }
}
export default SkipLinksAddonModal
