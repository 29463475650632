













import { Component } from 'vue-property-decorator'

import { AbstractSetItem } from './AbstractSetItem'
import { SocialMediaItemData } from '../../contracts'

@Component
export class OffersSetItem extends AbstractSetItem<SocialMediaItemData> {
}

export default OffersSetItem
