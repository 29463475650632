// Copyright © 2021 Move Closer

import {
  BlogPostDriver,
  BlogPostsFeedDriver,
  DepartmentDriver,
  DepartmentsFeedDriver,
  FileDriver,
  GalleryDriver,
  JobOfferDriver,
  JobOffersFeedDriver,
  LinkDriver,
  NavigationDriver,
  RelatedType,
  RelatedTypeDriverRegistry,
  SocialPostDriver,
  SocialPostsFeedDriver
} from '@modules'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resolveDrivers: () => RelatedTypeDriverRegistry = () => ({
  [RelatedType.BlogPost]: new BlogPostDriver(),
  [RelatedType.BlogPostsFeed]: new BlogPostsFeedDriver(),
  [RelatedType.Department]: new DepartmentDriver(),
  [RelatedType.DepartmentsFeed]: new DepartmentsFeedDriver(),
  [RelatedType.File]: new FileDriver(),
  [RelatedType.Gallery]: new GalleryDriver(),
  [RelatedType.JobOffer]: new JobOfferDriver(),
  [RelatedType.JobOffersFeed]: new JobOffersFeedDriver(),
  [RelatedType.Link]: new LinkDriver(),
  [RelatedType.Navigation]: new NavigationDriver(),
  [RelatedType.SocialPost]: new SocialPostDriver(),
  [RelatedType.SocialPostsFeed]: new SocialPostsFeedDriver()
})
