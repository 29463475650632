// Copyright © 2021 Move Closer

import {
  IVariantStatusService,
  VariantStatusService,
  VariantStatusServiceType
} from '@module/content/services/variantStatus'
import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector, IModal,
  Interfaces, ModalType,
  Module
} from '@movecloser/front-core'

import { ContentRepository } from './repositories/content'
import {
  AcceptanceRepositoryType,
  ContentRepositoryType,
  IAcceptanceRepository,
  IContentRepository,
  INodesRepository,
  IVariantsRepository,
  NodesRepositoryType,
  VariantsRepositoryType
} from './contracts/repositories'

import { AcceptanceRepository } from './repositories/acceptance'
import { contentModals } from './config/modals'
import { NodesRepository } from './repositories/nodes'
import { resources } from './config/resources'
import { routesFactory } from './config/routes'
import { VariantsRepository } from './repositories/variants'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@AppModule({
  name: 'content',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IAcceptanceRepository>(AcceptanceRepositoryType).to(AcceptanceRepository)
      bind<IContentRepository>(ContentRepositoryType).to(ContentRepository)
      bind<INodesRepository>(NodesRepositoryType).to(NodesRepository)
      bind<IVariantsRepository>(VariantsRepositoryType).to(VariantsRepository)
      bind<IVariantStatusService>(VariantStatusServiceType).toDynamicValue((context: Interfaces.Context) => {
        return new VariantStatusService(
          context.container.get<IVariantsRepository>(VariantsRepositoryType),
          context.container.get<IModal>(ModalType)
        )
      })
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)

    const modalConnector: IModal = container.get(ModalType)
    modalConnector.register(contentModals)
  },
  routes: routesFactory
})
export class ContentModule extends Module {}
