// Copyright © 2021 Move Closer

import { Component, Vue } from 'vue-property-decorator'
import { EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@modules'
import { PropertyItem } from '@/shared/contracts/content'

import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { ContentEditPayload, ContentModel, ContentRepositoryType, IContentRepository, Properties } from '../../contracts'
import { ContentModals } from '../../config/modals'
import { createBreadcrumbsFromContent, initBreadcrumbs, mapOptionsToSelected } from '../../helpers'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component
export class AbstractEditBasics extends Vue {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public breadcrumbs = initBreadcrumbs
  protected error: string|null = null
  protected isDirty: boolean = false
  public isLoading: boolean = true
  public isSaving: boolean = false
  public isDeleting: boolean = false
  public model: ContentModel | null = null
  public payload: ContentEditPayload = {} as ContentEditPayload
  public modelProperties: string[] = []

  public get contentOptions (): PropertyItem[] {
    return []
  }

  public get properties (): string[] {
    return this.modelProperties
  }

  created () {
    this.loadContent(this.$route.params.id as unknown as Identifier)
  }

  protected loadContent (id: Identifier): void {
    this.contentRepository.load(id).then(model => {
      this.breadcrumbs.items = createBreadcrumbsFromContent(model, 'content.articles.list', 'parent')
      this.model = model
      this.payload = model.toEditPayload()
      this.isLoading = false
      this.modelProperties = mapOptionsToSelected(model.properties, this.contentOptions.map(o => o.key))
      this.setProperties(this.modelProperties)
    })
  }

  protected onLosingAccept (id: Identifier): void {
    this.eventBus.emit('ui:edit-mode.force', id)
    this.modalConnector.close()
  }

  protected onPropertiesChange (options: string[]) {
    this.setProperties(options)

    this.toggleDirty(true)
  }

  protected preventLosingData (id: Identifier): boolean {
    if (!this.isDirty) {
      return true
    }

    this.modalConnector.open(ContentModals.PreventLoosingData, {
      onConfirm: () => this.onLosingAccept(id),
      onClose: () => this.modalConnector.close()
    })

    return false
  }

  protected setProperties (options: string[]): void {
    this.modelProperties = options
    this.payload.properties = this.contentOptions.map(o => o.key)
      .reduce((agr: Properties, o: string) => {
        agr[o] = options.includes(o)
        return agr
      }, {} as Properties)
  }

  protected toggleDirty (isDirty: boolean = true): void {
    this.isDirty = isDirty
  }
}
