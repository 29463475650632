






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { Inject } from '@modules'

import { ContentModel, ContentSortingPayload, IContentRepository, ContentRepositoryType } from '@module/content/contracts'
import { SortableCollection } from '@component/SortableCollection'
import { SortContentModalItem } from './SortContentModalItem.vue'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'SortContentModal',
  components: { SortableCollection, SortContentModalItem }
})
export class SortContentModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: ContentSortingPayload

  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  public isDisabled: boolean = false
  public itemComponent: VueConstructor = SortContentModalItem
  public items: ContentModel[] = []

  public get collection (): ContentModel[] {
    return this.items
  }

  public set collection (newCollection: ContentModel[]) {
    this.items = newCollection
  }

  mounted () {
    this.items = this.payload.collection || []
  }

  protected close () {
    this.payload.onClose()
  }

  protected submit () {
    this.isDisabled = true
    const children = [...this.items].map(c => c.id)
    this.payload.onConfirm(children)
  }
}

export default SortContentModal
