











































import { DashmixBreadcrumbsProps, DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'

import { EditModeLayout } from '@component/EditModeLayout/EditModeLayout'
import { FullScreenLoader } from '@/shared/layouts/FullScreenLoader'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@modules'
import { Loader } from '@component/Loader'
import { Sidebar } from '@/shared/layouts/partials/Sidebar'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { EditGalleryIntention } from '../intentions/EditGalleryIntention'
import { EmptyGalleryCreatePayload } from '../intentions/CreateGalleryIntention'
import { GalleryForm } from '../components/GalleryForm.vue'
import { GalleryItems } from '../components/GalleryItems.vue'
import { GalleryModel, GalleryRepositoryType, IGalleryRepository } from '../contracts'
import { createBreadcrumbsFromAction } from '../helpers'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'EditGallery',
  components: { EditModeLayout, GalleryForm, GalleryItems, FullScreenLoader, Sidebar, Loader }
})
export class EditGallery extends Vue {
  @Inject(GalleryRepositoryType)
  private galleryRepository!: IGalleryRepository

  public buttonTheme = DashmixTheme
  public formName: string = 'updateGallery'
  public gallery: GalleryModel | null = null
  public icons = DashmixIconName
  public isLoading: boolean = false
  public payload = { ...EmptyGalleryCreatePayload }

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: createBreadcrumbsFromAction(
        this.$t('media.gallery.listTitle') as string,
        this.$t('media.gallery.edit') as string,
        'edit'
      ),
      root: initBreadcrumbs.root
    }
  }

  public get galleryId (): Identifier {
    return parseInt(this.$route.params.id) as Identifier
  }

  public get galleryTitle (): string {
    return this.gallery?.title || ''
  }

  created () {
    this.loadGallery()
  }

  public goToList () {
    this.$router.push({ name: 'media.gallery.list' })
  }

  public loadGallery () {
    this.isLoading = true

    this.galleryRepository.load(this.galleryId).then((gallery: GalleryModel) => {
      this.gallery = gallery

      this.payload = {
        title: gallery.title,
        description: gallery.description
      }
    })
      .catch(error => {
        console.log(error)
        this.goToList()
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public updateGallery () {
    this.isLoading = true

    const intention = new EditGalleryIntention(this.payload)
    this.galleryRepository.update(this.galleryId, intention).then(model => {
      if (this.gallery) {
        this.gallery.applyIntention(intention)
      }
    }).catch((error: ResourceActionFailed) => {
      console.log(error)
      this.isLoading = false
    })
  }
}

export default EditGallery

