import { render, staticRenderFns } from "./GalleryPickerItem.vue?vue&type=template&id=665a5c8a&scoped=true&"
import script from "./GalleryPickerItem.vue?vue&type=script&lang=ts&"
export * from "./GalleryPickerItem.vue?vue&type=script&lang=ts&"
import style0 from "./GalleryPickerItem.vue?vue&type=style&index=0&id=665a5c8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665a5c8a",
  null
  
)

export default component.exports