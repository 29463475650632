












































import { Component } from 'vue-property-decorator'
import { DashmixIconName, DashmixAlertTheme } from '@movecloser/ui-core'
import { MetaInfo } from 'vue-meta'

import { EditModeLayout, EditModeTabsProps } from '@component/EditModeLayout'
import { Identifier } from '@/shared/contracts/data'

import { AbstractEditContent } from './abstracts/AbstractEditContent'
import { ContentType } from '../contracts'
import { PageVariant } from '../components/PageVariant.vue'
import { VariantTab } from '../components/VariantTab.vue'
import { VariantActions } from '../components/VariantActions.vue'
import { VariantAddons } from '../components/VariantAddons.vue'
import { VariantTabActions } from '../components/VariantTabActions.vue'
import { VariantsSelection } from '../components/VariantsSelection.vue'
import { ConnectorErrors } from '@/shared/exceptions/connector-errors'

/**
 * This view uses an abstract that unifies all actions available for Content management.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @parent AbstractEditContent
 *
 * @property {VariantModel} activeVariant
 * @property {string} activeVariantTab
 * @property {object: DashmixBreadcrumbsProps} breadcrumbs
 * @property {boolean} isDisabled
 * @property {boolean} isLoading
 * @property {VariantModel[]} selectedVariants
 * @property {SimpleVariantModel[]} variants
 */
@Component({
  name: 'EditPageContent',
  components: {
    EditModeLayout,
    PageVariant,
    VariantActions,
    VariantAddons,
    VariantsSelection,
    VariantTab,
    VariantTabActions
  },
  metaInfo (this: EditPageContent): MetaInfo {
    return {
      title: `${this.$t('content.editTabs.content')}`
    }
  }
})
export class EditPageContent extends AbstractEditContent {
  /**
   * Available inner-methods:
   * @method void loadContentWithBaseVariants (id: Identifier)
   * @method void onLosingAccept (id: Identifier)
   * @method void onVariantChange (changed: VariantModel)
   * @method boolean preventLosingData (id: Identifier)
   * @method void toggleDirty (isDirty: boolean)
   */
  public baseType: ContentType = ContentType.Page
  public icons = DashmixIconName
  public modeTabs: EditModeTabsProps = {
    items: [
      {
        label: 'content.editTabs.content',
        route: { name: 'content.pages.content', params: { id: this.$route.params.id } }
      },
      {
        label: 'content.editTabs.meta',
        route: { name: 'content.pages.basics', params: { id: this.$route.params.id } },
        guard: (id: Identifier): boolean => {
          return this.preventLosingData(id)
        }
      }
    ],
    initTab: 0
  }

  public get alertTheme (): DashmixAlertTheme {
    return this.errors.code === `${ConnectorErrors.ServerError}` ? DashmixAlertTheme.Danger : DashmixAlertTheme.Warning
  }

  public get tabs () {
    return this.selectedVariants.map(v => {
      return {
        component: PageVariant,
        props: {
          authUser: this.user,
          contentType: this.contentType,
          errors: this.errors,
          onDirty: this.toggleDirty,
          onVariantChange: this.onVariantChange,
          relatedService: this.resolveVariantServices(v.id),
          variant: v
        },
        tab: {
          id: `${v.id}`,
          label: VariantTab,
          props: {
            active: this.activeVariant.id,
            authUser: this.user,
            onVariantChange: this.onVariantChange,
            variant: v
          }
        }
      }
    })
  }
}

export default EditPageContent
