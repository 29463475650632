








































































import { DashmixBreadcrumbsProps, DashmixIconName } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'

import { FormInput } from '@component/form'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@modules'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader'
import { EditModeLayout } from '@component/EditModeLayout'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Property } from '@component/Property'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { Layout } from '../models/layout'
import { LayoutSlotForm } from '../components/LayoutSlotForm.vue'
import {
  ILayout,
  ILayoutRepository,
  LayoutData,
  LayoutModel,
  LayoutRepositoryType,
  LayoutSlot,
  LayoutSlotData
} from '../contracts'
import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'
import { composeLayoutSlotData } from '@module/layouts/helpers/layouts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<EditLayout>({
  name: 'EditLayout',
  components: {
    EditModeLayout,
    FormInput,
    LayoutSlotForm,
    Loader,
    ModelListHeader,
    Property
  },
  mounted () {
    this.slots = composeLayoutSlotData({})

    if (this.isEdit) {
      this.isLoading = true

      this.layoutRepository.get(this.id)
        .then(data => this.updateLayout(data, this.id))
        .catch(error => console.error(error))
        .finally(() => {
          this.isLoading = false
        })
    }
  }
})
export class EditLayout extends Vue {
  @Inject(LayoutRepositoryType)
  private layoutRepository!: ILayoutRepository

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  public containers: ContainersRegistry = {}
  public form = 'editLayout'
  private id: Identifier = Number(this.$route.params.id)
  private isActive: boolean = true
  private isEdit: boolean = !!this.id
  public isLoading: boolean = false
  public isSaving: boolean = false
  private layout: LayoutModel | null = null
  public modules: ModulesRegistry = {}
  public name: string = ''
  public navName: string = ''
  private slots: LayoutSlotData | null = null

  public Icons = DashmixIconName
  public LayoutSlot = LayoutSlot

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.layouts.title')}`,
          target: { name: 'layouts.layouts.list' }
        },
        {
          label: this.pageTitle,
          target: { name: 'layouts.layouts.edit' }
        }
      ],
      root: initBreadcrumbs.root,
      showOnMobile: false
    }
  }

  public get pageTitle (): string {
    return this.isEdit
      ? `${this.$t('settings.layouts.editTitle', { name: this.navName })}`
      : `${this.$t('settings.layouts.createTitle')}`
  }

  public extractLayoutStructure (slot: LayoutSlot): ContentStructure {
    if (!this.slots) {
      return {}
    }

    const found = this.slots[slot]
    return typeof found === 'object' && !Array.isArray(found) ? found : {}
  }

  public submit () {
    const payload: Omit<LayoutData, 'id'> = {
      containers: this.containers,
      isActive: this.isActive,
      modules: this.modules,
      name: this.name,
      slots: composeLayoutSlotData(this.slots || {})
    }

    this.isSaving = true

    const request = this.isEdit
      ? this.layoutRepository.update(this.id, payload)
      : this.layoutRepository.create(payload)

    request
      .then(data => {
        if (!this.isEdit && data.layoutId) {
          this.id = data.layoutId

          this.$router.push({
            name: 'layouts.layouts.edit',
            params: { id: `${data.layoutId}` }
          })
        }

        this.updateLayout(payload)
      })
      .catch(error => console.info(error))
      .finally(() => {
        this.isSaving = false
      })
  }

  public update (data: LayoutSlotData) {
    this.slots = { ...this.slots, ...data }
  }

  public async updateLayout (payload: Omit<LayoutData, 'id'>, id?: Identifier) {
    this.layout = Layout.hydrate<LayoutData, ILayout>({
      ...this.layout,
      ...payload,
      id: id ?? this.id
    })
    this.isEdit = !!this.id

    this.containers = this.layout.containers
    this.isActive = this.layout.isActive
    this.modules = this.layout.modules
    this.name = this.layout.name
    this.navName = this.layout.name
    this.slots = this.layout.slots
  }
}

export default EditLayout
