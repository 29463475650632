































import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType, EventPayload, IEventbus,
  IModal,
  ModalType
} from '@movecloser/front-core'
import { UsersModal } from '@module/users/config/modals'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { DashmixTheme, TableSearchBarAction } from '@movecloser/ui-core'
import { TableRowElement, TableHeadElement, TableHead } from '@movecloser/ui-core/lib/abstract/components/Table'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { HasIdentifier } from '@/shared/contracts/data'
import { Identifier, Inject } from '@modules'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { IUsersPermissions, UsersPermissions } from '../config/permissions.mixin'
import { UsersTableRow } from '../components/UsersTableRow.vue'
import { Roles, UserData, UserModel } from '../contracts/models'
import { usersFiltersConfig } from '../models/user.filters'
import { UserListsActions, userRowActionsFactory, usersTableHead } from '../maps/users'
import { User } from '@module/users/models/user'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<UsersList>({
  name: 'UsersList',
  components: { InteractiveTable, ModelListHeader },
  created () {
    this.eventBus.handle('edit:user', (event: EventPayload<Identifier>) => {
      if (!event.payload) {
        return
      }

      if (!this.canEditUsers) {
        return
      }
      this.onRoleEdit(event.payload)
    })
  }
})
export class UsersList extends Mixins<IUsersPermissions>(UsersPermissions) {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  public isLoading = false

  public get header (): HeaderInterface {
    const header: Partial<HeaderInterface> = {
      title: 'users.listTitle'
    }

    return header as HeaderInterface
  }

  public readonly filtersConfig = usersFiltersConfig
  public rowComponent = UsersTableRow
  public tableHead: TableHead = usersTableHead.map((item: TableHeadElement) => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public tableData: TableRowElement[] =[]

  public totalPages: number = 0
  public itemsTotal: number = 0

  public rowActions: TableSearchBarAction[] = []

  public actions: DropdownActions = {
    [UserListsActions.EditRole]: {
      callback: (data: unknown) => {
        this.onRoleEdit((data as HasIdentifier).id)
      }
    },
    [UserListsActions.ResetPassword]: {
      callback: (data: unknown) => {
        return this.onReset(data as UserData)
      },
      confirmation: {
        header: 'users.reset.header',
        contentText: 'users.reset.text',
        theme: DashmixTheme.Info,
        buttonLabel: 'users.reset.button'
      }
    }
  }

  public get breadcrumbs () {
    return {
      items: [
        {
          label: `${this.$t('users.listTitle')}`,
          target: { name: 'users.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)

    this.rowActions = userRowActionsFactory(this.domain, this.user)
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.userRepository.loadCollection(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditUsers,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.warn(error)
      this.isLoading = false
    })
  }

  public onReset (data: UserData) {
    this.isLoading = true
    this.authRepository.forgotPassword(data.email)
      .catch(error => {
        console.debug(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  protected onRoleChange (id: Identifier, newRoles: Roles) {
    this.tableData = this.tableData.map(row => {
      const user = { ...row.data as UserData }
      if (user.id === id) {
        user.roles = newRoles
      }

      row.data = User.hydrate(user)
      return { ...row }
    })
  }

  protected onRoleEdit (id: Identifier) {
    this.modalConnector.open(UsersModal.EditRoles,
      {
        userId: id,
        onSave: (newRoles: Roles) => this.onRoleChange(id, newRoles)
      })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default UsersList
