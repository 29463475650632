// Copyright © 2021 Move Closer

import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'
import { IModel, MagicModel } from '@movecloser/front-core'

import { HasIdentifier, Identifier } from '@/shared/contracts/data'

import { ContentType } from '@module/content/contracts'
import { OwnerModel } from '@module/root/contracts/models'

export type ILayout = IModel<LayoutData>

export type LayoutAddonData = {
  [type in ContentType]?: { id: Identifier; name: string }
}

export interface LayoutData extends HasIdentifier {
  containers: ContainersRegistry
  createdAt?: string
  editor?: OwnerModel
  isActive: boolean
  modules: ModulesRegistry
  name: string
  slots: LayoutSlotData
  updatedAt?: string
}

export type LayoutModel = MagicModel<LayoutData, ILayout>

export enum LayoutSlot {
  Bottom = 'bottom',
  Top = 'top'
}

export type LayoutSlotData = {
  [value in LayoutSlot]: ContentStructure
}
