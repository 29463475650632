// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Query } from '@/shared/contracts/query'

import { ISettingsRepository } from '../contracts/repositories'
import { Setting } from '../models/setting'
import { SettingData, SettingOptions } from '../contracts'

/**
 * @author Olga Milczek <michal.rossian@movecloser.pl>
 */

@Injectable()
export class SettingsRepository extends Repository<SettingData, Setting> implements ISettingsRepository {
  protected useAdapter: boolean = false

  public async getSetting<T> (type: string): Promise<T> {
    const response: IResponse = await this.connector.call(
      'settings',
      'get',
      { type }
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return response.data.data as T
  }

  public async loadCollection (query?: Query): Promise<ICollection<SettingData>> {
    const response: IResponse = await this.connector.call(
      'settings',
      'list',
      {},
      { ...query }
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return this.composeCollection(
      response.data.data,
      Setting,
      response.data.meta
    )
  }

  public async updateSetting<T> (type: string, payload: T, additionalOptions: SettingOptions = {}): Promise<void> {
    const response: IResponse = await this.connector.call(
      'settings',
      'update',
      { type },
      { value: payload, ...additionalOptions }
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
