
























































import { DashmixIconName, DashmixTheme, Hint } from '@movecloser/ui-core'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'

import {
  DictionaryRepositoryType,
  IDictionaryRepository
} from '@module/root/contracts/repositories'
import { Domain } from '@module/root/contracts/models'
import { FormTypeahead } from '@component/FromTypeahead/FormTypeahead'
import { Identifier, Inject } from '@modules'
import { Loader } from '@component/Loader'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { EditUsersRolesPayload } from '../contracts/components'
import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { Roles, SimpleRole } from '../contracts/models'
import { mapRolesToIntention } from '../heplers/mapRoles'

@Component({
  name: 'EditUsersRolesModal',
  components: { FormTypeahead, Loader }
})
export class EditUsersRolesModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: EditUsersRolesPayload

  @Inject(DictionaryRepositoryType)
  private dictionaryRepository!: IDictionaryRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  public form = 'editUserRoles'
  public icons = DashmixIconName
  public isLoading: boolean = false
  public isSearching: boolean = false
  public isSaving: boolean = false
  public options: Hint[] = []
  public roles: Roles = {}
  public themes = DashmixTheme
  public useForAll: boolean = false
  public userName: string = ''

  created () {
    this.loadOptions()
    this.loadUserData()
  }

  mounted () {
    // TODO fix - see src/modules/content/components/AddSetItemModal.vue:117
    (document.querySelector('.modal-body') as HTMLDivElement).style.overflow = 'visible'
  }

  public get siteLogos (): Record<Domain, AnyObject> {
    return [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          [site.domain]: site.logo.light
        }
      })
      .reduce((o, logo) => ({ ...o, ...logo }), {}) as unknown as Record<Domain, AnyObject>
  }

  public getTypeaheadOption (site: Domain): Hint[] {
    if (this.roles && this.roles[site]) {
      return this.roles[site].map(role => {
        return {
          value: role.id,
          label: role.name
        }
      })
    }
    return []
  }

  public onClear (): void {
    this.loadOptions()
  }

  public onConfirm (): void {
    this.isSaving = true

    const payload = {
      roles: !this.roles ? {} : mapRolesToIntention(this.roles)
    }
    this.userRepository.edit(this.payload.userId, payload)
      .then(() => {
        this.payload.onSave(this.roles)
      })
      .catch(error => console.debug(error))
      .finally(() => {
        this.isSaving = false
        this.modalConnector.close()
      })
  }

  public onDelete (site: Domain, selectedHint: Hint): void {
    if (this.roles && this.roles[site]) {
      this.roles[site] = this.roles[site].filter(role => {
        return role.id !== selectedHint.value
      })

      if (this.useForAll) {
        this.propagateRoles(this.roles[site])
      }
    }
  }

  public onSelect (site: Domain, selectedHint: Hint): void {
    if (this.roles && this.roles[site]) {
      this.roles[site].push({ id: selectedHint.value as Identifier, name: selectedHint.label })

      if (this.useForAll) {
        this.propagateRoles(this.roles[site])
      }
    }
  }

  public changeSetForAll (value: true): void {
    this.useForAll = value

    if (value) {
      this.propagateRoles(this.roles['bnp.pl'])
    }
  }

  public onSearch (searchedParams: string): void {
    this.loadOptions(searchedParams)
  }

  public propagateRoles (selected: SimpleRole[]): void {
    if (!this.roles) {
      return
    }
    this.roles = Object.keys(this.roles)
      .reduce((o, key) => ({ ...o, [key]: [...selected] }), {}) as unknown as Roles
  }

  protected getRoles (): Roles {
    return [...this.siteResolver.getDictionary()]
      .map(site => site.domain)
      .reduce((o, key) => ({ ...o, [key]: [] }), {}) as unknown as Roles
  }

  protected loadOptions (searchParams?: string) {
    this.isSearching = true
    this.dictionaryRepository.loadRolesDictionary({ q: searchParams || '' })
      .then(collection => {
        this.options = [...collection].map(option => {
          return {
            value: option.id,
            label: option.name
          }
        })
      }).finally(() => { this.isSearching = false })
  }

  protected loadUserData () {
    this.isLoading = true

    this.userRepository.load(this.payload.userId).then(model => {
      this.roles = model.roles
      this.userName = model.fullName()

      if (!this.roles) {
        this.roles = this.getRoles()
      } else if (this.roles && Object.keys(this.roles).length === 0) {
        this.roles = this.getRoles()
      }
    }).catch(error => console.log(error)
    ).finally(() => { this.isLoading = false })
  }
}

export default EditUsersRolesModal
