// Copyright © 2021 Move Closer

import { Injectable, ResourceActionFailed } from '@movecloser/front-core'

import {
  AnyDescription,
  AsyncRelatedService,
  Description,
  DriverConfig,
  Identifier,
  PossibleTypeDriver,
  Related,
  RelatedRecord,
  RelatedType,
  RelatedTypeDriverRegistry
} from '@modules'

import {
  CalledRelatedServiceMethod,
  IRelatedRepository,
  IRelatedService,
  RelatedQuery,
  RelatedToLoad
} from './related.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class RelatedService extends AsyncRelatedService implements IRelatedService {
  /**
   * Repository to load all related.
   */
  protected repository: IRelatedRepository

  /**
   * Timeout that loads related.
   */
  protected timeout: number | undefined

  /**
   * Related to load next call.
   */
  protected toLoad: RelatedToLoad<AnyDescription>[] = []

  public constructor (
    drivers: RelatedTypeDriverRegistry,
    relatedRepository: IRelatedRepository,
    record?: RelatedRecord
  ) {
    super(drivers, record)
    this.repository = relatedRepository
  }

  /**
   * @inheritDoc
   */
  public async describe<T extends Description = AnyDescription> (related: Related): Promise<T> {
    try {
      return await super.describe<T>(related)
    } catch (e) {
      return new Promise((resolve, reject) => {
        this.pushToQueue({
          method: CalledRelatedServiceMethod.Describe,
          related,
          resolve,
          reject
        })
      })
    }
  }

  /**
   * @inheritDoc
   */
  public merge (type: RelatedType, key: Identifier, value: AnyDescription): void {
    this.storeRelated({ [type]: { [key]: value } })
  }

  /**
   * @inheritDoc
   */
  public async resolve<T extends (Description | Description[]) = AnyDescription> (
    related: Related,
    config: DriverConfig = {}
  ): Promise<T> {
    try {
      return await super.resolve<T>(related, config)
    } catch (e) {
      return new Promise<T>((resolve, reject) => {
        this.pushToQueue<T>({
          method: CalledRelatedServiceMethod.Resolve,
          related,
          resolve,
          reject,
          config
        })
      })
    }
  }

  /**
   * Loads missing related in a bulk call.
   */
  protected loadMissingRelated (): void {
    const toLoad = [...this.toLoad]
    this.toLoad = []

    const query: RelatedQuery = {}
    for (const l of toLoad) {
      if (typeof query[l.related.type] === 'undefined') {
        query[l.related.type] = []
      }

      // eslint-disable-next-line
      query[l.related.type]!.push(l.related.value)
    }

    this.repository.load(query).then((record: RelatedRecord) => {
      this.storeRelated(record)
      this.notifyOnSuccess(toLoad)
    }).catch((error: ResourceActionFailed) => {
      this.notifyOnFail(toLoad, error)
    })
  }

  /**
   * Push next related to queue stack.
   */
  protected pushToQueue<T> (toPush: RelatedToLoad<T>): void {
    this.toLoad.push(toPush as RelatedToLoad<AnyDescription>)
    this.triggerLoading()
  }

  /**
   * Queue control.
   */
  protected triggerLoading (): void {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.loadMissingRelated()) as unknown as number
  }

  /**
   * Marks all Promises from stack as 'rejected'.
   */
  private notifyOnFail (toNotify: RelatedToLoad<AnyDescription>[], error: Error): void {
    for (const relatedToLoad of toNotify) {
      relatedToLoad.reject(error)
    }
  }

  /**
   * Marks all Promises from stack as 'resolved'.
   */
  private notifyOnSuccess (toNotify: RelatedToLoad<AnyDescription>[]): void {
    for (const receiver of toNotify) {
      const driver = this.resolveDriver(receiver.related.type)

      try {
        const result = this.recall(driver, receiver)
        receiver.resolve(result)
      } catch (e) {
        this.notifyOnFail([receiver], e)
      }
    }
  }

  /**
   * Recalls correct method for Promise resolving.
   */
  private recall<T> (driver: PossibleTypeDriver, toRecall: RelatedToLoad<T>) {
    switch (toRecall.method) {
      case CalledRelatedServiceMethod.Describe:
        return driver.describe(`${toRecall.related.value}`, this.record)
      case CalledRelatedServiceMethod.Resolve:
        return driver.resolve(
          `${toRecall.related.value}`,
          this.record,
          toRecall.config || {},
          this.resolveDriver.bind(this)
        )
    }
  }
}
