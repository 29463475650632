// Copyright © 2021 Move Closer

import { Authentication, AuthServiceType, EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '@modules'

import { UserModel } from '@module/auth/contracts/models'

import { Domain } from '../contracts/models'
import { ISiteResolver, SiteResolverType } from '../services/site-resolver'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<AbstractWidget>({
  created () {
    this.fetchData()

    this.eventBus.handle('app:authentication', (event: EventPayload<string>) => {
      if (event.payload === 'booted' || event.payload === 'refreshed') {
        if (this.authService.check()) {
          this.fetchData()
        }
      }
    })
  }
})
export class AbstractWidget extends Vue {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public get domain () {
    return this.siteResolver.getSite()?.domain || Domain.PL
  }

  public get user (): UserModel | null {
    return this.authService.user
  }

  public get isWidgetVisable (): boolean {
    return this.checkPermission(this.domain, this.user)
  }

  protected fetchData (): void {
    // Method should be implemented in each widget
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected checkPermission (domain: Domain, user: UserModel | null): boolean {
    return true
  }
}

export default AbstractWidget
