

















import { Component, Vue, Watch } from 'vue-property-decorator'
import { DashmixBreadcrumbsProps, DashmixBoxTabItem, FiltersConfig, GroupsConfig } from '@movecloser/ui-core'
import { VueConstructor } from 'vue'
import { MetaInfo } from 'vue-meta'

import { DropdownActions } from '@/shared/contracts/content'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@modules'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { Query } from '@/shared/contracts/query'

import { acceptanceAcceptedTableHead, AcceptanceActions, acceptancePendingTableHead, acceptanceRejectedTableHead, acceptanceRowActions } from '../maps/acceptance'
import { AcceptanceTableRow } from '../components/AcceptanceTableRow.vue'
import { AcceptanceData, AcceptanceModel, AcceptanceRepositoryType, AcceptanceStatus, AcceptanceTableRowElement, ContentType, IAcceptanceRepository } from '../contracts'
import { acceptanceFiltersConfig, AcceptanceStatusFiltersConfig } from '../models/acceptance.filters'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'AcceptanceList',
  components: { InteractiveTable },
  metaInfo (this: AcceptanceList): MetaInfo {
    return {
      title: `${this.$t('content.acceptance.listTitle')}`
    }
  }
})
export class AcceptanceList extends Vue {
  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  public isLoading: boolean = true
  public itemActions = acceptanceRowActions
  public model: AcceptanceModel = {} as AcceptanceModel
  public itemActionsDefinitions: DropdownActions = {
    [AcceptanceActions.Accept]: {
      callback: (data: unknown) => {
        const model = data as AcceptanceData
        return this.accept(model.id)
      }
    },
    [AcceptanceActions.Preview]: {
      callback: (data: unknown) => {
        this.$router.push({
          name: 'content.acceptance.show',
          params: { contentType: this.contentType, id: `${(data as AcceptanceData).id}` }
        })
      }
    }
  }

  public tabs: DashmixBoxTabItem[] = [
    {
      tab: {
        id: AcceptanceStatus.Pending,
        label: `${this.$t('content.acceptanceList.pending')}`
      }
    },
    {
      tab: {
        id: AcceptanceStatus.Accepted,
        label: `${this.$t('content.acceptanceList.accepted')}`
      }
    },
    {
      tab: {
        id: AcceptanceStatus.Published,
        label: `${this.$t('content.acceptanceList.published')}`
      }
    },
    {
      tab: {
        id: AcceptanceStatus.Rejected,
        label: `${this.$t('content.acceptanceList.rejected')}`
      }
    }
  ]

  public tableItems: AcceptanceTableRowElement[] = []
  public tableRow: VueConstructor = AcceptanceTableRow

  public totalItems: number = 0
  public totalPages: number = 0

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.' + this.contentType + '.listTitle')}`,
          target: { name: `content.${this.contentType}s.list` }
        }, {
          label: `${this.$t('content.acceptance.listTitle')}`,
          target: { name: 'content.acceptance.list' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      },
      showOnMobile: false
    }
  }

  public get contentType (): string {
    return `${this.$route.params.contentType}` || 'article'
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public get status (): AcceptanceStatus {
    return this.queryParams.status as AcceptanceStatus
  }

  public get tableHead () {
    switch (this.status) {
      case AcceptanceStatus.Pending:
        return acceptancePendingTableHead
      case AcceptanceStatus.Rejected:
        return acceptanceRejectedTableHead
      default:
        return acceptanceAcceptedTableHead
    }
  }

  public get tabFilters (): FiltersConfig {
    const tabFilters: GroupsConfig = {}

    if (!this.status) {
      return {
        groups: tabFilters,
        ignore: [],
        override: {}
      }
    }

    for (const filterKey of AcceptanceStatusFiltersConfig[this.status]) {
      tabFilters[filterKey] = acceptanceFiltersConfig.groups[filterKey]
    }

    return {
      ...acceptanceFiltersConfig,
      groups: tabFilters
    }
  }

  created () {
    this.validateQuery()

    this.loadList()
  }

  updated () {
    this.validateQuery()
  }

  public switchTab (value: string) {
    this.$router.push({
      name: 'content.acceptance.list',
      params: { content: this.contentType },
      query: { status: value }
    })
  }

  protected accept (id: Identifier) {
    return this.acceptanceRepository.accept(id).then(() => {
      this.tableItems = this.tableItems.filter(rowData => rowData.id !== id.toString())
    })
  }

  protected validateQuery (): void {
    if (![ContentType.Page].includes(this.contentType as ContentType)) {
      this.$router.push({
        name: 'content.acceptance.list',
        params: { content: ContentType.Page },
        query: { status: AcceptanceStatus.Pending }
      })
    }

    if (typeof this.queryParams.status !== 'string' || !Object.values(AcceptanceStatus).includes(this.status)) {
      this.$router.push({
        name: 'content.acceptance.list',
        params: { content: this.contentType },
        query: { status: AcceptanceStatus.Pending }
      })
    }
  }

  protected loadList (query: Query = {}): void {
    this.isLoading = true

    this.acceptanceRepository.loadCollection({ ...query, type: this.contentType, status: this.status || AcceptanceStatus.Pending })
      .then(collection => {
        this.tableItems = [...collection].map(model => {
          return { id: `${model.id}`, selectable: true, data: model }
        })

        this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
        this.totalItems = collection.meta.total
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default AcceptanceList
