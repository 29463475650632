// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const FullScreenLayout = defineComponent({
  name: 'FullScreenLayout',
  setup () {
    const logo = require('../../assets/images/icons/logo.png')
    return {
      logo
    }
  },
  template: `
    <main id="main-container">
      <div class="row no-gutters bg-image">
        <div class="hero-static col-md-8 offset-md-2 col-lg-6 offset-lg-3 d-flex align-items-center">
          <div class="bg-white py-4 card shadow row no-gutters justify-content-center w-100">

            <div class="col-12 mb-3">
              <img :src='logo' alt="logo" class="img-fluid mb-3">
            </div>
            <slot/>
          </div>
        </div>
      </div>
    </main>
  `
})

export default FullScreenLayout
