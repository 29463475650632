// Copyright © 2021 Move Closer

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Vue } from 'vue-property-decorator'

import { log } from '../../support'

type ListenerFn<K extends keyof WindowEventMap> = (this: Window, ev: WindowEventMap[K]) => any

interface EventConfig<K extends keyof WindowEventMap> {
  listener: ListenerFn<K>
  options?: EventListenerOptions
}

/**
 * Extendable component that's capable of registering the event listeners
 * and unregistering them before the component gets destroyed.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<EventListener>({
  name: 'EventListener',
  beforeDestroy (): void {
    this.removeEventListeners()
  }
})
export class EventListener extends Vue {
  /**
   * Registry that binds the event type with the array of listeners
   * (functions/handlers) registered under this type.
   */
  private eventsRegistry: Partial<{
    [K in keyof WindowEventMap]: Array<EventConfig<K>>
  }> = {}

  /**
   * Registers the specified event type/listener combination.
   *
   * @see Window.addEventListener
   */
  protected addEventListener <K extends keyof WindowEventMap> (
    type: K,
    listener: ListenerFn<K>,
    options?: boolean | AddEventListenerOptions
  ): void {
    if (typeof window === 'undefined') {
      log('EventListener.addEventListener(): [window] is [undefined]! Aborting!')
      return
    }

    try {
      window.addEventListener(type, listener, options)

      if (typeof this.eventsRegistry[type] === 'undefined') {
        // @ts-expect-error - These are some complicated type annotations and probably there's a proper way
        // to write these, but at this moment I really don't have idea on HOW to do this,
        // so I'm falling back to this suppression.
        this.eventsRegistry[type] = [{ listener, options }]
      } else {
        // @ts-expect-error - The "Symbol.iterator()" error would happen if we would attempt to spread the value
        // that's undefined, but since we made the necessary check above, we probably can suppress this warning.
        this.eventsRegistry[type] = [...this.eventsRegistry[type], { listener, options }]
      }
    } catch (error) {
      const message: string = 'EventListener.addEventListener(): Failed to add the event listener!'
      log([message, error], 'error')
    }
  }

  /**
   * Removes (unregisters) all registered event listeners.
   */
  private removeEventListeners (): void {
    if (typeof window === 'undefined') {
      log('EventListener.removeEventListener(): [window] is [undefined]! Aborting!')
      return
    }

    Object.entries(this.eventsRegistry).forEach(
      ([type, events]) => {
        if (typeof events === 'undefined') {
          log(`EventListener.removeEventListener(): There are no events registered for the [${type}] type!`, 'warn')
          return
        }

        events.forEach((config: EventConfig<any>) => {
          const { listener, options } = config

          try {
            window.removeEventListener(type, listener, options)
          } catch (error) {
            const message: string = `EventListener.removeEventListener(): Failed to remove the event listener for the [${type}] type!`
            log([message, error, listener, options], 'error')
          }
        })
      }
    )
  }
}
