// Copyright © 2021 Move Closer

import { CreateRoleIntention } from '@module/users/intentions/createRole'
import { UpdateRoleIntention } from '@module/users/intentions/updateRole'
import { ICollection } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import {
  EditUserPayload,
  RoleData,
  RoleModel,
  UserModel, UsersInfoData
} from './models'

export interface IRolesRepository {
  create (intention: CreateRoleIntention): Promise<string>
  delete (id: Identifier): Promise<void>
  load (id: RoleData['id']): Promise<RoleModel>
  loadCollection (query?: Query): Promise<ICollection<RoleModel>>
  update (id: Identifier, intention: UpdateRoleIntention): Promise<void>
}

export interface IUserRepository {
  delete (id: Identifier): Promise<void>
  edit (id: Identifier, payload: EditUserPayload): Promise<void>
  load (id: Identifier): Promise<UserModel>
  loadCollection (query: Query): Promise<ICollection<UserModel>>
  loadUsersInfo (): Promise<UsersInfoData>
  switchActive(id: Identifier, isActive: boolean, model: UserModel): Promise<UserModel>
}

export const RolesRepositoryType = Symbol.for('IRoleRepository')
export const UserRepositoryType = Symbol.for('IUserRepository')
