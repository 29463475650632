// Copyright © 2021 Move Closer

import { mapModel, MappingConfig, MappingTypes } from '@movecloser/front-core'

export const offersItemAdapterMap: MappingConfig = {
  position: 'position',
  locked: 'locked',
  id: 'id',
  data: {
    type: MappingTypes.Function,
    value: item => {
      return mapModel(item, offerItemDataAdapterMap)
    }
  }
}

export const offerItemDataAdapterMap: MappingConfig = {
  cities: 'city',
  departments: 'department',
  externalNumber: 'externalNumber',
  id: 'id',
  name: 'name',
  region: 'region',
  url: 'url'
}
