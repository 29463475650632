// Copyright © 2021 Move Closer

import 'reflect-metadata'
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import VueMeta from 'vue-meta'
import { DashmixDSL } from '@movecloser/ui-core'

import { createApp } from '@/bootstrap/app'
import { i18n } from '@/shared/support/i18n'
import { initSentry } from '@/shared/support/sentry'
import { installContainer } from '@modules'
import { installFetchRelated } from '@plugin/fetch-related.hook'
import { registerExtensions } from '@/shared/extentions'

import App from './App.vue'
import './assets/scss/main.scss'

Vue.config.productionTip = false

Vue.use(DashmixDSL)
installContainer(Vue)
installFetchRelated(Vue)
registerExtensions(Vue)

Vue.use(VueMeta)
Vue.use(VTooltip)

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
createApp().then(core => {
  const { configuration, container, router } = core

  if (process.env.VUE_APP_ENV !== 'local') {
    initSentry()
  }

  new Vue({
    router,
    container,
    configuration,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
