// Copyright © 2021 Move Closer

import { ICollection } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import { LayoutData, LayoutModel } from './models'

export interface ILayoutRepository {
  create (payload: Omit<LayoutData, 'id'>): Promise<any>
  delete (layoutId: Identifier): Promise<void>
  get (layoutId: Identifier): Promise<LayoutModel>
  list (query?: Query): Promise<ICollection<LayoutModel>>
  update (layoutId: Identifier, payload: Omit<LayoutData, 'id'>): Promise<void>
}

export const LayoutRepositoryType = Symbol.for('ILayoutRepository')
