




























import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject, Related, RelatedType } from '@modules'

import { AbstractModal } from '@component/Modal'
import { Identifier } from '@/shared/contracts/data'
import { IRelatedService, RelatedServiceType } from '@service/related'

import {
  ContentModel,
  ContentRepositoryType,
  ContentType,
  IContentRepository,
  PickerPayload
} from '../contracts'
import { ContentPickerPage } from './ContentPickerPage.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ContentPickerModal>({
  name: 'ContentPickerModal',
  components: {
    ContentPickerPage
  },

  mounted () {
    // TODO fix - see src/modules/content/components/AddSetItemModal.vue:117
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'
  }
})
export class ContentPickerModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<ContentModel, ContentType, Identifier>

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public icons = DashmixIconName

  public activeTab: string = this.tabs[0]?.tab?.id || ''
  public isLoading: boolean = true
  protected initialSelected: Related | null = null
  protected selected: Related | null = null

  public get isReady (): boolean {
    return !!this.selected
  }

  public get contentType (): ContentType|null {
    return this.payload?.config?.contentType
  }

  public get tabs () {
    return [{
      tab: {
        id: ContentType.Page,
        label: this.$t('content.picker.page')
      },
      component: ContentPickerPage,
      props: {
        selectedId: this.initialSelected?.value,
        onSelect: this.selectedRelated
      }
    }]
  }

  public apply (): void {
    if (this.selected && this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = {
        type: this.selected.type as unknown as ContentType,
        value: this.selected.value
      }
      this.relatedService.merge(this.selected.type, this.selected.value, {
        ...this.selected
      })

      this.payload.onSelection(result)
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public selectedRelated (id: Identifier, related: ContentType) {
    if (this.payload.selected) {
      this.initialSelected = this.payload.selected as unknown as Related
    }
    this.initialSelected = null

    if (related) {
      this.selected = {
        type: related as unknown as RelatedType,
        value: id
      }
    }
  }
}

export default ContentPickerModal
