











import { Authentication, AuthEvent, AuthEventType, AuthServiceType, EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { Subscription } from 'rxjs'

import { FullScreenLoader } from '@/shared/layouts'
import { Inject } from '@modules'
import { Modal } from '@component/Modal'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { SiteModel } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { Layout } from '@module/root/components/Layout.vue'
import { Modals } from '@/config/modals'
import { TokenModel, UserModel } from '@module/auth/contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'App',
  components: { FullScreenLoader, Layout, Modal },
  metaInfo (): MetaInfo {
    return {
      titleTemplate: 'Dashboard | %s'
    }
  }
})
export default class App extends Vue {
  @Inject(AuthRepositoryType)
  private authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  private authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  private booted: boolean = false
  private contextSubscription!: Subscription

  private subscription!: Subscription
  public site: SiteModel | null = null

  public get isLoading (): boolean {
    return !this.booted
  }

  created () {
    this.setSite()

    this.contextSubscription = this.eventBus.handle('ui:context.changed', () => {
      this.setSite(true)
    })

    this.subscription = this.authService.listen((event: AuthEvent) => {
      switch (event.type) {
        case AuthEventType.Booted:
          // Here we already have an answer to previous question. So we should check
          // if there's an authenticated user & decide where should the redirection goes.
          this.notify('booted')
          break
        case AuthEventType.BootedWithToken:
        case AuthEventType.Refresh:
          // In this case we trigger automatic token refresh.
          this.notify('refreshing')

          this.authRepository.refresh(event.token?.accessToken as string).then((model: TokenModel) => {
            this.authService.setUser(model.getUser())
            this.authService.setToken(model.toAuthToken())

            const userPermissions = model.getUser().sitesWithAccess()
            const activeSite = this.siteResolver.getSite()

            if (userPermissions.length === 0) {
              this.$router.push({ name: 'auth.no-access' })
            }

            if (!activeSite || !activeSite.domain || !userPermissions.includes(activeSite.domain)) {
              const newSite = userPermissions.length ? this.siteResolver.findByDomain(userPermissions[0]) : null

              if (!newSite) {
                this.authService.deleteToken()
                this.$router.push({ name: 'auth.login' })
                return
              }

              this.modalConnector.open(Modals.ForceChangeSite, {
                onConfirm: () => this.onForceChangeSite(newSite),
                newSite: newSite.domain
              })
            }
          }).catch(() => {
            this.authService.deleteToken()
          }).finally(() => {
            this.notify('refreshed')
          })
          break
        case AuthEventType.Authenticated:
          this.notify('booted')
          this.modalConnector.close(Modals.Login)
          break
        case AuthEventType.Invalidated:
          this.notify('booted')
          this.modalConnector.open(Modals.Login, {})
      }
    })
  }

  destroyed () {
    this.subscription.unsubscribe()
  }

  private notify (action: string): void {
    this.eventBus.emit('app:authentication', action)

    if (action !== 'refreshing') {
      this.booted = true
    }
  }

  private onForceChangeSite (newSite: SiteModel) {
    this.siteResolver.setSite(newSite)
    this.eventBus.emit('ui:context.changed')
  }

  private setSite (reload?: boolean) {
    const site = this.siteResolver.getSite()

    if (site) {
      this.site = site
    }

    if (reload) {
      this.$router.push({ name: 'root.dashboard' })
    }
  }
}
