

























import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { enterKey, IEnterEvent } from '@/shared/extensions/events'
import { FormInput } from '@/shared/components/form'
import { Inject } from '@modules'
import { UserModel } from '@module/auth/contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'ResetPasswordForm',
  components: { FormInput }
})
export class ResetPassword extends Vue implements IEnterEvent {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  protected listener = enterKey.bind(this)
  protected token!: string

  public email = ''
  public formName: string = 'resetPasswordForm'
  public password: string = ''
  public passwordConfirmation: string = ''
  public sending: boolean = false

  enterPressed () {
    this.resetPassword()
  }

  destroyed () {
    window.removeEventListener('keyup', this.listener)
  }

  mounted () {
    window.addEventListener('keyup', this.listener)

    if (!('token' in this.$route.params)) {
      this.$router.back()
    }

    this.token = String(this.$route.params.token)
  }

  resetPassword () {
    if (this.email && this.password && this.passwordConfirmation) {
      this.sending = true
      this.authRepository.resetPassword(this.token, this.email, this.password, this.passwordConfirmation)
        .then(() => {
          this.sending = false
          //
        })
        .catch(() => {
          this.sending = false
        })
    }
  }
}

export default ResetPassword

