// Copyright © 2021 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.abstract'

import {
  canCreateBlogPostsFeed,
  canCreateJobsFeed,
  canCreateSocialPostsFeed,
  canDeleteBlogPostsFeed, canDeleteSocialPostsFeed,
  canEditBlogPostsFeed,
  canEditJobsFeed, canEditSocialPostsFeed,
  canViewBlogPostsFeed,
  canViewJobsFeed, canViewSocialPostsFeed
} from './guards'

export interface ISetsPermissions extends IUserAware {
  canViewJobsFeed: boolean
  canCreateJobsFeed: boolean
  canEditJobsFeed: boolean
  canDeleteJobsFeed: boolean
  canViewBlogPostsFeed: boolean
  canCreateBlogPostsFeed: boolean
  canEditBlogPostsFeed: boolean
  canDeleteBlogPostsFeed: boolean
  canViewSocialPostsFeed: boolean
  canCreateSocialPostsFeed: boolean
  canEditSocialPostsFeed: boolean
  canDeleteSocialPostsFeed: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<SetsPermissions>({})
export class SetsPermissions extends UserAware implements ISetsPermissions {
  /**
   * Jobs Feed permissions
   */
  public get canViewJobsFeed () {
    return canViewJobsFeed(this.domain, this.authService.user)
  }

  public get canCreateJobsFeed () {
    return canCreateJobsFeed(this.domain, this.authService.user)
  }

  public get canEditJobsFeed () {
    return canEditJobsFeed(this.domain, this.authService.user)
  }

  public get canDeleteJobsFeed () {
    return canEditJobsFeed(this.domain, this.authService.user)
  }

  /**
   * Blog posts Feed permissions
   */
  public get canViewBlogPostsFeed () {
    return canViewBlogPostsFeed(this.domain, this.authService.user)
  }

  public get canCreateBlogPostsFeed () {
    return canCreateBlogPostsFeed(this.domain, this.authService.user)
  }

  public get canEditBlogPostsFeed () {
    return canEditBlogPostsFeed(this.domain, this.authService.user)
  }

  public get canDeleteBlogPostsFeed () {
    return canDeleteBlogPostsFeed(this.domain, this.authService.user)
  }

  /**
   * Social posts Feed permissions
   */
  public get canViewSocialPostsFeed () {
    return canViewSocialPostsFeed(this.domain, this.authService.user)
  }

  public get canCreateSocialPostsFeed () {
    return canCreateSocialPostsFeed(this.domain, this.authService.user)
  }

  public get canEditSocialPostsFeed () {
    return canEditSocialPostsFeed(this.domain, this.authService.user)
  }

  public get canDeleteSocialPostsFeed () {
    return canDeleteSocialPostsFeed(this.domain, this.authService.user)
  }
}
