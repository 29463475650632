




















































import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { debounce } from 'lodash'

import { Related, RelatedType, Inject } from '@modules'

import { AbstractModal } from '@component/Modal'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'
import { Identifier } from '@/shared/contracts/data'
import { Loader } from '@component/Loader'

import { PickerPayload } from '@module/content/contracts'

import { GalleryPickerItem } from './GalleryPickerItem.vue'
import { GalleryItems } from './GalleryItems.vue'
import { GalleryModel, GalleryRepositoryType, IGalleryRepository } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<GalleryPickerModal>({
  name: 'GalleryPickerModal',
  components: { Loader, GalleryPickerItem, GalleryItems },

  created () {
    const promises: Promise<void>[] = []

    if (this.payload.selected) {
      let selectedIds: Identifier[] = []

      if (this.payload.multiple) {
        selectedIds = (this.payload.selected as Related<RelatedType.Gallery, Identifier>[]).map(s => s.value)
      } else {
        selectedIds = [(this.payload.selected as Related<RelatedType.Gallery, Identifier>).value]
      }

      selectedIds.forEach(id => promises.push(this.loadGallery(id, true)))
    }

    Promise.all(promises).then(() => {
      this.loadGalleryList()
    })
  }
})
export class GalleryPickerModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<GalleryModel, RelatedType.Gallery, Identifier>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(GalleryRepositoryType)
  private galleryRepository!: IGalleryRepository

  protected query: string = ''
  protected galleryList: GalleryModel[] = []
  protected selected: GalleryModel[] = []
  protected activeGallery: GalleryModel | null = null

  private icons = DashmixIconName
  private isLoading: boolean = true
  private isGalleryLoading: boolean = false

  get isReady (): boolean {
    return !!this.selected && !!this.selected.length
  }

  get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public apply (): void {
    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selected.map(item => ({
        type: RelatedType.Gallery,
        value: item.id
      } as Related<RelatedType.Gallery, Identifier>))

      this.payload.onSelection(this.isMultiple ? result : result[0])
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public isSelected (id: Identifier): boolean {
    return !!this.selected && this.selected.filter(item => item.id === id).length > 0
  }

  public loadGallery (id: Identifier, isSelected: boolean): Promise<void> {
    if (!id) {
      return new Promise(resolve => resolve)
    }

    this.isGalleryLoading = true

    return new Promise((resolve, reject) => {
      this.galleryRepository.load(id)
        .then((gallery: GalleryModel) => {
          if (isSelected) {
            this.updateSelected(gallery)
          }
          this.activeGallery = gallery

          resolve()
        })
        .catch(error => {
          console.log(error)

          reject(error)
        })
        .finally(() => {
          this.isGalleryLoading = false
        })
    })
  }

  public loadGalleryList () {
    this.isLoading = true

    this.galleryRepository.loadCollection({ q: this.query })
      .then((galleryList: ICollection<GalleryModel>) => {
        this.galleryList = galleryList
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public toggleGallery (gallery: GalleryModel) {
    if (this.isSelected(gallery.id)) {
      this.selected = this.selected.filter(item => item.id !== gallery.id)
    } else {
      this.selected.push(gallery)
    }
  }

  public search () {
    this.searchDebounce()
  }

  public searchDebounce = debounce(this.loadGalleryList.bind(this), 500)

  public updateSelected (gallery: GalleryModel) {
    if (this.isMultiple) {
      this.selected.push(gallery)
    } else {
      this.selected = [gallery]
    }
  }
}

export default GalleryPickerModal
