




















import { Collection, ICollection } from '@movecloser/front-core'
import { Component, PropSync } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { Inject, log } from '@modules'

import {
  ContentRepositoryType, ContentStatus,
  IContentRepository,
  SimpleContentModel
} from '@module/content/contracts'
import { Domain } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { ConfigForm } from './_abstract/ConfigForm.vue'
import { DepartmentForm } from './DepartmentForm.vue'
import { DepartmentsSettings } from '../contracts'

@Component<DepartmentsSettingsForm>({
  name: 'DepartmentsSettingsForm',
  components: { DepartmentForm },
  created () {
    this.fetchSelectOptions()
  }
})
export class DepartmentsSettingsForm extends ConfigForm {
  @PropSync('formData', { type: Object, required: true })
  public payload!: DepartmentsSettings

  public readonly formName = 'departmentsSettings'

  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  public pages: Record<Domain, DashmixSelectItem[]> | null = null

  public loading: boolean = false

  public onSubmit () {
    this.$emit('submit', { reproject: true, global: true })
  }

  private async fetchSelectOptions (): Promise<void> {
    this.loading = true
    const pages: Record<Domain, DashmixSelectItem[]> = {} as unknown as Record<Domain, DashmixSelectItem[]>

    try {
      const availableDomains: Domain[] = Object.values(Domain)

      for (const domain of availableDomains) {
        const collection = await this.fetchPages(domain)
        pages[domain] = DepartmentsSettingsForm.mapToSelectOptions(collection)
      }
    } catch (e) {
      log(e)
    } finally {
      this.loading = false
      this.pages = pages
    }
  }

  private async fetchPages (domain: Domain): Promise<ICollection<SimpleContentModel>> {
    try {
      const site = this.siteResolver.findByDomain(domain)

      if (!site) {
        return new Collection<SimpleContentModel>([])
      }

      return this.contentRepository.loadTree({
        'flat-tree': '',
        perPage: '1000',
        siteId: `${site.id}`,
        status: ContentStatus.Published
      })
    } catch (e) {
      log(e)
      return new Collection<SimpleContentModel>([])
    }
  }

  public static mapToSelectOptions (pages: ICollection<SimpleContentModel>): DashmixSelectItem[] {
    return [...pages].map(p => {
      return {
        label: p.title,
        value: p.id
      }
    })
  }
}

export default DepartmentsSettingsForm
