// Copyright © 2021 Move Closer

import {
  AggregatesRelated,
  AnyDescription,
  DriverConfig,
  Identifier,
  Related,
  RelatedRecord,
  RelatedServiceType as _RelatedServiceType,
  RelatedType,
  ResolvesRelatedAsync
} from '@modules'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum CalledRelatedServiceMethod {
  Describe = 'describe',
  Resolve = 'resolve'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IRelatedRepository {
  load (query: RelatedQuery): Promise<RelatedRecord>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IRelatedService extends AggregatesRelated, ResolvesRelatedAsync {
  /**
   * TODO: Documentation.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merge (type: RelatedType, key: Identifier, value: AnyDescription): void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedQuery = Partial<Record<RelatedType, Identifier[]>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const RelatedRepositoryType = Symbol.for('IRelatedRepository')

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const RelatedServiceType = _RelatedServiceType

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedToLoad<T> {
  /**
   * TODO: Documentation.
   */
  config?: DriverConfig

  /**
   * TODO: Documentation.
   */
  method: CalledRelatedServiceMethod

  /**
   * TODO: Documentation.
   */
  reject: (error: Error) => void

  /**
   * TODO: Documentation.
   */
  related: Related

  /**
   * TODO: Documentation.
   */
  resolve: (resolved: T) => void
}
