// Copyright © 2021 Move Closer

import { DashmixIconName, TableSearchBarAction, TableHead } from '@movecloser/ui-core'

import { Domain } from '@module/root/contracts/models'
import { UserModel as AuthUserModel } from '@module/auth/contracts/models'

import { canEditUsers } from '../config/guards'

export const usersTableHead: TableHead = [
  {
    column: 'avatar',
    label: '',
    sortable: false,
    isSorted: false,
    width: '50px'
  },
  {
    column: 'name',
    label: 'users.table.name',
    sortable: true,
    isSorted: false,
    width: '1.2fr'
  },
  {
    column: 'role',
    label: 'users.table.access',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'createdDate',
    label: 'users.table.createdDate',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'lastLogin',
    label: 'users.table.lastLogin',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export const usersTableHeadShortened: TableHead = [
  {
    column: 'avatar',
    label: '',
    sortable: false,
    isSorted: false,
    width: '50px'
  },
  {
    column: 'name',
    label: 'users.table.name',
    sortable: false,
    isSorted: false,
    width: '1.7fr'
  },
  {
    column: 'role',
    label: 'users.table.access',
    sortable: false,
    isSorted: false,
    width: '1fr'
  }
]

export enum UserListsActions {
  EditRole = 'editRole',
  ResetPassword = 'resetPassword'
}

export const userRowActionsFactory = (domain: Domain, user: AuthUserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditUsers(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Edytuj role',
          icon: DashmixIconName.PenSolid,
          isBulkAction: false,
          action: UserListsActions.EditRole
        }
        // {
        //   label: 'Resetuj hasło',
        //   icon: DashmixIconName.UnlockSolid,
        //   isBulkAction: true,
        //   action: UserListsActions.ResetPassword
        // }
      ]
    )
  }

  return actions
}
