// Copyright © 2022 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { Picker } from '@modules'

import NavigationPicker from '../components/NavigationPicker.vue'

export enum SettingsModal {
  //
}

/**
 * Registry of available modals.
 */
export const settingsModals: ModalRegistry<VueConstructor> = {
  [Picker.Navigation]: NavigationPicker
}
