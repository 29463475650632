// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { Color as EColor } from '../../contracts'
import { log } from '../../support'

/**
 * Extendable component that's capable of receiving the `color` @Prop
 * and transforming it into the target CSS class using the specified registry.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Colorable>({ name: 'Colorable' })
export class Colorable<Color = EColor> extends Vue {
  /**
   * Determines the object's color.
   */
  @Prop({ type: String, required: false })
  public readonly color!: Color

  /**
   * Registry that binds the `Color` with the applicable CSS class.
   */
  public readonly colorClassNameRegistry: { [key: string]: string } = {
    [EColor.Black]: 'text-black',
    [EColor.Body]: 'text-body',
    [EColor.Primary]: 'text-primary',
    [EColor.White]: 'text-white'
  }

  /**
   * CSS class that handles the object's color.
   */
  public get colorClassName (): string {
    if (typeof this.color !== 'string') {
      return ''
    }

    const className: string = this.colorClassNameRegistry[this.color]

    if (typeof className === 'undefined') {
      log(`Colorable.colorClassName(): There's no CSS class binding for the [${this.color}] color! Returning empty string!`, 'warn')
      return ''
    }

    return className
  }
}
