// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { Identifier } from '@/shared/contracts/data'
import { PickerPayload } from '@module/content/contracts'
import { RelatedType } from '@/shared/modules'

import { AbstractSetPickerModal } from './AbstractSetPickerModal'
import { SetModel, SetType } from '../../contracts'

@Component({
  name: 'OffersPickerModal',
  extends: AbstractSetPickerModal
})
export class OffersPickerModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<SetModel, RelatedType.JobOffersFeed, Identifier>

  public setType = SetType.JobOffers
}

export default OffersPickerModal
