// Copyright © 2021 Move Closer
import { DashmixIconName } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { ModuleSettings } from '@modules'

import { formDrawerProps, useFormDrawer } from './FormDrawer.hooks'
import { FormDrawerProps } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const FormDrawer = defineComponent(({
  name: 'FormDrawer',
  components: {
    ModuleSettings
  },
  props: formDrawerProps,
  setup: (props: FormDrawerProps, ctx: SetupContext) => ({
    ...useFormDrawer(props, ctx),
    iconsSet: DashmixIconName
  }),
  template: `
    <div v-if="isOpen" class="page-builder__form-drawer open">
      <div class="page-builder__form-drawer__content">
        <div class="page-builder__form-drawer__header">
          <div>
            <h3 class="mb-0">
              {{ $t('builder.moduleInEdition') }}: {{ $t('moduleDrivers.' + module.driver) }}
            </h3>
            <small>ID: {{ module.id }}</small>
          </div>

          <DsButton class="page-builder__form-drawer__content__close"
                    :icon="iconsSet.TimesSolid" variant="icon"
                    @click="$emit('close')" />
        </div>

        <hr />

        <ModuleSettings :module="module" :moduleVersionsRegistry="versionsRegistry" :errors="errors" >
          <component v-if="isOpen" class="page-builder__form"
                     :is="selectedForm" v-bind="{ pickRelated, relatedService, resolveInjection }"
                     :content.sync="content" :alignment.sync="module.alignment" :version.sync="version" >
            <div class="d-block bg-danger text-white p-4">{{ $t('builder.missing.modulesForm') }}</div>
          </component>
        </ModuleSettings>

        <DsButton class="mt-3" :loading="validating" :icon="iconsSet.CheckSolid" :label="$t('builder.apply')"
                  @click="applyChanges" />
      </div>
    </div>
  `
}))
