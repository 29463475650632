// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { DashmixSelectItem } from '@movecloser/ui-core'
import { computed, ComputedRef, defineComponent, PropType } from '@vue/composition-api'

import { FormFieldAbstract, FormFieldAbstractSetup } from '../Abstract'

import { FormSelectProps } from './Select.contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const FormSelect = defineComponent({
  name: 'FormSelect',
  extends: FormFieldAbstract,
  template: `
    <div>

      <label :for="name" v-if="displayLabel" :require="required">{{ label }}</label>

      <DsSelect :model.sync="value"
               v-bind="{ autocomplete, disabled, clearable,
               filterBy, loading, multiple, options, placeholder, searchable }"
               v-on="$listeners"
               :class="classObject" withDynamicPosition >

        <template v-if="this.$slots['selected-option']" #selected-option="option">
          <slot name="selected-option" />
        </template>

        <template v-if="this.$slots.option" #option="option">
          <slot name="option" />
        </template>

        <template v-if="this.$slots['no-options']" #no-options>
          <slot name="no-options" />
        </template>

      </DsSelect>

      <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
      <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>

  `,
  props: {
    autocomplete: {
      type: String,
      default: 'off'
    },
    filterBy: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array as PropType<DashmixSelectItem[]>,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],

  setup (props: FormSelectProps, { emit }) {
    const {
      errors,
      displayLabel,
      hasErrors,
      value
    } = FormFieldAbstractSetup(props, emit)

    const classObject: ComputedRef<AnyObject> = computed(() => {
      return {
        'is-invalid': hasErrors.value
      }
    })

    return {
      classObject,
      errors,
      displayLabel,
      hasErrors,
      value
    }
  }
})
export default FormSelect
