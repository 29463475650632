













import { Component, Prop, Vue } from 'vue-property-decorator'
import { Fragment } from 'vue-fragment'

import { OwnerModel } from '@module/root/contracts/models'

@Component({
  name: 'EditorsStack',
  components: { Fragment }
})
export class EditorsStack extends Vue {
  @Prop({ type: Array, required: true })
  public editors!: OwnerModel[]

  public getAvatar (editor: OwnerModel): string | null {
    if (typeof editor.avatar !== 'string') {
      return null
    } else {
      return editor.avatar
    }
  }
}

export default EditorsStack
