







































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { EditModeLayout } from '@component/EditModeLayout'
import FormInput from '@component/form/Input/Input'
import { Loader } from '@component/Loader/Loader'

import { CreateRolePayload } from '../intentions/createRole'
import { UpdateRolePayload } from '../intentions/updateRole'

import PermissionsList from '../components/PermissionsList.vue'
import { RoleFormMode } from '../contracts/components'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'RoleForm',
  components: { Loader, FormInput, EditModeLayout, PermissionsList }
})
export class RoleForm extends Vue {
  @PropSync('payload', { type: Object, required: true })
  public data!: CreateRolePayload | UpdateRolePayload

  @Prop({ type: String, required: true })
  public mode!: RoleFormMode

  @Prop({ type: Boolean, required: true })
  public sending!: boolean

  @Prop({ type: String, required: true })
  public title!: string

  public Icons = DashmixIconName
  public isLoading: boolean = false
  public modes = RoleFormMode

  public get form () {
    switch (this.mode) {
      case RoleFormMode.Create:
        return 'createRole'
      case RoleFormMode.Edit:
        return 'editRole'
    }
  }

  public submit () {
    this.$emit('submit')
  }
}

export default RoleForm
